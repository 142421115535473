import React, { useState, useEffect }  from 'react';

const ChooseBrand = () => {

	const [brands, setBrands] = useState([]);
	const [selectedBrand, setSelectedBrand] = useState(null);

	useEffect(() => {
		setBrands(JSON.parse(localStorage.getItem('brands')));
	},[]);

	const RenderBrands = () => {

		const content = brands.map((b) => {
			const imgPath = process.env.PUBLIC_URL + '/' + b.bgImage ;
			const imgUrl = 'url(' + imgPath + ')';
			return <div key={b.name} className="col-4">
				<div className="border-round-md border-1 border-yellow-500 p-3 h-15rem"
					style={{ backgroundImage: imgUrl }}>
					{b.name}
				</div>
			</div>;
		});
		return <div className="grid">{content}</div>
	}

	return (<div className="w-screen h-screen" style={{ backgroundImage: 'linear-gradient(135deg, #fdfcfb 0%, #e2d1c3 100%)' }} >
			<div className="p-8">
				<p className="text-2xl mb-1 font-medium text-bluegray-900">Your Brands</p>
				<p className="text-sm mt-1 text-bluegray-900">Choose 1 brand to populate the details</p>
				<div>
					<RenderBrands />
				</div>
			</div>
	</div>);
}

export default ChooseBrand;