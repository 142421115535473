import React, { useState } from "react";
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';

function CustomInput({ objValue, onChange, index }) {
  const { label, type, value, mandatory, loyaltyPoints, fldType, fldError } = objValue;

  return (

    <div className="grid grid-nogutter my-3">
        <div className="col-6">
            <label htmlFor={type} className="block text-900 font-medium mb-1">{mandatory === true ? ('* ' + label) : label}</label>
        </div>
        <div className="col-6">
          <div className="flex justify-content-end flex-wrap">
              <small className="text-gray-600 mx-2">Loyality Points: {loyaltyPoints}</small>
            </div>
        </div>
        <div className="col-12 pb-2">
            <InputText
              type={type || "text"}
              id={label}
              value={value || ""}
              onChange={(e) => onChange(e, index)}
              placeholder={label}
              keyfilter={fldType}
              className="w-full"
            />
            <span className="text-xs text-red-500">{fldError}</span>
        </div>
    </div>
  );
}


function CustomCalendar({ objValue, onChange, index }) {
  const { label, type, value, mandatory, loyaltyPoints, fldType, fldError } = objValue;

  let maxDate = new Date();

  return (

    <div className="grid grid-nogutter my-3">
        <div className="col-6">
            <label htmlFor={type} className="block text-900 font-medium mb-1">{mandatory ? '* ' + label : label}</label>
        </div>
        <div className="col-6">
          <div className="flex justify-content-end flex-wrap">
              <small className="text-gray-600 mx-2">Loyality Points: {loyaltyPoints}</small>
            </div>
        </div>
        <div className="col-12 pb-2">
            <Calendar 
              dateFormat="M d, yy"
              id={label}
              value={value || ""}
              onChange={(e) => onChange(e, index)}
              placeholder={label}
              className="w-full"
              maxDate={maxDate} 
              readOnlyInput />
              <span className="text-xs text-red-500">{fldError}</span>
        </div>
    </div>
  );
}

function CustomFieldRenderer({ objValue, onChange, index }) {

  const { label, type, value, mandatory, loyaltyPoints, fldType, fldError } = objValue;

  if('dob' === type || 'marriage_anniversary' === type) {
      return (<CustomCalendar
                  key={index}
                  objValue={objValue}
                  onChange={onChange}
                  index={index}
                />);
  } else {
      return (<CustomInput
                  key={index}
                  objValue={objValue}
                  onChange={onChange}
                  index={index}
                />);
  }

}

export { CustomInput, CustomCalendar, CustomFieldRenderer } 