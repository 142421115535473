import React from 'react';
import ReactDOM from 'react-dom/client';
import {Link} from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

function Footer() {
	return(
		<div className="absolute left-0 bottom-0 mb-2 flex w-full align-items-center justify-content-center py-1">
			<small className="text-lg text-gray-500 text-right mr-3">&copy; 2024 Saltwin Tech Lab LLC</small>
			<Link className="text-lg no-underline text-gray-500 text-right cursor-pointer mr-3">Privacy</Link>
			<Link className="text-lg no-underline text-gray-500 text-right cursor-pointer mr-3">Terms</Link>
			<Link className="text-lg no-underline text-gray-500 text-right cursor-pointer mr-3">Sitemap</Link>
        </div>);
}

export default Footer;