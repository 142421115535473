import React from 'react';
import ReactDOM from 'react-dom/client';

import {
  HashRouter as Router,
  Routes,
  Route,
  Navigate,
  Switch
} from 'react-router-dom';

import Login from './components/Login';
import SignUp from './components/SignUp';
import ResetPassword from './components/ResetPassword';
import Dashboard from './components/Dashboard';
import ManageLoyalty from './components/ManageLoyalty';
import ManageLoyalty2 from './components/ManageLoyalty2';
import Outlets from './components/Outlets';
import ManageBrands from './components/ManageBrands';
import ManageOutlets from './components/ManageOutlets';
import ManageSegments from './components/ManageSegments';
import ManageCashbackRules from './components/ManageCashbackRules';
import Customer from './components/Customer';
import ChooseBrand from './components/ChooseBrand';
import Configurations from './components/Configurations';
import QRCode from './components/QRCode';
import QRScanWebForm from './components/QRScanWebForm';


import NewTheme from './components/NewTheme';

function App() {

  if(null === localStorage.getItem('token') || localStorage.getItem('token') === '') {
      const noBrand = {
                      "brandId": -313131,
                      "name": "",
                      "isActive": false,
                      "cashbackPrograms": null,
                      "pointsPrograms": null,
                      "promotions": null
                  };

      localStorage.setItem('token', '');
      localStorage.setItem('username', '');
      localStorage.setItem('email', '');
      localStorage.setItem('brands', JSON.stringify([]));
      localStorage.setItem('currentBrand', JSON.stringify(noBrand));
  }
  return(
      <>
        {
          <Router>
            <Routes>
              <Route
                exact
                path="/"
                element={<Login />}
              />
              <Route
                  path="/signup"
                  element={<SignUp />}
              />
              <Route
                  path="/resetPassword"
                  element={<ResetPassword />}
              />
              <Route
                  path="/dashboard"
                  element={<Dashboard />}
              />
              <Route
                  path="/manageloyalty"
                  element={<ManageLoyalty />}
              />
              <Route
                  path="/manageloyalty2"
                  element={<ManageLoyalty2 />}
              />
              <Route
                  path="/outletsconf"
                  element={<Outlets />}
              />
              <Route
                  path="/managebrands"
                  element={<ManageBrands />}
              />
              <Route
                  path="/manageoutlets"
                  element={<ManageOutlets />}
              />
              <Route
                  path="/managesegments"
                  element={<ManageSegments />}
              />
              <Route
                  path="/managecashbackrules"
                  element={<ManageCashbackRules />}
              />
              <Route
                  path="/customer"
                  element={<Customer />}
              />
              <Route
                  path="/configurations"
                  element={<Configurations />}
              />
              <Route
                  path="/choosebrand"
                  element={<ChooseBrand />}
              />
              <Route
                  path="/qrcode"
                  element={<QRCode />}
              />
              <Route
                  path="/webform/:qrcodeid"
                  element={<QRScanWebForm />}
              />
              <Route
                  path="/newTheme"
                  element={<NewTheme />}
              />

              <Route
                  path="*"
                  element={<Navigate to="/" />}
              />
            </Routes>
          </Router>
        }
      </>
    );
}

export default App;