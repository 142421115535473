import React, { useState, useEffect, useRef }  from 'react';
import { useEventListener } from 'primereact/hooks';
import {Link, useNavigate} from 'react-router-dom';
import { Image } from 'primereact/image';
import { Button } from 'primereact/button';

import { FaLayerGroup, FaShop, FaCashRegister, FaChartLine } from 'react-icons/fa6';


import { Menu } from 'primereact/menu';

const NewTheme = () => {

	
	document.addEventListener('click', function(e){
	  if(e.target.tagName=="A"){
	  	var navLinks = document.getElementsByClassName("salt-nav-link");
		for (var i = 0; i < navLinks.length; i++) {
			var currActive = document.getElementsByClassName("salt-nav-link-active");
			currActive[0].className = currActive[0].className.replace(" salt-nav-link-active", "");
			e.target.className += " salt-nav-link-active";
		}
	  } 
	});

	let items = [
        { label: 'New', icon: 'pi pi-plus' },
        { label: 'Search', icon: 'pi pi-search' }
    ];


	return (
				<div className="grid grid-nogutter">
				    <div className="col-2">
				        <div className="text-center text-white px-2 py-3 h-screen" style={{ backgroundImage: 'linear-gradient(to right, #434343 0%, black 100%)' }}>
				        	<div className="grid">
							    <div className="col-12 border-bottom-1 p-4">
							    	<Link to="/dashboard">
							        	<Image src={process.env.PUBLIC_URL + '/saltwin-text.png'} alt="Image" className="my-2 cursor-pointer" />
							        </Link>
							    </div>
							    <div className="col-12 p-2 mt-2 flex flex-row flex-wrap">
							        <Link to ="/dashboardnt" className="pi pi-objects-column salt-nav-link salt-nav-link-active flex align-items-center justify-content-start w-12 font-light border-round text-white no-underline p-3 m-2">
								        &nbsp;Dashboard
								    </Link>
								    <Link to ="/brandsnt" className="pi pi-shop salt-nav-link flex align-items-center justify-content-start w-12 font-light border-round text-white no-underline p-3 m-2">
								        &nbsp;Brands
								    </Link>
								    <Link to ="/managesegmentsnt" className="pi pi-tags salt-nav-link flex align-items-center justify-content-start w-12 font-light border-round text-white no-underline p-3 m-2">
								        &nbsp;Segments
								    </Link>
								    <Link to ="/customersnt" className="pi pi-users salt-nav-link flex align-items-center justify-content-start w-12 font-light border-round text-white no-underline p-3 m-2">
								        &nbsp;Customers
								    </Link>
							    </div>
							</div>
				        </div>
				    </div>
				    <div className="col-10">
				        <div className="text-center px-2 py-3 h-screen" style={{ backgroundImage: 'linear-gradient(to top, #dfe9f3 0%, white 100%)' }}>

				        	New Theme
				        	

				        </div>
				    </div>
				</div>
			);
}

export default NewTheme;