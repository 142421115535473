import React, { useState }  from 'react';
import {NavigationBarVertical, NavigationBarHorizontal, Footer} from './NavigationBar';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import {Link} from 'react-router-dom';
import {ServerErrorModal} from '../Utility';

const Outlets = () => {
	const [outletDetails, setOutletDetails] = useState(outlet);

	const updateOutletValue = (e) => {
        const t = e.target;
        const n = t.name;
        const v = t.value;

        const newState = {...outletDetails, [n]: v};
        setOutletDetails(newState);
    }

    const updateAddressValue = (e) => {
        const t = e.target;
        const n = t.name;
        const v = t.value;

        var addr = outletDetails.address;
        const newAddr = {...addr, [n]: v};

        const newState = {...outletDetails, 'address': newAddr};
        setOutletDetails(newState);
    }

    const handleSubmit = (e) => {
    	e.preventDefault();
    	let errors = {};
        if(outletDetails.name.length === 0 ) {
            errors.name = 'Outlet name is required field';
        } 
        if(outletDetails.phoneNumber.length === 0 ) {
            errors.phoneNumber = 'Phone number is required field';
        } else if(outletDetails.phoneNumber.length < 10) {
            errors.phoneNumber = 'Phone number must be of 10 digits length';
        } 

        const newState = {...outletDetails, errors: errors};
        setOutletDetails(newState);

       if(Object.keys(errors).length > 0) {
            return;
       }

       // send a request to the server to save the outlet details
        saveOutletDetails().then(res => {
            if(res != null) {
                if(res.isSuccess === false) {
                    let errors = {};
                    errors.loginError = res.error;
                    const newState = {...outletDetails, errors: errors};
                    setOutletDetails(newState);
                } else {
                    setOutletDetails(outlet);
                    const o = res.name;
                    const msg = 'Registration successful. Your outlet name is - ' + o;
                    setRegOutlet('Welcome, ' + o);
                    setRegMessage(msg);
                    setDialogVisible(true);
                    <ShowRegisterSuccessModal username={o} message={msg} />;
                }
            } else {
                <ServerErrorModal />
            }
        });

    }

    const saveOutletDetails = async(e) => {
        try {
            const response = await fetch('https://api.saltwin.com/loyalty-web-service/rest/v1/outlet', {
                method: 'POST',
                body: JSON.stringify(outletDetails),
                headers: {
		          'Content-Type': 'application/json; charset=UTF-8',
		          'token': localStorage.getItem('token')
		        }
              });
            const resJson = await response.json();
            return resJson;
        } catch (err) {
            const errorMessage = "Error:" + err.message;
            console.error(errorMessage);
        }
      }

    const isFormFieldInvalid = (name) => { return outletDetails.errors[name]; }

    const getFormErrorMessage = (name) => {
        return isFormFieldInvalid(name) ? <small className="p-error text-lg">{outletDetails.errors[name]}</small> : <small className="p-error text-lg">&nbsp;</small>;
    };

  	const [dialogVisible, setDialogVisible] = useState(false);
  	const [regOutlet, setRegOutlet] = useState('');
	const [regMessage, setRegMessage] = useState('');


	const ShowRegisterSuccessModal = () => {
		return dialogVisible ? <Dialog header={regOutlet} visible={dialogVisible} style={{ width: '50vw' }} onHide={() => setDialogVisible(false)}>
        <p className="m-0 text-lg font-medium mb-2 text-gray-800">
            <p>{regMessage}</p>
            <span>Go to </span>
            <Link to="/dashboard" className="text-yellow-800">Dashboard</Link>
        </p>
    </Dialog> : <></>;
	}

	return (
		<div className="relative p-0 m-0">
		    <div className="relative text-gray-800 w-screen h-screen">
        		<NavigationBarVertical />
		        <div className="absolute top-0 left-0 ml-7 h-4rem px-2 pt-2 border-200 border-bottom-1" style={{ width: '94%', height: '630px' }}>
		        	<NavigationBarHorizontal hideLinkOf='' />
					<div id="show-saltwin-content" className="px-4 py-4" style={{ width: '100%' }}>						
						<div className="grid overflow-y-auto"  style={{ height: '580px' }}>
						    <div className="col-7">
						    	<div className="formgrid grid mb-2">
									Outlet Details {getFormErrorMessage('outletError')}
								</div>
						        <div className="formgrid grid">
								    <div className="field col">
								        <label htmlFor="outletName" className="block text-lg font-medium my-1 text-gray-800">Name*</label>
			                            <InputText id="outletName" type="text" placeholder="Outlet Name" className="w-full text-lg border-round-sm"
					                        name="name"
					                        value={outletDetails.name}
					                        onChange={(e) => updateOutletValue(e)} />
					                    {getFormErrorMessage('name')}
								    </div>
								    <div className="field col">
								        <label htmlFor="numOfOutlets" className="block text-lg font-medium my-1 text-gray-800">Count</label>
			                            <InputText id="numOfOutlets" type="text" placeholder="Outlets Count" className="w-full text-lg border-round-sm"
			                                name="numberOfOutlet"
			                                value={outletDetails.numberOfOutlet}
			                                onChange={(e) => updateOutletValue(e)} />
								    </div>
								</div>
								<div className="formgrid grid">
									<div className="field col">
										<label htmlFor="phoneNumber" className="block text-lg font-medium my-1 text-gray-800">Phone Number*</label>
			                            <InputText id="phoneNumber" type="text" placeholder="Phone Number" className="w-full text-lg border-round-sm"
			                                name="phoneNumber"
			                                keyfilter="int"
			                                maxLength="10"
			                                value={outletDetails.phoneNumber}
			                                onChange={(e) => updateOutletValue(e)} />
		                              	{getFormErrorMessage('phoneNumber')}
		                            </div>
		                            <div className="field col"></div>
								</div>
								<div className="formgrid grid mb-2">
									Address
								</div>
								<div className="formgrid grid">
								    <div className="field col">
								        <label htmlFor="name" className="block text-lg font-medium my-1 text-gray-800">Name</label>
			                            <InputText type="text" placeholder="Name" className="w-full text-lg border-round-sm my-1"
			                                name="name"
			                                value={outletDetails.address.name}
			                                onChange={(e) => updateAddressValue(e)}  />
								    </div>
								    <div className="field col"></div>
								</div>
								<div className="formgrid grid">
								    <div className="field col">
								        <label htmlFor="line1" className="block text-lg font-medium my-1 text-gray-800">Address Line 1</label>
			                            <InputText type="text" placeholder="Address Line 1" className="w-full text-lg border-round-sm my-1"
			                                name="line1"
			                                value={outletDetails.address.line1}
			                                onChange={(e) => updateAddressValue(e)} />
								    </div>
								    <div className="field col">
								        <label htmlFor="line2" className="block text-lg font-medium my-1 text-gray-800">Address Line 2</label>
			                            <InputText type="text" placeholder="Address Line 2" className="w-full text-lg border-round-sm my-1"
			                                name="line2"
			                                value={outletDetails.address.line2}
			                                onChange={(e) => updateAddressValue(e)} />
								    </div>
								</div>
								<div className="formgrid grid">
								    <div className="field col">
								        <label htmlFor="city" className="block text-lg font-medium my-1 text-gray-800">City</label>
			                            <InputText type="text" placeholder="City" className="w-full text-lg border-round-sm"
					                        name="city"
					                        value={outletDetails.address.city} 
					                        onChange={(e) => updateAddressValue(e)} />
								    </div>
								    <div className="field col">
								        <label htmlFor="district" className="block text-lg font-medium my-1 text-gray-800">District</label>
			                            <InputText type="text" placeholder="District" className="w-full text-lg border-round-sm"
			                                name="district"
			                                value={outletDetails.address.district}
			                                onChange={(e) => updateAddressValue(e)} />
								    </div>
								</div>
								<div className="formgrid grid">
								    <div className="field col">
								        <label htmlFor="state" className="block text-lg font-medium my-1 text-gray-800">State</label>
			                            <InputText type="text" placeholder="State" className="w-full text-lg border-round-sm"
					                        name="state"
					                        value={outletDetails.address.state}
					                        onChange={(e) => updateAddressValue(e)} />
								    </div>
								    <div className="field col">
								        <label htmlFor="country" className="block text-lg font-medium my-1 text-gray-800">Country</label>
			                            <InputText type="text" placeholder="Country" className="w-full text-lg border-round-sm"
			                                name="country"
			                                value={outletDetails.address.country}
			                                onChange={(e) => updateAddressValue(e)} />
								    </div>
								</div>
								<div className="formgrid grid">
								    <div className="field col">
								        <label htmlFor="zipcode" className="block text-lg font-medium my-1 text-gray-800">Zipcode</label>
			                            <InputText type="text" placeholder="Zipcode" className="w-full text-lg border-round-sm"
					                        name="zipcode"
					                        keyfilter="int"
					                        maxLength="6"
					                        value={outletDetails.address.zipcode}
					                        onChange={(e) => updateAddressValue(e)} />
								    </div>
								    <div className="field col"></div>
								</div>
								<div className="formgrid grid">
									<div className="field col">
										<Button label="Save Outlet"
							    			className="w-full bg-yellow-500 text-sm border-round-sm mt-1"
							    			onClick={(e) => handleSubmit(e)} />
		                            </div>
		                            <div className="field col"></div>
								</div>
						    </div>
						    <div className="col-5">
						        <div className=""></div>
						    </div>
						</div>
					</div>
        		</div>
        		<Footer />
		    </div>
		</div>
        
    )

}

const outlet = {
	name: 't',
	numberOfOutlet: '2',
	phoneNumber: '34567788',
	address: {
		name: 't',
		line1: 't',
		line2: 'd',
		district: 'z',
		city: 'c',
		state: 's',
		country: 'c',
		zipcode: '3'
	},
	errors: {}
}

export default Outlets;