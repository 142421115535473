import React, { useState, useEffect , useRef}  from 'react';
import { useNavigate } from 'react-router-dom';
import {NavigationBarVertical, NavigationBarHorizontal, Footer, ProgressLoader} from './NavigationBar';
import { DataView, DataViewLayoutOptions } from 'primereact/dataview';
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';
import { Badge } from 'primereact/badge';
import { Avatar } from 'primereact/avatar';
import { Chip } from 'primereact/chip';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from "primereact/checkbox";
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import { AutoComplete } from "primereact/autocomplete";
import { ProgressSpinner } from 'primereact/progressspinner';
import { OverlayPanel } from 'primereact/overlaypanel';
import { RadioButton } from "primereact/radiobutton";
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useTimout } from 'primereact/hooks';
import { Dropdown } from 'primereact/dropdown';

import { ServerErrorModal, APIErrorModal, ConstantValues} from '../Utility';

import { FaPencil, FaTrashCan, FaPlus, FaUsers, FaCheck, FaXmark } from 'react-icons/fa6';



const ManageSegments = () => {

	const [segmentsData, setSegmentsData] = useState([]);
	const [brandsData, setBrandsData] = useState(JSON.parse(localStorage.getItem("brands")));
	const [dialogVisible, setDialogVisible] = useState(false);
	const [dialogSegment, setDialogSegment] = useState(newSegment);
	const [deleteSegment, setDeleteSegment] = useState(null);
	const [loaderVisible, setLoaderVisible] = useState(false);
	const [ddSegmentOptions, setDdSegmentOptions] = useState([]);
	const [ddSelectedSegment, setDdSelectedSegment] = useState(null);

	const [dialogAPIErrorFlag, setDialogAPIErrorFlag] = useState(false);
	const [dialogAPIError, setDialogAPIError] = useState(null);

	const [dialogAPIInfoFlag, setDialogAPIInfoFlag] = useState(false);
    const [dialogAPIInfoMessage, setDialogAPIInfoMessage] = useState(null);

	const [customersData, setCustomersData] = useState([]);
	const [selectedSegment, setSelectedSegment] = useState(null);

	const [changedCustomerSegment, setChangedCustomerSegment] = useState(null);
	const [selectedCategory, setSelectedCategory] = useState(1);

	const [selectedCustomerForChangeSegment, setSelectedCustomerForChangeSegment] = useState(null);
	const [selectedCustomerSegment, setSelectedCustomerSegment] = useState(null);
	const [radioBtnName, setRadioBtnName] = useState('');
	const [chooseSegemntVisible, setChooseSegemntVisible] = useState(false);
	const [customersDialogVisible, setCustomersDialogVisible] = useState(false);

	const [customersDataDialog, setCustomersDataDialog] = useState(customersData);
	const [changeCustomerSegmentTo, setChangeCustomerSegmentTo] = useState(null);
	const [chooseAllCustomers, setChooseAllCustomers] = useState(false);
	const [totalCustomerCount, setTotalCustomerCount] = useState(0);

	const [showBrandFlg, setShowBrandFlg] = useState(false);


	const navigate = useNavigate();

	const opSegChng = useRef(null);

	useEffect(() => { 
		if(undefined === localStorage.getItem('token') === null ||
				localStorage.getItem('token') === null) navigate('/'); 
		localStorage.setItem('currentPage', 'managesegments');
		if(checkBrandsAvailable()) {
			const brands = JSON.parse(localStorage.getItem('brands'));
			setShowBrandFlg(brands.length === 1 ? false : true);
            getSegmentsDetails();
        }
	}, []);

	const checkBrandsAvailable = () => {
		var currBrand = JSON.parse(localStorage.getItem("currentBrand"));
		var currBrandId = currBrand.brandId;
		if(currBrandId === -313131) {
            setDialogAPIInfoMessage('Please configure your brand(s) or contact system administrator');
            setDialogAPIInfoFlag(true);
            return false;
        }
        return true;
	}

	const getSegmentsDetails = () => {
		const sd = async() => {
			setLoaderVisible(true);
			try {
				var currBrand = JSON.parse(localStorage.getItem("currentBrand"));
				var currBrandId = currBrand.brandId;
		      	// send a request to the server to get dashboard details
		      	const response = await fetch('https://api.saltwin.com/loyalty-web-service/rest/v1/segment/' + currBrandId,
		      	{
			        method: 'GET',
			        headers: {
			          'Content-Type': 'application/json',
			          'Authorization': 'Bearer ' + localStorage.getItem('token')
			        }
		      	});
		      	const resJson = await response.json();
		      	SegmentsList(resJson);
		    } catch (err) {
		    	<ServerErrorModal />
		    }
		    setLoaderVisible(false);
		}
		sd(); 
  	}	

  	const SegmentsList = (resJson) => {
  		if(resJson.success === true) {
      		setSegmentsData(resJson.segments);
      	} else {
      		showPopupOnAPIError(resJson);
      	}
  	};

  	const showPopupOnAPIError = (resJson) => {
  		if('Unauthorized' === resJson.error) {
  			setDialogAPIErrorFlag(true);
  			setDialogAPIError(resJson.message);
  			setTimeout(() => {
			  navigate('/');
			}, 3000);
  		} else if(null !== resJson.error) {
  			setDialogAPIErrorFlag(true);
  			setDialogAPIError(resJson.error.message);
  		}
  	}

  	const getCustomersDetails = (segment) => {
		const sd = async() => {
			setLoaderVisible(true);
			try {
				setChangeCustomerSegmentTo(segment);
				setChooseAllCustomers(false);
				var currBrand = JSON.parse(localStorage.getItem("currentBrand"));
				var currBrandId = currBrand.brandId;
		      	// send a request to the server to get dashboard details
		      	const response = await fetch('https://api.saltwin.com/loyalty-web-service/rest/v1/customer/' + currBrandId,
		      	{
			        method: 'GET',
			        headers: {
			          'Content-Type': 'application/json',
			          'Authorization': 'Bearer ' + localStorage.getItem('token')
			        }
		      	});
		      	const resJson = await response.json();
		      	CustomersList(resJson, segment);
		    } catch (err) {
		    	<ServerErrorModal />
		    }
		    setLoaderVisible(false);
		}
		sd(); 
  	}	

  	const CustomersList = (resJson, segment) => {
  		setDdSegmentOptions([]);
  		var mappedCustomer = 0;
  		const seg = segment;
  		if(resJson.success === true) {
      		const customers = resJson.customerResponses;

	  		const newState = customers.map((c) => {
	  			if(seg.id === c.segment.id) {
	  				mappedCustomer += 1;
	  				return {...c, 'segChooseBox': true};
	  			}
	  			return {...c, 'segChooseBox': false};
	  		});

	  		setCustomersData(newState);
	  		setCustomersDataDialog(newState);
	  		setTotalCustomerCount(newState.length);

	  		var ddOptions = [];
	  		ddOptions.push({id: -1, name: 'All'});
	  		const ddSegOptions = segmentsData.map((s) => {
	  			ddOptions.push({id: s.id, name: s.name});
	  		});
	  		setDdSegmentOptions(ddOptions);
	  		setDdSelectedSegment({id: -1, name: 'All'});
	  		setCustomersDialogVisible(true);
      	} else {
      		showPopupOnAPIError(resJson);
      	}
  	};



    const AutoCompleteBox = () => {
    	const [valueAC, setValueAC] = useState('');
	    const [itemsAC, setItemsAC] = useState([]);

	    const searchAC = (event) => {
	        setItemsAC([...Array(10).keys()].map(item => event.query + '-' + item));
	    }

	    return (
	    	<>
	    	<AutoComplete className="w-8" inputClassName="w-12" placeholder="Search Customer"
	    			value={valueAC} suggestions={itemsAC} completeMethod={searchAC} onChange={(e) => setValueAC(e.value)} />

	    			</>);
    }

	const ConfigSegments = () => {
	return <div className="grid px-3">
				<AddEditSegment />
			    <div className="col-12">
			    	<div className="flex">
					    <div className="flex-1 flex justify-content-start align-items-center flex-wrap">
				    		<div className="text-2xl font-medium">Segments</div>
					    	<Avatar icon="pi pi-refresh" className="cursor-pointer"
					    		style={{ backgroundColor: '#fff', color: '#000'}} shape="circle"
					    		onClick ={(e) => getSegmentsDetails()} />
				    	</div>
				    	<div className="flex-1 flex justify-content-end flex-wrap">
				    		<Button icon={FaPlus} rounded text raised severity="secondary" aria-label="Add Segment"
				    			onClick={(e) => {
				    								if(checkBrandsAvailable()) {
				    									setDialogSegment(newSegment); 
				    									setDialogVisible(true);
				    								}
				    							}
				    					}>&nbsp;Add New</Button>
				    	</div>
					</div>
			        <div className="flex">
						<div className="flex flex-column overflow-auto" style={{ width: '100%', height: '70vh' }}>
							<DataView value={segmentsData} listTemplate={segmentListTemplate} />
					   </div>
					</div>
			    </div>
			</div>;
	}

	const AddUpdateDeleteOps = async(method, segment) => {
		setLoaderVisible(true);
        try {
            const response = await fetch('https://api.saltwin.com/loyalty-web-service/rest/v1/segment', {
                method: method,
                headers: {
                   'Content-Type': 'application/json',
			       'Authorization': 'Bearer ' + localStorage.getItem('token')
                },
                body: JSON.stringify(segment)
              });
            const resJson = await response.json();
            SegmentsList(resJson);
        } catch (err) {
            <ServerErrorModal />
        }
        setLoaderVisible(false);
	}

	const AddEditSegment = () => {

		var currBrand = JSON.parse(localStorage.getItem("currentBrand"));
		var currBrandId = currBrand.brandId;

		if(currBrandId !== dialogSegment.brandId) {
			brandsData.map((b, index) => {
				if(b.brandId === dialogSegment.brandId) {
					currBrand = b;
				}
			});
			currBrandId = currBrand.brandId;
		}

		const d_isActive = dialogSegment.active;
		const d_isDefault = dialogSegment.default;
		const d_name = dialogSegment.name;
		const d_brandId = currBrandId;
		const d_id = dialogSegment.id;

		const [isActive, setIsActive] = useState(d_isActive);
		const [isDefault, setIsDefault] = useState(d_isDefault);
		const [name, setName] = useState(d_name);
		const [invalidName, setInvalidName] = useState('');
		const [brandId, setBrandId] = useState(d_brandId);
		const [id, setId] = useState(d_id);

		const submitNewSegment = () => {
			const newSegment = {
								id: id,
								active: isActive ? true : false,
								default: isDefault ? true : false,
								name: name,
								brandId: brandId
							};

			if('' === name) {
				setInvalidName('invalid');
			} else {
				setInvalidName('');
				var httpMethod = 'PUT';
				if('' === dialogSegment.id) {
					httpMethod = 'POST';
				}
				AddUpdateDeleteOps(httpMethod, newSegment);
				setDialogVisible(false);
			}
		};

		const SegmentNameError = ({flag}) => {
			if(flag === 'invalid') {
				return <span className="text-xs text-red-500">Segment name should not be empty</span>;
			} else {
				return <></>;
			}
		}

		const updateNameVal = (val) => {
			if('' !== val || val.length > 0) setInvalidName('');
			setName(val);
		}

		return(	
			<Dialog header={ (d_id === null || d_id === '' ) ? 'Add New Segment' : 'Edit Segment' } className="text-lg" 
				visible={dialogVisible} style={{ width: '50vw' }} 
				onHide={() => setDialogVisible(false)} >

				<div className="grid">
				    <div className="col-6">
				        <div className="flex flex-column gap-2">
			                <label htmlFor="segment_name">Name</label>
			                <InputText flag="segment_name" value={name} onChange={(e) => updateNameVal(e.target.value)} />
			                <SegmentNameError flag={invalidName} />
			            </div>
				    </div>
				    <div className="col-6">
				    	<div className="flex flex-wrap justify-content-start gap-5">
				    		<div className="flex flex-column gap-2">
				                <label htmlFor="isActive">Active</label>
				                <Checkbox inputId="isActive" name="isActive" checked={isActive} onChange={e => setIsActive(e.checked)} />
				            </div>
				            <div className="flex flex-column gap-2">
				                <label htmlFor="isDefault">Default</label>
				                <Checkbox inputId="isDefault" name="isDefault" checked={isDefault} onChange={e => setIsDefault(e.checked)} />
				            </div>
        				</div>
				    </div>
				    <div className="col-6">
				        <div className="hidden"><div className="flex flex-column gap-2">
			                <label htmlFor="brand_name">Brand</label>
			                <InputText id="brand_name" disabled variant="filled" value={currBrand.name} className="capitalize" />
			            </div></div>
				    </div>
				    <div className="col-6">
				    	<div className="flex flex-wrap justify-content-end gap-2">
					        <Button icon={FaXmark} rounded severity="danger" aria-label="Cancel"
					        	onClick={() => setDialogVisible(false)} >&nbsp;Cancel</Button>
					        <Button icon={FaCheck} rounded severity="success" aria-label="Submit"
					        	onClick={() => submitNewSegment()} >&nbsp;Submit</Button>
				        </div>
				    </div>
				</div>
		    </Dialog>
		);
	}

	const segmentListTemplate = (items) => {
	    if (!items || items.length === 0) return null;

	    let list = items.map((segment, index) => {
	        return segmentItemTemplate(segment, index);
	    });

	    return <div className="grid grid-nogutter">{list}</div>;
	};

	const segmentItemTemplate = (segment, index) => {
		const segId = segment.id;
		const act_key = "key_active_" + segId;
		const def_key = "key_default_" + segId;

		var tag_severity;
		var activeFlgVal = 'Active';
		if(segment.active === true) {
			tag_severity = 'success'; 
			activeFlgVal = 'Active';
		} else {
			tag_severity = 'danger';
			activeFlgVal = 'Inctive';
		}

	    const BadgeIsDefault = () => {
			if(segment.default === true)
				return <Tag key={def_key} rounded value="Default"></Tag>;
			else
				return <></>;
		}

		const GetBrandChip = () => {
			if(false === showBrandFlg) {
				return <></>;
			} else {
				const bId = segment.brandId;
				var brandObj;
				brandsData.map((b, index) => {
					if(b.brandId === bId) {
						brandObj = b;
					}
				});
				/*if(null === brandObj.logo || '' === brandObj.logo) {
					return <Chip label={brandObj.name} image={process.env.PUBLIC_URL + '/logo192.png'} />;
				} else {
					return <Chip label={brandObj.name} image={process.env.PUBLIC_URL + '/' + brandObj.logo} />;
				}*/
				return <Chip label={brandObj.name} image={process.env.PUBLIC_URL + '/logo192.png'} className="capitalize" />;
			}
		}

		const accept = () => {
			AddUpdateDeleteOps('DELETE',deleteSegment);
		}

		const reject = () => {
			setDeleteSegment(null);
		}

		const deleteSegmentPopup = (event, segment) => {
	        confirmPopup({
	            target: event.currentTarget,
	            message: 'Do you want to delete this segment?',
	            icon: 'pi pi-info-circle',
	            defaultFocus: 'reject',
	            acceptClassName: 'p-button-danger',
	            accept,
	            reject
	        });
	        setDeleteSegment(segment);
	    };

	    return (
	    		<div className="col-12" key={segId}>
	                <div className="col-12 p-2 my-2 border-round-md border-1 border-yellow-300"  >
	                	<div className="flex align-items-center gap-2 pb-4 mb-2 border-bottom-1 border-300">
		                	<div className="flex-1">
		                		<p className="p-0 m-0 text-xl mb-2 font-medium text-900 capitalize">{segment.name}</p>
		                	</div>
	                    	<div className="flex sm:flex-column align-items-center sm:align-items-end gap-2 sm:gap-2">
	                        	<div className="flex flex align-items-center sm:align-items-end gap-2">
		                            <Button icon={FaUsers} rounded text raised severity="secondary" aria-label="Customers"
		                            	 className="bg-white cursor-pointer" onClick={(e) => getCustomersDetails(segment)} />
		                            <Button icon={FaPencil} rounded text raised severity="secondary" aria-label="Edit Segment"
		                            	 className="bg-white cursor-pointer" onClick={(e) => {setDialogSegment(segment); setDialogVisible(true)}} />
			                    	<Button icon={FaTrashCan} rounded text raised severity="secondary" aria-label="Delete Segment"
			                    		 className="bg-white cursor-pointer" onClick={(e) => deleteSegmentPopup(e, segment)} />
		                        </div>
	                        </div>
	                    </div>
	                    <div className="flex flex-column sm:flex-row justify-content-between align-items-center xl:align-items-start flex-1 gap-4">
	                        <div className="flex-1 align-items-end sm:align-items-start gap-2">
	                            <Tag key={act_key} rounded value={activeFlgVal} severity={tag_severity} className="mx-2" />
	                        </div>
	                        <div className="flex-1 sm:flex-column align-items-start sm:align-items-end gap-2 sm:gap-2">
	                            <BadgeIsDefault />
	                        </div>
	                        <div className="flex-1 sm:flex-column align-items-start sm:align-items-end gap-2 sm:gap-2">
	                            <GetBrandChip />
	                        </div>
	                        
	                    </div>
	                </div>
	                <ConfirmPopup />
	            </div>
	        );
    };

    const updateChooseAllCustomers = (flag) => {
		setChooseAllCustomers(flag);
		const newState = customersDataDialog.map((c) => {
			return {...c, 'segChooseBox': flag};
		});
		setCustomersDataDialog(newState);
	}

	const chooseCustomerToChangeSegment = (cusId) => {
		var chosenCust = 0;
		const newState = customersDataDialog.map((c) => {
			if(c.uuid === cusId) {
				const flg = !c.segChooseBox;
				if(flg === true) {chosenCust = chosenCust + 1; }
				return {...c, 'segChooseBox': flg};
			} else {
				const flg = c.segChooseBox;
				if(flg === true) {chosenCust = chosenCust + 1; }
			}
			return c;
		});
		
		if(chosenCust === totalCustomerCount) {
			setChooseAllCustomers(true);
		} else {
			setChooseAllCustomers(false);
		}
		setCustomersDataDialog(newState);
	}

    const chooseCustomerSegment = (e) => {

    }

    const accept = () => {
    };

    const reject = () => {
    };

	const changeCustomerSegment = (seg) => {
		setSelectedCustomerSegment(seg);
    	const selCust = selectedCustomerForChangeSegment;

		const sd = async() => {
			setLoaderVisible(true);
			try {
				var currBrand = JSON.parse(localStorage.getItem("currentBrand"));
				var currBrandId = currBrand.brandId;

				const cusSegReq = {
					uuid: selCust.uuid,
					brandId: currBrandId,
					segment: seg
				};
		      	// send a request to the server to update customer segemnt
		      	const response = await fetch('https://api.saltwin.com/loyalty-web-service/rest/v1/customer/update',
		      	{
			        method: 'PUT',
			        headers: {
			          'Content-Type': 'application/json',
			          'Authorization': 'Bearer ' + localStorage.getItem('token')
			        },
                	body: JSON.stringify(cusSegReq)
		      	});
		      	const resJson = await response.json();
		      	if(null === resJson.error) {
		      		const newState = customersData.map((c) => {
			    		if(c.uuid === selCust.uuid) {

			    			return {...c, segment: seg};
			    		}
			    		return c;
			    	});
			    	setCustomersData(newState);
			    	setChooseSegemntVisible(false);
		      	} else {
		      		setDialogAPIErrorFlag(true);
		      		setDialogAPIError(resJson.error.message);
		      	}
		    } catch (err) {
		    	<ServerErrorModal />
		    }
		    setLoaderVisible(false);
		}
		sd(); 
  	}

	const CustomerSearchBox = () => {
    	const [valueCustomer, setValueCustomer] = useState(null);
	    const [itemsCustomer, setItemsCustomer] = useState([]);
	    const [filteredCustomer, setFilteredCustomer] = useState(null);

	    const searchCustomer = (event) => {
		    	setTimeout(() => {
	           const srchStr = event.query;

		    	let _filteredCustomers;
		    	if (!event.query.trim().length) {
	                _filteredCustomers = [...customersData];
	            } else {
	            	_filteredCustomers = customersData.filter((cus) => {
	            		return (cus.phoneNumber.includes(srchStr) || (null !== cus.name && cus.name.toLowerCase().includes(srchStr.toLowerCase())));
	            	});
	            }
		    	setFilteredCustomer(_filteredCustomers);
		    	setCustomersDataDialog(_filteredCustomers);
	        }, 250);
	    	
	    }

	    return (
	    	<>
	    		<AutoComplete className="w-6" inputClassName="w-12" placeholder="Search by Phone Number" field="phoneNumber"
	    			value={valueCustomer} suggestions={filteredCustomer} completeMethod={searchCustomer} 
	    			onChange={(e) => setValueCustomer(e.value)} />
	    	</>);
    }

    const updateSegmentForSelectedCustomers = () => {
    	const currBrand = JSON.parse(localStorage.getItem("currentBrand"));
		const currBrandId = currBrand.brandId;

		const segment = changeCustomerSegmentTo;

		var selectedCustomers = [];
		customersDataDialog.map((sc) => {
			if(true === sc.segChooseBox) {
				selectedCustomers.push({
					uuid: sc.uuid
				});
			}
		});

		const sd = async() => {
			setLoaderVisible(true);
			try {
				var currBrand = JSON.parse(localStorage.getItem("currentBrand"));
				var currBrandId = currBrand.brandId;

				const cusSegReq = {
					customerSegmentRequestList: selectedCustomers,
					brandId: currBrandId,
					segment: segment
				};
		      	// send a request to the server to update customer segemnt
		      	const response = await fetch('https://api.saltwin.com/loyalty-web-service/rest/v1/customer/update/list',
		      	{
			        method: 'PUT',
			        headers: {
			          'Content-Type': 'application/json',
			          'Authorization': 'Bearer ' + localStorage.getItem('token')
			        },
                	body: JSON.stringify(cusSegReq)
		      	});
		      	const resJson = await response.json();
		      	if(true === resJson.success) {
		      		setChooseAllCustomers(false);
		      		const newState = customersDataDialog.map((c) => {
		      			var newCusState = c;
		      			resJson.customerResponses.map((resc) => {
							if(c.uuid === resc.uuid) {
								newCusState = {...newCusState, segment: resc.segment};
							}
						});
						return newCusState;
		      		});
		      		setCustomersDataDialog(newState);

		      	} else {
		      		setDialogAPIErrorFlag(true);
		      		setDialogAPIError(resJson.error.message);
		      	}
		    } catch (err) {
		    	<ServerErrorModal />
		    }
		    setLoaderVisible(false);
		}
		if(selectedCustomers.length > 0) {
			sd(); 
		}
    }

    const changeCustomersDataDialogDD = (e) => {
    	setChooseAllCustomers(false);
    	if(e.value.id === -1) { 
    		setCustomersDataDialog(customersData);
	  		setTotalCustomerCount(customersData.length);
    	} else {
    		const newState = customersData
    			.filter((c) =>  e.value.id === c.segment.id )
    			.map((c) => {
					return {...c, 'segChooseBox': false};
				});
	  		setCustomersDataDialog(newState);
	  		setTotalCustomerCount(newState.length);
    	}
    	setDdSelectedSegment(e.value);
    }

	const chooseSegmentChkBxTemplate = (customer) => {
	    return <Checkbox value={customer.uuid} checked={customer.segChooseBox} onChange={(e) => chooseCustomerToChangeSegment(customer.uuid)} ></Checkbox>;
	};

	const chooseSegmentChkBxAllTemplate = () => {
	    return <Checkbox value="all" onChange={() => updateChooseAllCustomers(!chooseAllCustomers)} checked={chooseAllCustomers}></Checkbox>;
	};

	const phoneBodyTemplate = (customer) => { 
		return <>{customer.countryCode} {customer.phoneNumber}</>;
	};

	const segmentNameBodyTemplate = (customer) => { 
		return <>{customer.segment.name}</>;
	};

	return (
		<>
		<div className="relative p-0 m-0">
		    <div className="relative text-gray-800 w-screen h-screen">
        		<NavigationBarVertical />
		        <div className="absolute top-0 left-0 h-4rem px-2 border-200 border-bottom-1" style={{ width: ConstantValues.hNavBarWidth, height: '95%', marginLeft: ConstantValues.vNavBarWidth }}>
		        	<NavigationBarHorizontal hideLinkOf='ManageCustomerSegments' />
					<div id="show-saltwin-content" className="absolute top-4rem left-0 px-2 py-4" style={{ width: '100%', height: '95%' }}>
						<div id="cust-segments" className="py-2 relative">
							<div className="grid overflow-hidden" style={{height: '86vh' }}>
							    <div className="col-12">
							        <ConfigSegments />
							    </div>
							</div>
						</div>
					</div>
        		</div>
        		<Footer />
		    </div>
		</div>
		<ProgressLoader loaderVisible={loaderVisible} />
        <Dialog header="Error" headerClassName="text-red-500" visible={dialogAPIErrorFlag} style={{ width: '50vw' }} onHide={() => setDialogAPIErrorFlag(false)}>
	        <div className="m-0 text-lg font-medium mb-2 text-gray-800">
	            <div className="font-light">{dialogAPIError}</div>
	        </div>
	    </Dialog>
	    <Dialog header={'Update Customers Segment to - ' + (null !== changeCustomerSegmentTo ? changeCustomerSegmentTo.name : '')} className="text-lg" 
			visible={customersDialogVisible} style={{ width: '50vw', height: '100vh', maxHeight: '100%' }} 
			onHide={() => setCustomersDialogVisible(false)}
			position="right" >
			<div style={{ height: '100vh', maxHeight: '100%' }} >
				<div className="py-2" style={{ height: '95%'}}>
					<div class="flex justify-content-end flex-wrap">
						<Dropdown value={ddSelectedSegment} onChange={(e) => changeCustomersDataDialogDD(e)} options={ddSegmentOptions} optionLabel="name" 
	                		className="w-full md:w-14rem" />
	                </div>
					<DataTable value={customersDataDialog} 
						className="text-lg font-light mt-2"
						scrollable scrollHeight="70vh"
						sortMode="multiple"
						paginator rows={200} rowsPerPageOptions={[10, 25, 50, 100, 200]} >
		                <Column field="segChooseBox" header={chooseSegmentChkBxAllTemplate} body={chooseSegmentChkBxTemplate}></Column>
		                <Column field="phoneNumber" header="Phone" body={phoneBodyTemplate} />
		                <Column field="name" header="Name" />
		                <Column field="segment" header="Segment" body={segmentNameBodyTemplate} />
		                <Column field="pointBalance" header="Balance" align="right" />
		            </DataTable>
				</div>
				<div class="flex justify-content-center flex-wrap mt-3" style={{ height: '5%' }}>
					<Button className="w-full"
						onClick={(e) => updateSegmentForSelectedCustomers()}
						label={'Update Customers Segment to - ' + (null !== changeCustomerSegmentTo ? changeCustomerSegmentTo.name : '')}  />
				</div>
			</div>
	    </Dialog>
	    <Dialog header="Info" visible={dialogAPIInfoFlag} style={{ width: '50vw' }} onHide={() => setDialogAPIInfoFlag(false)}>
            <div className="m-0 text-lg font-medium mb-2 text-gray-800">
                <div className="font-light">{dialogAPIInfoMessage}</div>
            </div>
        </Dialog>
		</>
    )
} 

const newSegment = {
						id: '',
						active: true,
						default: false,
						name: '',
						brandId: -1
					};

export default ManageSegments;