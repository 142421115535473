import React, { useState, useEffect}  from 'react';
import { useNavigate } from 'react-router-dom';
import {NavigationBarVertical, NavigationBarHorizontal, Footer, ProgressLoader} from './NavigationBar';
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Calendar } from 'primereact/calendar';
import { RadioButton } from 'primereact/radiobutton';
import { Dropdown } from 'primereact/dropdown';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Dialog } from 'primereact/dialog';
import { DataView } from 'primereact/dataview';
import { classNames } from 'primereact/utils';

import { ServerErrorModal, ConstantValues, CheckEmail, CountryPhoneCodes} from '../Utility';



const Customer = () => {

	const [custAddDetBtlLbl, setcustAddDetBtlLbl] = useState("Add more customer details");
	const [custAddDetBtlIcon, setcustAddDetBtlIcon] = useState("pi pi-plus");

	const [segmentsData, setSegmentsData] = useState(null);
	const [loaderVisible, setLoaderVisible] = useState(false);

	const [dialogAPIErrorFlag, setDialogAPIErrorFlag] = useState(false);
	const [dialogAPIError, setDialogAPIError] = useState(null);

	const [dialogAPIInfoFlag, setDialogAPIInfoFlag] = useState(false);
	const [dialogAPIInfoMessage, setDialogAPIInfoMessage] = useState(null);

	const[_customer, _setCustomer] = useState(customerEmptyObj);
	const[_customerSegment, _setCustomerSegment] = useState(null);

	const [custActionType, setCustActionType] = useState('');
    const codes = CountryPhoneCodes;
	
	const[addDtlBlkDiv, setAddDtlBlkDiv] = useState('hidden');
	const[custDetailsDivFlg, setCustDetailsDivFlg] = useState('hidden');

	const [customerSearchQuery, setCustomerSearchQuery] = useState(''); 
    const [customersResultViewFlag, setCustomersResultViewFlag] = useState('hidden');
    const [customersResult, setCustomersResult] = useState([]);
    const [redeemPoints, setRedeemPoints] = useState('');
    const [bonusPoints, setBonusPoints] = useState('');

    const [redeemExistingCustomerDivFlg, setRedeemExistingCustomerDivFlg] = useState('hidden');

	const navigate = useNavigate();

	useEffect(() => {
		if(localStorage.getItem('token') === null) navigate('/home'); 
		localStorage.setItem('currentPage', 'customer');
		if(checkBrandsAvailable()) {
			getSegmentsDetails();
		}
	}, []);

	const checkBrandsAvailable = () => {
		var currBrand = JSON.parse(localStorage.getItem("currentBrand"));
		var currBrandId = currBrand.brandId;
		if(currBrandId === -313131) {
            setDialogAPIInfoMessage('Please configure your brand(s) or contact system administrator');
            setDialogAPIInfoFlag(true);
            return false;
        }
        return true;
	}

	const getSegmentsDetails = () => {
		const sd = async() => {
			setLoaderVisible(true);
			try {
				var currBrand = JSON.parse(localStorage.getItem("currentBrand"));
				var currBrandId = currBrand.brandId;
		      	// send a request to the server to get dashboard details
		      	const response = await fetch('https://api.saltwin.com/loyalty-web-service/rest/v1/segment/' + currBrandId,
		      	{
			        method: 'GET',
			        headers: {
			          'Content-Type': 'application/json',
			          'Authorization': 'Bearer ' + localStorage.getItem('token')
			        }
		      	});
		      	const resJson = await response.json();
		      	if(true === resJson.success) {
		      		setSegmentsData(resJson.segments);
		      	} else {
		      		if('Unauthorized' === resJson.error) {
		      			setDialogAPIErrorFlag(true);
		      			setDialogAPIError(resJson.message);
		      			setTimeout(() => {
						  navigate('/');
						}, 3000);
		      		}  else if(undefined !== resJson.error.message) {
			  			setDialogAPIErrorFlag(true);
			  			setDialogAPIError(resJson.error.message);
			  		} else if(null !== resJson.error) {
			  			setDialogAPIErrorFlag(true);
			  			setDialogAPIError(resJson.error);
			  		}
		      	}
		    } catch (err) {
		    	<ServerErrorModal />
		    }
		    setLoaderVisible(false);
		}
		sd(); 
  	}

  	const saveCustomerDetails = () => {
  		if(null === _customer.phoneNumber || '' === _customer.phoneNumber) {
  			setDialogAPIError('Please provide customer phone number');
		    setDialogAPIErrorFlag(true);
		    return;
  		} 
  		if(null === _customer.countryCode || '' === _customer.countryCode ) {
  			setDialogAPIError('Please choose country code');
		    setDialogAPIErrorFlag(true);
		    return;
  		}
  		if(null === _customer.email || '' === _customer.email) {
  			// do nothing
  		} else if(!CheckEmail(_customer.email)) {
  				setDialogAPIError('Please provide valid email');
			    setDialogAPIErrorFlag(true);
			    return;
  		}
  		if(null === _customer.segment) {
			setDialogAPIError('Please choose segment');
		    setDialogAPIErrorFlag(true);
		    return;
  		}

  		const httpMethod = (custActionType === 'Add' ? 'POST' : 'PUT');
  		
  		const sd = async() => {
			setLoaderVisible(true);
			try {
		      	// send a request to the server to update customer segemnt
		      	const response = await fetch('https://api.saltwin.com/loyalty-web-service/rest/v1/customer/',
		      	{
			        method: httpMethod,
			        headers: {
			          'Content-Type': 'application/json',
			          'Authorization': 'Bearer ' + localStorage.getItem('token')
			        },
			        body: JSON.stringify(_customer)
		      	});
		      	const resJson = await response.json();
		      	if(true === resJson.success) {
		      		if(null !== resJson.uuid) {
		      			//_setCustomer(customerEmptyObj);
		      			_setCustomer(resJson);
		      			setCustomersResult([]);
		      			//setCustDetailsDivFlg('hidden');
						setDialogAPIInfoMessage('Customer ' + (custActionType === 'Add' ? 'added' : 'updated') +' successfully');
		      			setDialogAPIInfoFlag(true);
		      			setCustomerSearchQuery('');
		      			//setRedeemExistingCustomerDivFlg('hidden');
		      		}
		      	} else {
		      		if('Unauthorized' === resJson.error) {
		      			setDialogAPIErrorFlag(true);
		      			setDialogAPIError(resJson.message);
		      			setTimeout(() => {
						  navigate('/');
						}, 3000);
		      		}  else if(undefined !== resJson.error.message) {
			  			setDialogAPIErrorFlag(true);
			  			setDialogAPIError(resJson.error.message);
			  		} else if(null !== resJson.error) {
			  			setDialogAPIErrorFlag(true);
			  			setDialogAPIError(resJson.error);
			  		}
		      	}
		    } catch (err) {
		    	<ServerErrorModal />
		    }
		    setLoaderVisible(false);
		}
		sd(); 
  	}

    const showHideAddDtlBlkDiv = (e) => {
    	if('hidden' === addDtlBlkDiv) {
    		setAddDtlBlkDiv('block flex flex-column gap-3');
    		setcustAddDetBtlLbl("Hide additional details");
    		setcustAddDetBtlIcon("pi pi-minus");
    	} else {
    		setAddDtlBlkDiv('hidden');
    		setcustAddDetBtlLbl("Add more customer details");
    		setcustAddDetBtlIcon("pi pi-plus");
    	}
    }  

    const searchCustomerByPhone = (phoneNum) => {
		const sd = async() => {
			try {
				var currBrand = JSON.parse(localStorage.getItem("currentBrand"));
				var currBrandId = currBrand.brandId;

		      	// send a request to the server to update customer segemnt
		      	const response = await fetch('https://api.saltwin.com/loyalty-web-service/rest/v1/customer/' + currBrandId + '/' + phoneNum,
		      	{
			        method: 'GET',
			        headers: {
			          'Content-Type': 'application/json',
			          'Authorization': 'Bearer ' + localStorage.getItem('token')
			        }
		      	});
		      	const resJson = await response.json();
		      	if(null === resJson.error) {
		      		if(resJson.customerResponses.length === 0) {
		      			const newCust = [{
											    "name": null,
											    "countryCode": null,
											    "phoneNumber": phoneNum,
										    	"isNew": true,
											    "dob": null,
											    "anniversaryDate": null,
											    "membershipId": '',
											    "membershipStartDate": null,
											    "membershipEndDate": null,
											    "email": null,
											    "outletId": null,
											    "uuid": null,
											    "segment": null,
											    "pointBalance": null,
											    "totalRedeemption": null,
											    "dateOfJoining": null,
											    "totalPointsEarn": null,
											    "pointMoneyRatio": null,
											    "timeZone": null,
											    "brandId": currBrandId
											  }];
		      			setCustomersResult(newCust);
		      		} else {
		      			setCustomersResult(resJson.customerResponses);
		      		}
		      	} 
		    } catch (err) {
		    	<ServerErrorModal />
		    }
		}
		sd();
  	}

    const searchCustomerByQuery = (query) => {
    	if(!checkBrandsAvailable()) {
			return;
		}
    	setCustomerSearchQuery(query);
    	if(query.length > 2) {
    		setCustomersResultViewFlag('block');
	    	searchCustomerByPhone(query);
	    	setCustomersResult(fetchingCustomerResult);
    	} else if(query.length === 0) {
    		setCustomersResultViewFlag('hidden');
    		setCustDetailsDivFlg('hidden');
	    	setCustomersResult([]);
	    	_setCustomer(customerEmptyObj);
	    	setRedeemExistingCustomerDivFlg('hidden');
    	}
    }

    const itemCustomerTemplate = (customer, index) => {
    	const actionType = (undefined === customer.isNew ? 'Edit' : 'Add');
    	const actionIcon = 'pi ' + (undefined === customer.isNew ? 'pi-arrow-right' : 'pi-user-plus');
        return (
            <div className="col-12"  key={customer.phoneNumber + '_' + customer.name}>
                <div className="border-bottom-1 border-gray-300">
                	<Button className="bg-white border-white w-full my-1 p-0"
                		onClick={(e) => addUpdateCustomer(actionType, customer)}>
	                	<div className="grid grid-nogutter p-3 w-full">
	                		<div className="col-8">
	                			<div className="flex flex-column">
	                				<div className="text-xs font-light text-900 text-left">{customer.name}</div>
								    <div className="text-sm font-medium text-900 text-left">{customer.phoneNumber}</div>
								</div>
						    </div>
						    <div className="col-4">
						        <div className="flex align-items-center justify-content-end">
						        	<div className="text-xs underline font-medium cursor-pointer" 
						        		onClick={(e) => addUpdateCustomer(actionType, customer)}>
						        		<i className={actionIcon}></i>
						        	</div>
							    </div>
						    </div>
						</div>
					</Button>
                </div>
            </div>
        );
    };

    const listCustomersTemplate = (items) => {
        if (!items || items.length === 0) return null;

        let list = items.map((customer, index) => {
            return itemCustomerTemplate(customer, index);
        });

        return <div className="grid grid-nogutter">{list}</div>;
    };

    const addUpdateCustomer = (actionType, customer) => {
    	setCustomersResultViewFlag('hidden');
    	setCustDetailsDivFlg('block');
	    setCustomersResult([]);
    	setCustActionType(actionType);
    	_setCustomer(customer);
    	if(null !== customer.segment) {
    		let _custSeg = null;
	    	segmentsData.map((s) => {
	    		if(s.id === customer.segment.id) {
	    			_custSeg = s;
	    		}
	    	});
	    	_setCustomerSegment(_custSeg);
    	}
    	if('Edit' === actionType) {
    		setRedeemExistingCustomerDivFlg('block');
    	} else {
    		setRedeemExistingCustomerDivFlg('hidden');
    	}
    }

    const _updateCustomerDetails = (e) => {
    	const t = e.target;
    	const n = t.name;
    	const v = t.value;

    	var newState;
    	if('segment' === n) {
    		_setCustomerSegment(v);
    	}
    	newState = {..._customer, [n]: v};
    	_setCustomer(newState);
    }

    const redeemCashbackPoints = (points) => {
		const sd = async() => {
			setLoaderVisible(true);
			try {
				var currBrand = JSON.parse(localStorage.getItem("currentBrand"));
				var currBrandId = currBrand.brandId;

				var payload = {
					brandId: currBrandId,
					cashbackToRedeem: points,
					redeemptionType: 'CASHBACK',
					customer: _customer
				};

		      	// send a request to the server to redeem the points
		      	const response = await fetch('https://api.saltwin.com/loyalty-web-service/rest/v1/customer/redeem',
		      	{
			        method: 'POST',
			        headers: {
			          'Content-Type': 'application/json',
			          'Authorization': 'Bearer ' + localStorage.getItem('token')
			        },
			        body: JSON.stringify(payload)
		      	});
		      	const resJson = await response.json();
		      	if(true === resJson.success) {
		      		_setCustomer(resJson.customer);
		      		setRedeemPoints('');
		      		setDialogAPIInfoFlag(true);
		      		setDialogAPIInfoMessage(points + ' cashback redeemed successfully.');
		      	} else {
		      		setDialogAPIErrorFlag(true);
		      		setDialogAPIError(resJson.error.message);
		      	}
		    } catch (err) {
		    	<ServerErrorModal />
		    }
		    setLoaderVisible(false);
		}
		sd(); 
  	}

  	const addBonusCashbackPoints = (points) => {
  		const sd = async() => {
			setLoaderVisible(true);
			try {
				var currBrand = JSON.parse(localStorage.getItem("currentBrand"));
				var currBrandId = currBrand.brandId;

				var payload = {
					brandId: currBrandId,
					bonusAmount: points,
					customerUuid: _customer.uuid,
					bonusType: 'CASHBACK'
				};

		      	// send a request to the server to redeem the points
		      	const response = await fetch('https://api.saltwin.com/loyalty-web-service/rest/v1/customer/add-bonus',
		      	{
			        method: 'POST',
			        headers: {
			          'Content-Type': 'application/json',
			          'Authorization': 'Bearer ' + localStorage.getItem('token')
			        },
			        body: JSON.stringify(payload)
		      	});
		      	const resJson = await response.json();
		      	if(true === resJson.success) {
		      		setBonusPoints('');
		      		setDialogAPIInfoFlag(true);
		      		setDialogAPIInfoMessage('Bonus cashback of '+ points + ' credited successfully.');
		      		const newState = {..._customer, 'pointBalance': resJson.totalPoints};
		      		_setCustomer(newState);
		      	} else {
		      		setDialogAPIErrorFlag(true);
		      		setDialogAPIError(resJson.error.message);
		      	}
		    } catch (err) {
		    	<ServerErrorModal />
		    }
		    setLoaderVisible(false);
		}
		sd(); 
  	}

  	const RedeemAllPointsBtn = ({points}) => {
  		if(points > 0) {
  			return <div className="hidden">
  					<div className="my-3 border-bottom-1 border-gray-300 "></div>
  					<Button label={'Redeem All ' + points + ' points'} className="w-full"
  						onClick={(e) => redeemCashbackPoints(points)} />
  				</div>;
  		} else {
  			return <></>;
  		}
  	}

  	const RedeemPartialPointsBtn = () => {
  		if(redeemPoints > 0 && redeemPoints <= _customer.pointBalance) {
  			return <Button label="Redeem" className="ml-3 w-3"
						onClick={(e) => redeemCashbackPoints(redeemPoints)} />;
  		} else {
  			return <Button label="Redeem" className="ml-3 w-3" disabled
						onClick={(e) => redeemCashbackPoints(redeemPoints)} />;
  		}
  	}

  	const AddBonusPointsBtn = () => {
  		if(bonusPoints > 0) {
  			return <Button label="Add" className="ml-3 w-3"
						onClick={(e) => addBonusCashbackPoints(bonusPoints)} />;
  		} else {
  			return <Button label="Add" className="ml-3 w-3" disabled
						onClick={(e) => addBonusCashbackPoints(bonusPoints)} />;
  		}
  	}

  	const [value, setValue] = useState();
  	let maxDate = new Date();

	return (
		<>
		<div className="relative p-0 m-0">
		    <div className="relative text-gray-800 w-screen h-screen">
        		<NavigationBarVertical />
		        <div className="absolute top-0 left-0 h-4rem px-2 border-200 border-bottom-1" style={{ width: ConstantValues.hNavBarWidth, height: '95%', marginLeft: ConstantValues.vNavBarWidth }}>
		        	<NavigationBarHorizontal hideLinkOf='ManageCustomerSegments' />
					<div id="show-saltwin-content" className="absolute top-4rem left-0 px-2 py-4" style={{ width: '100%', height: '95%' }}>
						<div id="cust-segments" className="py-2 relative">
							<div className="grid overflow-hidden" style={{height: '86vh' }}>
							    <div className="col-12">
							        <div className="grid px-3">
									    <div className="col-6">
									    	<div className="flex">
											    <div className="flex-1 flex justify-content-start align-items-center flex-wrap">
										    		<div className="shadow-none relative">
										    			<div className="text-2xl font-medium mb-2 capitalize">Customer</div>
										    			<div className="flex justify-content-start gap-2">
										    				<InputText id="searchCustomer" name="searchCustomer" placeholder="Search Phone Number" className="w-12"
															value={customerSearchQuery} onChange={(e) => searchCustomerByQuery(e.target.value)} keyfilter="int" />
															<Button label="Clear" className="w-6rem"
																onClick={(e) => {setCustomerSearchQuery(''); searchCustomerByQuery('');}}></Button>
										    			</div>
													    <DataView className={classNames('w-12 shadow-2 absolute left-0 top-3 z-5', {customersResultViewFlag})}
													    	value={customersResult} listTemplate={listCustomersTemplate} />
													</div>
										    	</div>
											</div>
									        <div className="flex">
												<div className="flex flex-column overflow-auto" style={{ width: ConstantValues.contentViewWidth, height: ConstantValues.contentViewHeight }}>
													<div className={custDetailsDivFlg}>
														<div className="p-3 mt-3 border-round-md border-1 border-gray-100 shadow-1">
															<div className="flex flex-column gap-3 overflow-y-auto p-1" style={{ height: '70vh'}}>
																<div className="flex flex-column gap-1">
																    <label htmlFor="customerName">Name</label>
																    <InputText id="customerName" name="name" value={_customer.name === null ? '' : _customer.name} onChange={(e) => _updateCustomerDetails(e)} />
																</div>
																<div className="flex flex-column gap-1">
																    <label htmlFor="phoneNumber">Phone Number</label>
																    <div className="flex-1 flex justify-content-start align-items-center">
															    		<Dropdown name="countryCode" value={_customer.countryCode} onChange={(e) => _updateCustomerDetails(e)} options={codes} optionLabel="name"
								                							filter placeholder="Code" className="mr-2 w-8" />
															    		<InputText id="phoneNumber" name="phoneNumber" value={_customer.phoneNumber === null ? '' : _customer.phoneNumber} onChange={(e) => _updateCustomerDetails(e)} keyfilter="int" className="w-8" />
															    	</div>
																</div>
																<div className="flex flex-column gap-1">
																    <label className="text-xs font-light text-bluegray-500">Customer will earn cashback based on this value</label>
																    <Button label={custAddDetBtlLbl} id="customerAdditionalDetailsBtn" className="text-left font-medium bg-gray-200 border-white" 
																    	icon={custAddDetBtlIcon} iconPos="right"
																    	onClick={(e) => showHideAddDtlBlkDiv()} />
																</div>
																<div className={addDtlBlkDiv}>
																	<div className="flex flex-column gap-1">
																	    <label htmlFor="membershipId">Membership Number</label>
																	    <InputText id="membershipId" name="membershipId" value={_customer.membershipId === null ? '' : _customer.membershipId} onChange={(e) => _updateCustomerDetails(e)} />
																	</div>
																	<div className="flex flex-row">
																		<div className="flex flex-1 flex-column gap-1 pr-1">
																		    <label htmlFor="membershipStartDate">Membership Start</label>
																		    <Calendar dateFormat="M d, yy" id="membershipStartDate" name="membershipStartDate" value={_customer.membershipStartDate === null ? '' : new Date(_customer.membershipStartDate)} onChange={(e) => _updateCustomerDetails(e)} />
																		</div>
																		<div className="flex flex-1 flex-column gap-1 pl-1">
																		    <label htmlFor="membershipEndDate">Membership End</label>
																		    <Calendar dateFormat="M d, yy" id="membershipEndDate" name="membershipEndDate" value={_customer.membershipEndDate === null ? '' : new Date(_customer.membershipEndDate)} onChange={(e) => _updateCustomerDetails(e)} />
																		</div>
																	</div>
																	<div className="flex flex-column gap-1">
																	    <label htmlFor="email">Email</label>
																	    <InputText id="email" name="email" value={_customer.email === null ? '' : _customer.email} onChange={(e) => _updateCustomerDetails(e)} />
																	</div>
																	<div className="flex flex-row">
																		<div className="flex flex-1 flex-column gap-1 pr-1">
																		    <label htmlFor="birthday">Birthday</label>
																		    <Calendar dateFormat="M d, yy" id="dob" name="dob" value={_customer.dob === null ? '' : new Date(_customer.dob)} 
																		    	onChange={(e) => _updateCustomerDetails(e)}
																		    	maxDate={maxDate} readOnlyInput />
																		</div>
																		<div className="flex flex-1 flex-column gap-1 pl-1">
																		    <label htmlFor="anniversaryDate">Anniversary</label>
																		    <Calendar dateFormat="M d, yy" id="anniversaryDate" name="anniversaryDate" value={_customer.anniversaryDate === null ? '' : new Date(_customer.anniversaryDate)} 
																		    	onChange={(e) => _updateCustomerDetails(e)}
																		    	maxDate={maxDate} readOnlyInput />
																		</div>
																	</div>
																	<div className="flex flex-row gap-3">
														                <div className="flex align-items-center">
														                    <RadioButton inputId="male" name="gender" value="male" onChange={(e) => _updateCustomerDetails(e)} checked={_customer.gender === 'male'} />
														                    <label htmlFor="male" className="ml-2">Male</label>
														                </div>
														                <div className="flex align-items-center">
														                    <RadioButton inputId="female" name="gender" value="female" onChange={(e) => _updateCustomerDetails(e)} checked={_customer.gender === 'female'} />
														                    <label htmlFor="female" className="ml-2">Female</label>
														                </div>
														            </div>
														            <div className="flex flex-column gap-1">
																	    <label htmlFor="address">Address</label>
																	    <InputTextarea id="address" name="address" value={_customer.address === null ? '' : _customer.address} onChange={(e) => _updateCustomerDetails(e)}
																	    	rows={5} cols={30} />
																	</div>
																	<div className="flex flex-row">
																		<div className="flex flex-1 flex-column gap-1 pr-1">
																		    <label htmlFor="city">City</label>
																	    	<InputText id="city" name="city" value={_customer.city === null ? '' : _customer.city} onChange={(e) => _updateCustomerDetails(e)} />
																		</div>
																		<div className="flex flex-1 flex-column gap-1 pl-1">
																		    <label htmlFor="state">State/Province/Region</label>
																	    	<InputText id="state" name="state" value={_customer.state === null ? '' : _customer.state} onChange={(e) => _updateCustomerDetails(e)} />
																		</div>
																	</div>
																	<div className="flex flex-row">
																		<div className="flex flex-1 flex-column gap-1 pr-1">
																		    <label htmlFor="city">Country</label>
																	    	<InputText id="city" name="city" value={_customer.city === null ? '' : _customer.city} onChange={(e) => _updateCustomerDetails(e)} />
																		</div>
																		<div className="flex flex-1 flex-column gap-1 pl-1">
																		    
																		</div>
																	</div>
														            <div className="flex flex-column gap-1">
																	    <label htmlFor="segment">Customer Segment</label>
																	    <Dropdown value={_customerSegment} id="segment" name="segment" onChange={(e) => _updateCustomerDetails(e)} options={segmentsData} optionLabel="name" 
													    				placeholder="Choose Segment" className="w-full md:w-14rem" />
																	</div>
																</div>
																<div className="flex flex-column gap-1">
																    <Button label="Submit" className="w-5"
																    	onClick={(e) => saveCustomerDetails()} />
																</div>
																<div className="my-1 hidden">
																    <Checkbox checked className="mr-2" />
																    <label className="text-xs font-medium text-bluegray-500">
																    	Add loyalty cashback to this customer. 
																    	Customer agrees to receive communication from SALTWIN
																    </label>
																</div>
															</div>
														</div>
													</div>
											   </div>
											</div>
									    </div>
									    <div className="col-6 px-4">
									    	<div className="flex">
											    <div className="flex-1 flex-column justify-content-start align-items-center">
											    	<div className="text-2xl font-medium mb-2 capitalize">cashback and rewards</div>
											    	<div className="font-light mb-2">Customers earn 50% Cashback on any purchase</div>
										    	</div>
											</div>
									        <div className="flex">
												<div className="flex flex-column overflow-auto" style={{ width: '100%', height: '70vh' }}>
													<div className={redeemExistingCustomerDivFlg}>
														<div className="w-10 p-4 mt-3 border-round-md border-1 border-gray-100 shadow-1 flex flex-column gap-1">
															<div className="flex flex-column gap-1">
																<div className="text-sm font-semibold text-900">Add Bonus Cashback</div>
																<div className="text-xs font-light text-900"></div>
															</div>
															<div className="p-3 mt-1 border-round-md border-1 border-gray-100 flex flex-column gap-1">
																<div className="flex flex-column gap-1">
																	<div className="text-sm font-light text-700">Cashback</div>
																	<div className="mt-2">
																		<InputText className="w-8" keyfilter="int" id="bonusPoints" name="bonusPoints" placeholder="eg. 10"
																			value={bonusPoints} onChange={(e) => setBonusPoints(e.target.value)} />
																		<AddBonusPointsBtn />
																	</div>
																</div>
															</div>
														</div>
														<div className="w-10 p-4 mt-3 border-round-md border-1 border-gray-100 shadow-1 flex flex-column gap-1">
															<div className="flex flex-column gap-1">
																<div className="text-sm font-semibold text-900">
																{(('' === _customer.name || null === _customer.name) ? _customer.phoneNumber : _customer.name) + ' has ' + _customer.pointBalance + ' cashback in total'}
																</div>
																<div className="text-xs font-light text-900"></div>
															</div>
															<div className="p-3 mt-1 border-round-md border-1 border-gray-100 flex flex-column gap-1">
																<div className="flex flex-column gap-1">
																	<div className="text-sm font-light text-700">Redeem</div>
																	<div className="mt-2">
																		<InputText className="w-8" keyfilter="int" id="redeemPoints" name="redeemPoints" placeholder="eg. 10"
																			value={redeemPoints} onChange={(e) => setRedeemPoints(e.target.value)} />
																		<RedeemPartialPointsBtn />
																	</div>
																	<RedeemAllPointsBtn points={_customer.pointBalance} />
																</div>
															</div>
														</div>
													</div>
											   </div>
											</div>
									    </div>
									</div>
							    </div>
							</div>
						</div>
						<Dialog header="Error" headerClassName="text-red-500" visible={dialogAPIErrorFlag} style={{ width: '50vw' }} onHide={() => setDialogAPIErrorFlag(false)}>
					        <p className="m-0 text-lg font-medium mb-2 text-gray-800">
					            <p className="font-light">{dialogAPIError}</p>
					        </p>
					    </Dialog>
					    <Dialog header="Info" visible={dialogAPIInfoFlag} style={{ width: '50vw' }} onHide={() => setDialogAPIInfoFlag(false)}>
					        <p className="m-0 text-lg font-medium mb-2 text-gray-800">
					            <p className="font-light">{dialogAPIInfoMessage}</p>
					        </p>
					    </Dialog>
					</div>
        		</div>
        		<Footer />
		    </div>
		</div>
		<ProgressLoader loaderVisible={loaderVisible} />
		</>
    )
}

const fetchingCustomerResult = [
		{
			"name": "",
			"phoneNumber": "Fetching..."
		}
		  
	];

const customerEmptyObj = {
						    "name": '',
						    "countryCode": '',
						    "phoneNumber": '',
						    "dob": null,
						    "anniversaryDate": null,
						    "email": '',
						    "membershipId": '',
						    "membershipStartDate": null,
						    "membershipEndDate": null,
						    "outletId": null,
						    "uuid": null,
						    "segment": null,
						    "pointBalance": null,
						    "totalRedeemption": null,
						    "dateOfJoining": null,
						    "totalPointsEarn": null,
						    "pointMoneyRatio": null,
						    "timeZone": null,
						    "brandId": null,
						    "address": null,
						    "city": null,
						    "state": null,
						    "country": null,
						    "region": null,
						    "tags": null
						  };

export default Customer;