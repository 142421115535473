import React from 'react';
import ReactDOM from 'react-dom/client';
import { PrimeReactProvider } from 'primereact/api';
import App from './App';
import './index.css';
import 'primereact/resources/themes/saga-orange/theme.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';

import '@fortawesome/fontawesome-free/css/all.min.css';

const value = {
        zIndex: {
            modal: 1100,    // dialog, sidebar
            overlay: 1000,  // dropdown, overlaypanel
            menu: 1000,     // overlay menus
            tooltip: 1100,   // tooltip
            toast: 1200     // toast
        },
        autoZIndex: true
        
    };

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <PrimeReactProvider value={value}>
        <App />
    </PrimeReactProvider>
);
