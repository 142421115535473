import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { ProgressLoader } from './NavigationBar';
import { Divider } from 'primereact/divider';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import { Calendar } from 'primereact/calendar';
import { classNames } from 'primereact/utils';
import { Card } from 'primereact/card';
import dateFormat from 'dateformat';
import Footer from './Footer';

import { CustomFieldRenderer } from "./QRScanWebFormCustomInput";

import {ConstantValues, ServerErrorModal, CheckEmail, CountryPhoneCodes} from '../Utility';


const QRScanWebForm = () => {

	const [loaderVisible, setLoaderVisible] = useState(false);

	const [dialogAPIInfoFlag, setDialogAPIInfoFlag] = useState(false);
    const [dialogAPIInfoMessage, setDialogAPIInfoMessage] = useState(null);

    const [dialogAPIErrorFlag, setDialogAPIErrorFlag] = useState(false);
	const [dialogAPIError, setDialogAPIError] = useState(null);

	const [disclaimerChecked, setDisclaimerChecked] = useState(false);
	const [disclaimerCheckedErr, setDisclaimerCheckedErr] = useState('');

	const [aeQRCode, setAeQRCode] = useState(null);

	const [formQuestions, setFormQuestions] = useState([]);
	const [showFormFlag, setShowFormFlag] = useState('');
	const [showInfoCard, setShowInfoCard] = useState('hidden');
	const [infoCardMsg, setInfoCardMsg] = useState('');

	const handleChange = (e, index) => {
	    const values = [...formQuestions];
	    values[index].value = e.target.value;
	    setFormQuestions(values);
	  };

	const handleError = (index, err) => {
	    const values = [...formQuestions];
	    values[index].fldError = err;
	    setFormQuestions(values);
	  };

  let maxDate = new Date();

	const navigate = useNavigate();

	const pathName = useLocation().pathname;
	const locPath = useLocation();
	const qrcodeUUID = pathName.replace('/webform/', '');
	useEffect(() => {
		
		getQRCodes();
	}, []);

	const getQRCodes = () => {
		const sd = async() => {
			setLoaderVisible(true);
			try {
				var currBrand = JSON.parse(localStorage.getItem("currentBrand"));
				var currBrandId = currBrand.brandId;
		      	// send a request to the server to get dashboard details
		      	const response = await fetch('https://api.saltwin.com/loyalty-web-service/rest/v1/qrcode/webform/' + qrcodeUUID,
		      	{
			        method: 'GET',
			        headers: {
			          'Content-Type': 'application/json',
			          'Authorization': 'Bearer ' + localStorage.getItem('token')
			        }
		      	});
		      	const resJson = await response.json();
		      	QRCodesList(resJson);
		    } catch (err) {
		    	<ServerErrorModal />
		    }
		    setLoaderVisible(false);
		}
		sd(); 
  	}

  	const QRCodesList = (resJson) => {
  		if(resJson.success === true) {
  			const newState = resJson.questionaires.map((q) => {
  				const qtyp = q.type;
  				if('dob' === qtyp || 'marriage_anniversary' === qtyp) {
  					return {...q, 'value': '', 'fldError': '', 'fldType': '' };
  				} else if('phone' === qtyp) {
  					return {...q, 'value': '', 'fldError': '', 'fldType': 'int'};
  				} else if('email' === qtyp) {
  					return {...q, 'value': '', 'fldError': '', 'fldType': 'email'};
  				} else {
  					return {...q, 'value': '', 'fldError': '', 'fldType': ''};
  				}
  			});
  			setFormQuestions(newState);
  			setAeQRCode(resJson);
  			setShowFormFlag('');
  			setShowInfoCard('hidden');
  			setInfoCardMsg('');
    	} else {
    		//showPopupOnAPIError(resJson);
    		setShowFormFlag('hidden');
    		setShowInfoCard('');
    		setInfoCardMsg(resJson.error.message);
    	}
  	}

  	const showPopupOnAPIError = (resJson) => {
  		if('Unauthorized' === resJson.error) {
  			setDialogAPIErrorFlag(true);
  			setDialogAPIError(resJson.message);
  			setTimeout(() => {
			  navigate('/');
			}, 3000);
  		} else if(undefined !== resJson.error.message) {
  			setDialogAPIErrorFlag(true);
  			setDialogAPIError(resJson.error.message);
  		} else if(null !== resJson.error) {
  			setDialogAPIErrorFlag(true);
  			setDialogAPIError(resJson.error);
  		}
  	}

  const submitWebForm = (e) => {
		e.preventDefault();
		var errCnt = 0;
		var newState = formQuestions.map((q) => {
			if(q.mandatory === true && q.value === '') {
				errCnt += 1;
				const msg = q.label + ' is required field.'
				return {...q, 'fldError': msg};
			} else {
				return {...q, 'fldError': '', 'answer': q.value };
			}
		});
		setFormQuestions(newState);
		if(false === disclaimerChecked) {
			errCnt += 1;
			setDisclaimerCheckedErr('Please check the disclaimer box.');
		} else {
			setDisclaimerCheckedErr('');
		}
		if(errCnt === 0) {
			var bodyQrCode = {
						"qrcodeUuid": qrcodeUUID,
		        "tenatId": aeQRCode.tenatId,
		        "brandId": aeQRCode.brandId,
		        "outletId": aeQRCode.outletId,
		        "qrCodeType": aeQRCode.type,
						"type": aeQRCode.type,
						"name": aeQRCode.name,
						"answers": formQuestions.map(q => {
												const qtyp = q.type;
												var ans = q.value;
  											if('dob' === qtyp || 'marriage_anniversary' === qtyp) {
  													ans = dateFormat(ans, "dd-mm-yyyy");
  											}
												return {
															'id': q.id,
															'type': q.type,
															'label': q.label,
															'isMandatory': q.mandatory,
															'loyaltyPoints': q.loyaltyPoints,
															'phoneNumberCountryCode': '+91',
															'answer': ans
												};
						})
					}

					SendFormDetails(bodyQrCode);
			
		}
		
	}

	const SendFormDetails = async(bodyQrCode) => {
		setLoaderVisible(true);
      try {
          const response = await fetch('https://api.saltwin.com/loyalty-web-service/rest/v1/qrcode/webform/regiter-user', {
              method: 'POST',
              headers: {
                 'Content-Type': 'application/json'
              },
              body: JSON.stringify(bodyQrCode)
            });
          const resJson = await response.json();
        if(resJson.success === true) {
        	var infoMsg = '';
        	if('register_user' === aeQRCode.type) {
        		infoMsg = 'User registered successfully';
        	} else  {
        		infoMsg = 'No response';
        	}
        	setDialogAPIInfoMessage(infoMsg);
  				setDialogAPIInfoFlag(true);
  				const newState = formQuestions.map((q) => {
	  				return {...q, 'value': '', 'fldError': ''};
	  			});
	  			setFormQuestions(newState);
	  			setDisclaimerChecked(false);
	  			setDisclaimerCheckedErr('');
      	} else {
      		showPopupOnAPIError(resJson);
      	}
      } catch (err) {
          <ServerErrorModal />
      }
      setLoaderVisible(false);
	}

	const header = (
        <img alt="Card" src="https://primefaces.org/cdn/primereact/images/usercard.png" />
    );

  return (<>
    <div className="flex align-items-center justify-content-center">
		    <form className={classNames('surface-card p-4 shadow-2 border-round w-full lg:w-6', showFormFlag)}>
		        <div className="text-center mb-5">
		            <img src="/demo/images/blocks/logos/hyper.svg" alt="hyper" height={50} className="mb-3" />
		            <div className="text-900 text-3xl font-medium mb-3">Hey, Welcome</div>
		            <span className="text-600 font-medium line-height-3">Join us to get loyalty bonus along with exiting rewards and offers</span>
		        </div>
		        	{formQuestions.map((obj, index) => (
			          <CustomFieldRenderer
			            key={index}
			            objValue={obj}
			            onChange={handleChange}
			            index={index}
			          />
			        ))}
		        <div>
		            
		        </div>
		        <div className="mb-4">
		            <div className="flex align-items-center justify-content-between">
		                <div className="flex align-items-center">
		                    <Checkbox id="disclaimerCheck" onChange={e => setDisclaimerChecked(e.checked)} checked={disclaimerChecked} className="mr-2" />
		                    <label htmlFor="disclaimerCheck">Allow to send promotion and offer notifications.</label>
		                </div>
		            </div>
		            <span className="text-xs text-red-500">{disclaimerCheckedErr}</span>
		        </div>

	            <Button label="Submit" icon="pi pi-user" className="w-full" onClick={(e) => submitWebForm(e)} />
		    </form>
		    <Card title="QRCode Expired" subTitle="" header={header} className={classNames('md:w-25rem', showInfoCard)}>
		    		<p></p>
		    		<p></p>
            <p className="m-0">{infoCardMsg}</p>
            <p></p>
        </Card>
		</div>
		<Dialog header="Error" headerClassName="text-red-500" visible={dialogAPIErrorFlag} style={{ width: '50vw' }} onHide={() => setDialogAPIErrorFlag(false)}>
        <div className="m-0 text-lg font-medium mb-2 text-gray-800">
            <div className="font-light">{dialogAPIError}</div>
        </div>
    </Dialog>
    <Dialog header="Info" visible={dialogAPIInfoFlag} style={{ width: '50vw' }} onHide={() => setDialogAPIInfoFlag(false)}>
        <div className="m-0 text-lg font-medium mb-2 text-gray-800">
            <div className="font-light">{dialogAPIInfoMessage}</div>
        </div>
    </Dialog>
    <ProgressLoader loaderVisible={loaderVisible} />
		</>
  );

}

export default QRScanWebForm;