import React, { useState, useEffect, useRef }  from 'react';
import {Link, useNavigate} from 'react-router-dom';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Badge } from 'primereact/badge'
import { Avatar } from 'primereact/avatar';
import { Divider } from 'primereact/divider';
import { Menu } from 'primereact/menu';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Dialog } from 'primereact/dialog';
import { classNames } from 'primereact/utils';

import { ConstantValues} from '../Utility';


const NavigationBarHorizontal = ({hideLinkOf}) => {

	const navigate = useNavigate();

	const menuRight = useRef(null);
    const items = [
        {
        	label: localStorage.getItem('username'),
            items: [
                {
                    label: 'Outlets',
                    icon: 'pi pi-sitemap',
                    command: () => {
                    	navigate('/outlets-conf'); 
                    },
                    className: 'hidden'
                },
                {
                    label: 'Logout',
                    icon: 'pi pi-sign-out',
                    command: () => {
                    	handleLogout();
                    }
                }
            ]
        }
    ];

	useEffect(() => {
		if(localStorage.getItem('token') === null) navigate('/'); 
	}, []);

	const handleLogout = () => {
	    // clear the token from local storage and set the user as logged out
		localStorage.removeItem('token');
		localStorage.removeItem("currentBrand");
		localStorage.removeItem("brands");
		if(localStorage.getItem('token') === null) navigate('/'); 
	}

	const ManageCustomerSegmentsLink = ({hideLinkOf}) => {
		if(hideLinkOf === 'ManageCustomerSegments')
			return <></>;
		else
			return <Link to="/managesegments"><Button outlined className="text-yellow-500 text-lg mx-2 hidden" icon="pi pi-file-edit" label="Manage customer segment" /></Link>;
	}

	const ManageCustomerPointsLink = ({hideLinkOf}) => {
		if(hideLinkOf === 'ManageCustomerPoints')
			return <></>;
		else
			return <Button outlined className="text-yellow-500 text-lg mx-2 hidden" icon="pi pi-pencil" label="Manage customer points" />;
	}

	const AddACustomerLink = ({hideLinkOf}) => {
		if(hideLinkOf === 'AddACustomer')
			return <></>;
		else
			return <Button outlined className="text-yellow-500 text-lg mx-2 hidden" icon="pi pi-plus-circle" label="Add a customer" />;
	}

	return (
		<div className="flex">
		    <div className="flex-2 flex align-items-center justify-content-center">
		    	<AddACustomerLink hideLinkOf={hideLinkOf}  /> 
        		<ManageCustomerPointsLink hideLinkOf={hideLinkOf}  />
        		<ManageCustomerSegmentsLink hideLinkOf={hideLinkOf} />
		    </div>
		    <div className="flex-1 flex flex-row-reverse align-items-center">
		    	<Menu model={items} popup ref={menuRight} id="popup_menu_right" className="w-full md:w-16rem" />
		    	<Avatar className="mx-2 cursor-pointer" image={process.env.PUBLIC_URL + '/fav.png'}  shape="circle" onClick={(event) => menuRight.current.toggle(event)} aria-controls="popup_menu_right" aria-haspopup />
		    	<i icon="pi" className="hidden pi pi-phone p-overlay-badge text-yellow-800 mx-2 cursor-pointer" style={{ fontSize: '1rem' }}></i>
		    	<i icon="pi" className="hidden pi pi-bell p-overlay-badge text-yellow-800 mx-2 cursor-pointer" style={{ fontSize: '1rem' }}></i>
				<i icon="pi" className="hidden pi pi-envelope p-overlay-badge text-yellow-800 mx-2 cursor-pointer" style={{ fontSize: '1rem' }}>
				    <Badge severity="danger"></Badge>
				</i>
				<Divider layout="vertical"></Divider>
				<span className="p-input-icon-right text-lg hidden">
				    <i className="pi pi-search text-lg" />
				    <InputText placeholder="" className="text-lg" id="site-search" />
				</span>
		    </div>
		</div>
    )
}

const NavigationBarVertical = () => {

	const navBarItemsClassName = 'salt-nav-link flex align-items-center justify-content-start w-12 font-light border-round text-white no-underline p-3 m-2';

	const currPage = localStorage.getItem('currentPage');

	return (<div className="absolute top-0 left-0 font-bold h-screen border-200 border-right-1 align-items-center justify-content-center" 
				style={{ backgroundColor: 'black', width: ConstantValues.vNavBarWidth }}>
    			<div className="border-bottom-1 border-white-alpha-90 p-4">
	    			<Link to="/dashboard">
	    				<img src={process.env.PUBLIC_URL + '/saltwin-text.png' } alt="home-logo" className="my-2 cursor-pointer" />
	    			</Link>
			    </div>
			    <div className="p-2 mt-2 flex flex-row flex-wrap">
			        <Link to ="/dashboard" className={classNames('pi pi-objects-column ', navBarItemsClassName, { 'salt-nav-link-active' : currPage === 'dashboard' })}>
				        &nbsp;Dashboard
				    </Link>
				    <Link to ="/customer" className={classNames('pi pi-users ',navBarItemsClassName, { 'salt-nav-link-active' : currPage === 'customer' })}>
				        &nbsp;Customers
				    </Link>
				    <Link to ="/managesegments" className={classNames('pi pi-tags', navBarItemsClassName, { 'salt-nav-link-active' : currPage === 'managesegments' })}>
				        &nbsp;Segments
				    </Link>
				    <Link to ="/managecashbackrules" className={classNames('pi pi-money-bill', navBarItemsClassName, { 'salt-nav-link-active' : currPage === 'managecashbackrules' })}>
				        &nbsp;Cashback Rules
				    </Link>
				    <Link to ="/qrcode" className={classNames('pi pi-qrcode', navBarItemsClassName, { 'salt-nav-link-active' : currPage === 'qrcode' })}>
				        &nbsp;QR Codes
				    </Link>
			    </div>
	        </div>);
}

const Footer = () => {
	return (<div className="absolute bottom-0 px-4 pb-2 font-light hidden" style={{ width: ConstantValues.hNavBarWidth, marginLeft: ConstantValues.vNavBarWidth }}>
    			<div className="flex">
				    <div className="flex-1">
				    	<div className="flex flex-row flex-wrap">
						    <small className="text-lg text-gray-500 text-right mr-3">&copy; 2024 Saltwin Tech Lab LLC</small>
							<Link className="text-lg no-underline text-gray-500 text-right cursor-pointer mr-3">Privacy</Link>
							<Link className="text-lg no-underline text-gray-500 text-right cursor-pointer mr-3">Terms</Link>
							<Link className="text-lg no-underline text-gray-500 text-right cursor-pointer mr-3">Sitemap</Link>
						</div>
				    </div>
				    <div className="flex-1">
				    	<div className="flex flex-row-reverse flex-wrap">
						    <div className="flex text-lg text-gray-500">Last activity: 1 minute ago</div>
						</div>
				    </div>
				</div>
    		</div>);
}

const ProgressLoader = ({loaderVisible}) => {
	const isDisplay = true === loaderVisible ? 'block' : 'hidden';
	return (
		<div style={{ width: '100vw', height: '100vh', maxHeight: '100%', position: 'absolute', left: '0', top: '0', zIndex: '5' }}
				className={isDisplay} >
			<ProgressSpinner className="absolute left-50 top-50" />
		</div>
		);
}

const InfoErrorPopup = ({popupVisible, setPopupVisible, details}) => {
	const isError = details.isError;
	var header = details.header;
	var message = details.message;
	if((null === isError || undefined === isError)
		&& (null === header || undefined === header)) {
		header = 'Info';
	} else if((true === isError)
		&& (null === header || undefined === header)) {
		header = 'Error';
	}

	const msgs = message.map((msg) => {
		return <p>{msg}</p>
	});

	return (
		<Dialog header={header} visible={popupVisible} style={{ width: '50vw' }} onHide={() => setPopupVisible(false)}>
            <div className="m-0 text-lg font-medium mb-2 text-gray-800">
                <div className="font-light">{msgs}</div>
            </div>
        </Dialog>
		);
}

const infoErrorPopupDetails = {
		isError: null,
		header: null,
		message: []
	}

export {NavigationBarVertical, NavigationBarHorizontal, Footer, ProgressLoader, InfoErrorPopup, infoErrorPopupDetails};

