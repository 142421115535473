import React, { useState, useEffect } from "react";
import { Divider } from 'primereact/divider';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import {Link} from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Footer from './Footer';
import { Dialog } from 'primereact/dialog';
import { RequestTypes, SendHttpRequest } from './HttpRequestHandler';
import { useEventListener } from 'primereact/hooks';

import {CheckEmail, ConstantValues, ServerErrorModal} from '../Utility';

function Login() {

    const [resetPasswordDialog, setResetPasswordDialog] = useState(false);
    const [resetPasswordEmailGrid, setResetPasswordEmailGrid] = useState('hidden');
    const [resetPasswordEmail, setResetPasswordEmail] = useState('');
    const [resetPasswordLinkSent, setResetPasswordLinkSent] = useState('');
    const [resetPasswordEmailError, setResetPasswordEmailError] = useState('');

    const [bindKeyDown, unbindKeyDown] = useEventListener({
        type: 'keydown',
        listener: (e) => {
            onKeyDown(e);
        }
    });

    const onKeyDown = (e) => {
        if (e.code === 'Enter' 
                && (e.target.name === 'username' || e.target.name === 'password')) {
            handleSubmit(e);
        }
    };

    useEffect(() => {
        bindKeyDown();

        return () => {
            unbindKeyDown();
        };
    }, [bindKeyDown, unbindKeyDown]);

    const [fieldValues, setFieldValues] = useState({
        'username': '',
        'password': '',
        'errors': {}
    });

    const updateFieldValue = (e) => {
        const t = e.target;
        const n = t.name;
        const v = t.value;

        const newState = {...fieldValues, [n]: v};
        setFieldValues(newState);
    }

    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();
        let errors = {};
        const username = fieldValues.username;
        const password = fieldValues.password;
        if(username.length === 0 ) {
            errors.username = 'Username is required field';
        } else if(!CheckEmail(username)) {
                errors.username = 'Please provide valid email';
        }

        if(password.length === 0) {
            errors.password = 'Password is required field';
        } else if(password.length < ConstantValues.passwordLength) {
            errors.password = 'Password must be of ' + ConstantValues.passwordLength + ' characters length';
        } 
        const newState = {...fieldValues, errors: errors};
        setFieldValues(newState);

       if(Object.keys(errors).length > 0) {
            return;
       }
        // send a request to the server to validate the credentials
        SendHttpRequest(RequestTypes.POST, 'rest/v1/user/signin', { username, password }).then(res => {
            if(res != null) {
                if(true === res.success) {
                    const token = res.accessToken;
                    const username = res.username;
                    const email = res.email;
                    localStorage.setItem('token', token);
                    localStorage.setItem('username', username);
                    localStorage.setItem('email', email);

                    const noBrand = {
                                        "brandId": -313131,
                                        "name": "",
                                        "isActive": false,
                                        "cashbackPrograms": null,
                                        "pointsPrograms": null,
                                        "promotions": null
                                    };

                    
                    localStorage.setItem('brands', JSON.stringify(res.brands));
                    localStorage.setItem('currentBrand', res.brands.length === 0 ? JSON.stringify(noBrand) : JSON.stringify(res.brands[0]));
                    navigate('/dashboard'); 

                    /*if(brandsJson.length > 1) {
                        navigate('/choosebrand'); 
                    } else {
                        localStorage.setItem('currentBrand', JSON.stringify(brandsJson[0]));
                        navigate('/dashboard'); 
                    }*/
                    setFieldValues({
                        'username': '',
                        'password': '',
                        'errors': {}
                    });
                } else {
                    let errMsg = '';
                    if('Unauthorized' === res.error) {
                        errMsg = res.message;
                    }  else if(undefined !== res.error.message) {
                        errMsg = res.error.message;
                    } else if(null !== res.error) {
                        errMsg = res.error;
                    }

                    let errors = {};
                    errors.loginError = errMsg;
                    const newState = {...fieldValues, errors: errors};
                    setFieldValues(newState);
                }
            } else {
                <ServerErrorModal />
            }
        });
    }

    const isFormFieldInvalid = (name) => { return fieldValues.errors[name]; }

    const getFormErrorMessage = (name) => {
        return isFormFieldInvalid(name) ? <small className="p-error">{fieldValues.errors[name]}</small> : <small className="p-error">&nbsp;</small>;
    };

    const resetPassword = () => {
        setResetPasswordEmail('');
        setResetPasswordEmailError('');
        setResetPasswordLinkSent('');
        setResetPasswordEmailGrid('block');
        //setResetPasswordDialog(true);
    }

    const sendResetPasswordLink = async(e) => {
        try {
            const email = resetPasswordEmail;
            const userName = resetPasswordEmail;
            const response = await fetch('https://api.saltwin.com/loyalty-web-service/rest/v1/user/forget-password', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({ email, userName })
              });
            const resJson = await response.json();
            return resJson;
        } catch (err) {
            const errorMessage = "Error:" + err.message;
            console.error(errorMessage);
        }
      }

    const handleSubmitResetPasswordLink = (e) => {
        e.preventDefault();
        let error = '';
        if(resetPasswordEmail.length === 0 ) {
            error = 'Username/Email is required field';
        } else if(!CheckEmail(resetPasswordEmail)) {
            error = 'Please provide valid Username/Email';
        }

        if('' === error) {
            sendResetPasswordLink().then(res => {
                if(res != null) {
                    if(res.success === true) {
                        setResetPasswordEmailGrid('none');
                        setResetPasswordLinkSent('Reset password link sent successfully. Please check inbox of your registered email - ' + resetPasswordEmail);
                    } else if(res.success === false) {
                        setResetPasswordEmailError(res.error.message);
                    } else {
                        setResetPasswordEmailError(res.error.message);
                    }
                } else {
                    <ServerErrorModal />
                }
            });
        } else {
            setResetPasswordEmailError(error);
        }
        
    }

    return (
        
            <div className="card">
            <div className="flex flex-column md:flex-row h-screen">
                <div className="w-full md:w-6 flex flex-column align-items-center justify-content-center py-1">
                    <img src={process.env.PUBLIC_URL + '/SaltwinLogo.jpeg'} alt="logo" />
                </div>
                <div className="w-full md:w-1">
                    <Divider layout="vertical" className="hidden md:flex">
                        
                    </Divider>
                    <Divider layout="horizontal" className="flex md:hidden" align="center">
                        
                    </Divider>
                </div>
                <div className="w-full md:w-6 flex align-items-center justify-content-center py-1 relative">
                    <div className="surface-card p-4 w-full lg:w-8">
                        <div className="text-center">
                            <div className="text-900 text-2xl font-semibold capitalize">Sign In</div>
                        </div>

                        <div>
                            {getFormErrorMessage('loginError')}
                            <label htmlFor="username" className="block font-medium mb-2 text-gray-800">Username</label>
                            <InputText id="username" type="text" placeholder="Business Email" className="w-full border-round-sm"
                                name="username"
                                value={fieldValues.username}
                                onChange={(e) => updateFieldValue(e)} />
                            {getFormErrorMessage('username')}
                            <label htmlFor="password" className="block font-medium mb-2 text-gray-800">Password</label>
                            <InputText type="password" placeholder="Password" className="w-full border-round-sm"
                                name="password"
                                value={fieldValues.password}
                                onChange={(e) => updateFieldValue(e)} />
                            {getFormErrorMessage('password')}
                            <Button label="Sign In" icon="" className="w-full bg-yellow-500 text-yellow-900 border-round-sm mt-2 pi pi-user"
                                onClick={(e) => handleSubmit(e)} />

                            <div className="text-center mt-1 py-2">
                                <span className="no-underline text-bluegray-600 font-light text-right cursor-pointer mr-3" onClick={(e) => resetPassword()}>Forgot password?</span>
                                <Link to="/signup" className="no-underline text-yellow-800 text-right cursor-pointer">Sign Up</Link>
                            </div>
                        </div>
                    </div>
                    <Footer />
                    <Dialog header="Reset Password" visible={resetPasswordDialog} 
                        onHide={() => setResetPasswordDialog(false)}  style={{ width: '50%' }}>
                        <div class="grid" style={{ display: resetPasswordEmailGrid }}>
                            <div class="col-12">
                                <p className="">Enter your registered business email/username to send reset password link.</p>
                            </div>
                            <div class="col-12">
                                <InputText id="resetPasswordEmail" type="text" placeholder="Registered Business Email" className="w-5 border-round-sm"
                                    name="resetPasswordEmail"
                                    value={resetPasswordEmail}
                                    onChange={(e) => setResetPasswordEmail(e.target.value)} />
                                <Button label="Send Password Link" icon="" className="bg-yellow-500 text-yellow-900 border-round-sm ml-2 mt-2"
                                onClick={(e) => handleSubmitResetPasswordLink(e)} />
                            </div>
                            <div class="col-12 h-1rem">
                                <small className="p-error">{resetPasswordEmailError}</small>
                            </div>
                        </div>
                        <small className="text-yellow-900 text-lg">{resetPasswordLinkSent}</small>
                    </Dialog>
                </div>
            </div>
        </div>
    
    );
}

const brandsJson = [
                                            {
                                                id: 0,
                                                isActive: true,
                                                name: "Brand-1",
                                                logo: "logo_1.jpeg",
                                                bgImage: "bg_img.jpeg",
                                                website:""
                                            },
                                            {
                                                id: 1,
                                                isActive: true,
                                                name: "Brand-2",
                                                logo: "logo_2.jpeg",
                                                bgImage: "bg_img.jpeg",
                                                website:""
                                            },
                                            {
                                                id: 2,
                                                isActive: false,
                                                name: "Brand-3",
                                                logo: "logo_3.jpeg",
                                                bgImage: "bg_img.jpeg",
                                                website:""
                                            },
                                            {
                                                id: 3,
                                                isActive: false,
                                                name: "Brand-4",
                                                logo: "",
                                                bgImage: "",
                                                website:""
                                            }

                                        ];


export default Login;

        