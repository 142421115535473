import React, { useState, useEffect }  from 'react';
import {NavigationBarVertical, NavigationBarHorizontal, Footer, ProgressLoader} from './NavigationBar';
import { useNavigate } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import { DataView } from 'primereact/dataview';
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';
import { Avatar } from 'primereact/avatar';
import { SelectButton } from 'primereact/selectbutton';
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from "primereact/checkbox";
import { RadioButton } from "primereact/radiobutton";
import { Dropdown } from 'primereact/dropdown';
import { Image } from 'primereact/image';
import { classNames } from 'primereact/utils';
import { ToggleButton } from 'primereact/togglebutton';

import { ServerErrorModal, ConstantValues} from '../Utility';
import { RequestTypes, SendHttpRequest } from './HttpRequestHandler';

import { FaPlus, FaPencil, FaTrashCan, FaCheck, FaXmark, FaEye } from 'react-icons/fa6';


const QRCode = () => {

	const [loaderVisible, setLoaderVisible] = useState(false);

	const [qrCodesData, setQrCodesData] = useState([]);
    const [qrCodesOnLoadData, setQrCodesOnLoadData] = useState([]);

    const [selectQrCodesButtonOptions, setSelectQrCodesButtonOptions] = useState(['All','Active','Inactive']);
	const [selectQrCodesButtonValue, setSelectQrCodesButtonValue] = useState('All');
	const [currentSelectedQrCode, setCurrentSelectedQrCode] = useState(null);

	const [viewQRCode, setViewQRCode] = useState(false);
	const [qRCodeDetails, setQRCodeDetails] = useState(null);
	const [allQuestionsList, setAllQuestionsList] = useState(null);

	const [dialogVisible, setDialogVisible] = useState(false);

	const [dialogAPIInfoFlag, setDialogAPIInfoFlag] = useState(false);
    const [dialogAPIInfoMessage, setDialogAPIInfoMessage] = useState(null);

    const [dialogQRCode, setDialogQRCode] = useState(newQRCode);

    const [dialogAPIErrorFlag, setDialogAPIErrorFlag] = useState(false);
	const [dialogAPIError, setDialogAPIError] = useState(null);

	const navigate = useNavigate();

	useEffect(() => {
		if(localStorage.getItem('token') === null) navigate('/home'); 
		localStorage.setItem('currentPage', 'qrcode');
		getQRCodes();
	}, []);

	const getQRCodes = () => {
		const sd = async() => {
			setLoaderVisible(true);
			try {
				var currBrand = JSON.parse(localStorage.getItem("currentBrand"));
				var currBrandId = currBrand.brandId;
		      	// send a request to the server to get dashboard details
		      	const response = await fetch('https://api.saltwin.com/loyalty-web-service/rest/v1/qrcode/',
		      	{
			        method: 'GET',
			        headers: {
			          'Content-Type': 'application/json',
			          'Authorization': 'Bearer ' + localStorage.getItem('token')
			        }
		      	});
		      	const resJson = await response.json();
		      	QRCodesList(resJson);
		    } catch (err) {
		    	<ServerErrorModal />
		    }
		    setLoaderVisible(false);
		}
		sd(); 
  	}

  	const QRCodesList = (resJson) => {
  		if(resJson.success === true) {
  			var qrcodes = resJson.qrCodes;
  			var actCodes = qrcodes.filter((q) => {return (q.active === true) });
  			var sortedCodes = actCodes.sort((q1, q2) => {return (q2.id - q1.id); });
  			var sortedActCodes = sortedCodes;

  			var inActCodes = qrcodes.filter((q) => {return (q.active === false) });

  			inActCodes.forEach((is) => {
  				sortedCodes = [...sortedCodes, is];
  			});

      		setQrCodesOnLoadData(sortedCodes);
      		if(currentSelectedQrCode === null) {
      			setQrCodesData(sortedCodes);
  			} else if(currentSelectedQrCode === 'Inactive') {
      			setQrCodesData(inActCodes);
  			} else if(currentSelectedQrCode === 'Active') {
      			setQrCodesData(sortedActCodes);
  			}
      	} else {
      		showPopupOnAPIError(resJson);
      	}
  	}

  	const showPopupOnAPIError = (resJson) => {
  		if('Unauthorized' === resJson.error) {
  			setDialogAPIErrorFlag(true);
  			setDialogAPIError(resJson.message);
  			setTimeout(() => {
			  navigate('/');
			}, 3000);
  		} else if(undefined !== resJson.error.message) {
  			setDialogAPIErrorFlag(true);
  			setDialogAPIError(resJson.error.message);
  		} else if(null !== resJson.error) {
  			setDialogAPIErrorFlag(true);
  			setDialogAPIError(resJson.error);
  		}
  	}

  	const checkBrandsAvailable = () => {
		var currBrand = JSON.parse(localStorage.getItem("currentBrand"));
		var currBrandId = currBrand.brandId;
		if(currBrandId === -313131) {
            setDialogAPIInfoMessage('Please configure your brand(s) or contact system administrator');
            setDialogAPIInfoFlag(true);
            return false;
        }
        return true;
	}

	const ShowError = ({err}) => {
		if(null === err) {
			return <></>;
		} else {
			return <span className="text-xs text-red-500">{err}</span>;
		}
	}

	const AppendTransparentLayer = ({viewCodeFlg}) => {
		if(true === viewCodeFlg) {
			return <div className="absolute top-0 left-0 opacity-10 z-5 surface-50" style={{ width: '100%', height: '100%'}}></div>;
		} else {
			return <></>;
		}
	}

	const AddUpdateDeleteOps = async(method, qrcode) => {
		setLoaderVisible(true);
        try {
            const response = await fetch('https://api.saltwin.com/loyalty-web-service/rest/v1/qrcode/', {
                method: method,
                headers: {
                   'Content-Type': 'application/json',
			       'Authorization': 'Bearer ' + localStorage.getItem('token')
                },
                body: JSON.stringify(qrcode)
              });
            const resJson = await response.json();
            getQRCodes();
	        if(resJson.success === true) {
	        	var infoMsg = '';
	        	if('POST' === method) {
	        		infoMsg = 'New QRCode added successfully';
	        	} else if('PUT' === method) {
	        		infoMsg = 'QRCode updated successfully';
	        	} else if('DELETE' === method) {
	        		infoMsg = 'QRCode deleted successfully';
	        	}
	        	setDialogAPIInfoMessage(infoMsg);
	  			setDialogAPIInfoFlag(true);
	      	} else {
	      		showPopupOnAPIError(resJson);
	      	}
        } catch (err) {
            <ServerErrorModal />
        }
        setLoaderVisible(false);
	}

	const ResolveQRCodeQuestionareTemplate = (qrId, type, name, qrcodeQuestions, allQuestList) => {

		var newStateQuesList = null; 
		if(null === qrId) {
			newStateQuesList = allQuestList;
		} else {
			if(null === allQuestList) {
				newStateQuesList = qrcodeQuestions.map((q) => {
					return {...q, addToForm: true};
				});
			} else {
				var tempQRQuesState = qrcodeQuestions.map((q) => {
					return {...q, addToForm: true};
				});

				var newQuestionListToEdit = [];
				allQuestList.map((nwQues) => {
					var isExists = false;
					var existQues = null;
					tempQRQuesState.map((qrQues) => {
						if(nwQues.id === qrQues.id && nwQues.type === qrQues.type) {
							isExists = true;
							existQues = qrQues;
							return;
						}
					});
					if(true === isExists) {
						newQuestionListToEdit.push(existQues);
					} else {
						newQuestionListToEdit.push(nwQues);
					}
				});

				newStateQuesList = newQuestionListToEdit;
			}
		}

		const qrcodequestions = {
						"id" : qrId,
						"qrcode_type": {
							"options" : [
								{code: "register_user", "name" : "Register User"}
							],
							"tooltip" : "Choose QR code type",
							"txt_length" : "50",
							"mandatory": true,
							"value" : type
						},
						"qrcode_name": {
							"tooltip" : "Provide QR code name",
							"txt_length" : "50",
							"mandatory": true,
							"value" : name
						},
						"form_view": [],
						"questionare": newStateQuesList
					}

		return qrcodequestions;
	}

	const FetchNewQuestionaireFormDetails = (type) => {
		const sd = async() => {
			setLoaderVisible(true);
			try {
				var currBrand = JSON.parse(localStorage.getItem("currentBrand"));
				var currBrandId = currBrand.brandId;
		      	// send a request to the server to get dashboard details
		      	const response = await fetch('https://api.saltwin.com/loyalty-web-service/rest/v1/qrcode/questionaire',
		      	{
			        method: 'GET',
			        headers: {
			          'Content-Type': 'application/json',
			          'Authorization': 'Bearer ' + localStorage.getItem('token')
			        }
		      	});
		      	const resJson = await response.json();
		      	if(resJson.success === true) {
		  			var questionList = resJson.qrCodeQuestionaires;
		  			const qrcodequestions = ResolveQRCodeQuestionareTemplate(null, 'register_user', '', null, questionList); 

					setDialogQRCode(qrcodequestions);
					setDialogVisible(true);
					setViewQRCode(false); 
					setQRCodeDetails(null);
		      	} else {
		      		showPopupOnAPIError(resJson);
		      	}
		    } catch (err) {
		    	<ServerErrorModal />
		    }
		    setLoaderVisible(false);
		}
		sd(); 
	}

	const ResolveEditQRCodeQuestionareTemplate = (qrcode, allQuestList) => {

		const qrId = qrcode.id
		const type = qrcode.type;
		const name = qrcode.name;
		const qrcodeQuestions = qrcode.qrCodeQuestionaires;

		const qrCodeUuid = qrcode.qrCodeUuid;
		const tenantId = qrcode.tenantId;
        const brandId = qrcode.brandId;
        const isActive = qrcode.active;

		var newStateQuesList = null; 
		if(null === qrId) {
			newStateQuesList = allQuestList;
		} else {
			if(null === allQuestList) {
				newStateQuesList = qrcodeQuestions.map((q) => {
					return {...q, addToForm: true};
				});
			} else {
				var tempQRQuesState = qrcodeQuestions.map((q) => {
					return {...q, addToForm: true};
				});

				var newQuestionListToEdit = [];
				allQuestList.map((nwQues) => {
					var isExists = false;
					var existQues = null;
					tempQRQuesState.map((qrQues) => {
						if(nwQues.id === qrQues.id && nwQues.type === qrQues.type) {
							isExists = true;
							existQues = qrQues;
							return;
						}
					});
					if(true === isExists) {
						newQuestionListToEdit.push(existQues);
					} else {
						newQuestionListToEdit.push(nwQues);
					}
				});

				newStateQuesList = newQuestionListToEdit;
			}
		}

		const qrcodequestions = {
						"id" : qrId,
						"qrcodeImageUUid": qrCodeUuid,
				        "tenantId": tenantId,
				        "brandId": brandId,
				        "type": type,
				        "active": isActive,
						"qrcode_type": {
							"options" : [
								{code: "register_user", "name" : "Register User"}
							],
							"tooltip" : "Choose QR code type",
							"txt_length" : "50",
							"mandatory": true,
							"value" : type
						},
						"qrcode_name": {
							"tooltip" : "Provide QR code name",
							"txt_length" : "50",
							"mandatory": true,
							"value" : name
						},
						"form_view": [],
						"questionare": newStateQuesList
					}

		return qrcodequestions;
	}

	const ViewQRCodeForm = (qrcode) => {
		if(qrcode.active === false ) {
			const qrcodequestions = ResolveQRCodeQuestionareTemplate(qrcode.id, qrcode.type, qrcode.name, qrcode.qrCodeQuestionaires, null); 
		                            	 		setDialogQRCode(qrcodequestions);
		                            	 		setDialogVisible(true); 
		                            	 		setViewQRCode(true);
		                            	 		setQRCodeDetails(qrcode); 
		} else {
			window.open(qrcode.data, "_blank");
		}
	}

	const FetchQuestionListNEditQRCode = (qrcode) => {

		const sd = async() => {
			setLoaderVisible(true);
			try {
				var currBrand = JSON.parse(localStorage.getItem("currentBrand"));
				var currBrandId = currBrand.brandId;
		      	// send a request to the server to get dashboard details
		      	const response = await fetch('https://api.saltwin.com/loyalty-web-service/rest/v1/qrcode/questionaire',
		      	{
			        method: 'GET',
			        headers: {
			          'Content-Type': 'application/json',
			          'Authorization': 'Bearer ' + localStorage.getItem('token')
			        }
		      	});
		      	const resJson = await response.json();
		      	if(resJson.success === true) {
		  			var allQuestionList = resJson.qrCodeQuestionaires;
		  			const qrcodequestions = ResolveEditQRCodeQuestionareTemplate(qrcode, allQuestionList); 
					setDialogQRCode(qrcodequestions); 
					setDialogVisible(true);
					setViewQRCode(false);
					setQRCodeDetails(qrcodequestions);
		      	} else {
		      		showPopupOnAPIError(resJson);
		      	}
		    } catch (err) {
		    	<ServerErrorModal />
		    }
		    setLoaderVisible(false);
		}
		sd(); 
	}

	const AddEditQRCode = () => {

		const qrcode = dialogQRCode;

		const [aeQRCode, setAeQRCode] = useState(qrcode);
		const [aeQRCodeType, setAeQRCodeType] = useState(null);
		const [formErrors, setFormErrors] = useState({});

		const [quesBorderColor, setQuesBorderColor] = useState("border-gray-300");
		const [dialogQuestionaireErrFlag, setDialogQuestionaireErrFlag] = useState(false);
		const [dialogQuestionaireErrHeader, setDialogQuestionaireErrHeader] = useState(null);
		const [dialogQuestionaireErrMsgs, setDialogQuestionaireErrMsgs] = useState([]);

		const updateQrCodeTypeValue = (e) => {
			const v = e.target.value;

			const nxtQRCdTyp = {...aeQRCode.qrcode_type, value: v.code};
			const nxtQRCd = {...aeQRCode, qrcode_type: nxtQRCdTyp};
			setAeQRCode(nxtQRCd);

			aeQRCode.qrcode_type.options.map(qrt => {

				if(qrt.code === v.code) {
					setAeQRCodeType(qrt);
				}
			});
		}

		const updateQrCodeNameValue = (e) => {
			const v = e.target.value;

			const nxtQRCdNm = {...aeQRCode.qrcode_name, value: v};
			const nxtQRCd = {...aeQRCode, qrcode_name: nxtQRCdNm};
			setAeQRCode(nxtQRCd);
		}

		const updateQrCodeStatusValue = (e) => {
			const v = e.target.value;

			const nxtQRCd = {...aeQRCode, 'active': v};
			setAeQRCode(nxtQRCd);
		}

		const updateQRCodeQuestionaire = (e, qid) => {
			const t = e.target;
			const n = t.name;
			const v = t.value;
			const ft = t.type;

			var questions = aeQRCode.questionare;
			var newQuestionsState = questions.map(q => {
				if(q.id === qid) {
					if('checkbox' === ft) {
						if(!q.mandatory === true) {
							var nq = {...q, addToForm: true};
							return {...nq, [n]: !v};
						} else {
							return {...q, [n]: !v};
						}
					} else {
						var nq = {...q, addToForm: true};
						return {...nq, [n]: v};
					}
				} else {
					return q;
				}
			});
			const nxtQRCd = {...aeQRCode, questionare: newQuestionsState};
			setAeQRCode(nxtQRCd);
		}

		const submitNewQRCode = () => {
			const qrTyp = aeQRCode.qrcode_type.value;
			const qrNm = aeQRCode.qrcode_name.value;

			var chkdQuesCnt = 0;
			const questions = aeQRCode.questionare;
			questions.map(q => {
				if(q.addToForm) {
					chkdQuesCnt += 1;
				}
			});

			var formErrorsObj = {};
			var hasErr = false;
			var errMsgs = [];
			if(qrTyp === '' || qrTyp === null) {
				formErrorsObj.qrcode_type = "Choose QR Type";
				errMsgs.concat("Choose QR Type");
				hasErr = true;
			}
			if(qrNm === '' || qrNm === null) {
				formErrorsObj.qrcode_name = "Provide QR Type Name";
				errMsgs.push("Provide QR Type Name");
				hasErr = true;
			}
			if(chkdQuesCnt === 0) {
				formErrorsObj.questionaire = "Choose field(s) to show in web form. Minimum 1 field is required.";
				errMsgs.push("Choose field(s) to show in web form. Minimum 1 field is required.");
				setQuesBorderColor("border-red-300");
				hasErr = true;
			} else {
				setQuesBorderColor("border-gray-300");
			}
			setFormErrors(formErrorsObj);

			if(!hasErr) {
				var currBrand = JSON.parse(localStorage.getItem("currentBrand"));
				var currBrandId = currBrand.brandId;

				const filteredQuestions = aeQRCode.questionare.filter(q => q.addToForm === true);
				const finalQuestions = filteredQuestions.map(q => {
					return {...q, cashBack: null};
				});

				var bodyQrCode = {};
				var httpMethod = 'PUT';
				if(null === aeQRCode.id || '' === aeQRCode.id) {
					httpMethod = 'POST';
					bodyQrCode = {
						"tenantId" : 0,
						"brandId": currBrandId,
						"id": aeQRCode.id,
						"type": aeQRCode.qrcode_type.value,
						"name": aeQRCode.qrcode_name.value,
						"questionaires": finalQuestions
					}
				} else {
					httpMethod = 'PUT';
					bodyQrCode = {
						"qrcodeImageUUid": aeQRCode.qrcodeImageUUid,
				        "tenantId": aeQRCode.tenantId,
				        "brandId": aeQRCode.brandId,
				        "type": aeQRCode.type,
				        "active": aeQRCode.active,
						"id": aeQRCode.id,
						"type": aeQRCode.qrcode_type.value,
						"name": aeQRCode.qrcode_name.value,
						"questionaires": finalQuestions
					}
				}
				AddUpdateDeleteOps(httpMethod, bodyQrCode);
				setDialogVisible(false);
				setDialogQuestionaireErrFlag(false);
				setDialogQuestionaireErrHeader(null);
				setDialogQuestionaireErrMsgs([]);
			} else {
				setDialogQuestionaireErrFlag(true);
				setDialogQuestionaireErrHeader("Please provide below details:");
				setDialogQuestionaireErrMsgs(errMsgs);
			}

		}

		const formViewTemplate = (formView, index) => {
			return (
				<div className="col-6 px-2 py-1">
			        <div className="flex flex-column gap-2">
		                <label htmlFor="qrcode_name">{(formView.mandatory === true ? '* ' : '') + formView.name}</label>
		                <InputText id="qrcode_name" name="qrcode_name" 
		                	tooltip={formView.tooltip}
		                	value={aeQRCode.qrcode_name.value} 
		                	onChange={(e) => updateQrCodeNameValue(e)} />
		                <ShowError err={formErrors.qrcode_name} />
		            </div>
			    </div>
			);
		}

		const questionaireTemplate = (questions, index) => {
	        return (
	            <div className={classNames('col-12  border-1 border-gray-300 border-round-sm m-1 relative', quesBorderColor)} key={questions.id}>
	            	<AppendTransparentLayer viewCodeFlg={viewQRCode} />
	            	<div className={classNames('flex flex-column xl:flex-row xl:align-items-start p-4 gap-4')}>
	            		<div className="flex flex-column sm:flex-row justify-content-between align-items-center xl:align-items-start flex-1 gap-4">
	            			<div className="flex flex-column align-items-center sm:align-items-start gap-3">
                            	<div className="text-xl font-bold text-900">{questions.label}</div>
                            	<div className="text-xs font-light text-900">{questions.description}</div>
                            </div>
                            <div className="flex align-items-center sm:align-items-end">
                            	<div class="flex flex-column flex-wrap sm:row-gap-3 row-gap-4">
									<div class="flex align-items-start justify-content-start sm:column-gap-3 column-gap-4">
		                            	<div className="flex align-items-center">
		                            		<Checkbox id="addToFormLbl" name="addToForm"
			                            	value={questions.addToForm} onChange={(e) => updateQRCodeQuestionaire(e, questions.id)} 
			                            	checked={viewQRCode === true ? true : questions.addToForm } />
									        <label htmlFor="addToFormLbl" className="ml-2">Add To Form</label>
									    </div>
									    <div className="flex align-items-center">
		                            		<Checkbox id="isMandatoryLbl" name="mandatory"
			                            	value={questions.mandatory} onChange={(e) => updateQRCodeQuestionaire(e, questions.id)} 
			                            	checked={questions.mandatory} />
									        <label htmlFor="isMandatoryLbl" className="ml-2">Is Mandatory</label>
									    </div>
									</div>
									<div class="flex align-items-start justify-content-start sm:column-gap-3 column-gap-4">
										<div className="flex align-items-center">
		                            		<label htmlFor="loyalityPointLbl" className="mr-2">Loyalty Points</label>
					                		<InputText id="loyalityPointLbl" name="loyaltyPoints"
					                			keyfilter="int" value={questions.loyaltyPoints} onChange={(e) => updateQRCodeQuestionaire(e, questions.id)} />
									    </div>
									</div>
								</div>
	                        </div>
	            		</div>
	            	</div>
	            </div>
	            );
    	}

    	const formViewListTemplate = (items) => {
	        if (!items || items.length === 0) return null;

	        let list = items.map((formView, index) => {
	            return formViewTemplate(formView, index);
	        });

	        return <div className="grid grid-nogutter">{list}</div>;
	    };

		const questionaireListTemplate = (items) => {
	        if (!items || items.length === 0) return null;

	        let list = items.map((questions, index) => {
	            return questionaireTemplate(questions, index);
	        });

	        return <div className="grid grid-nogutter">{list}</div>;
	    };

	    const footerContent = (
		    <div className="flex flex-wrap justify-content-end gap-2 pt-3">
		        <Button icon={FaXmark} rounded severity="danger" aria-label="Cancel"
		        	onClick={() => setDialogVisible(false)} >&nbsp;Cancel</Button>
		        <Button icon={FaCheck} rounded severity="success" aria-label="Submit"
		        	onClick={() => submitNewQRCode()} >&nbsp;Submit</Button>
	        </div>
		);

	    var addEditDialogHeader = '';
	    var isQRCodeActiveStatusFlag = true;
	    var isQRCodeActiveStatusHiddenClass = 'hidden';
		if(aeQRCode.id === null || aeQRCode.id === '') {
			addEditDialogHeader = 'Add New QR Code';
			isQRCodeActiveStatusFlag = true;
			isQRCodeActiveStatusHiddenClass = 'hidden';
		} else {
			addEditDialogHeader = 'Edit QR Code';
			isQRCodeActiveStatusFlag = aeQRCode.active;
			isQRCodeActiveStatusHiddenClass = '';
		}

		return (
				<Dialog header={ viewQRCode === true ? 'View QR Code' : addEditDialogHeader } visible={dialogVisible} 
					style={{ width: '60vw' }} 
					footer={ viewQRCode === true ? '' : footerContent }
					onHide={() => {if (!dialogVisible) return; setDialogVisible(false); setViewQRCode(false); setQRCodeDetails(null); }}
					onShow={() => {
						aeQRCode.qrcode_type.options.map(qrt => {

							if(qrt.code === aeQRCode.qrcode_type.value) {
								setAeQRCodeType(qrt);
							}
						});
					}}>
			    <div className="grid py-2 px-3 relative">
			    	<AppendTransparentLayer viewCodeFlg={viewQRCode} />
			    	<div className="col-6 hidden">
				        <div className="flex flex-column gap-2">
			                <label htmlFor="qrcode_type">* QR Code Type</label>
			                <Dropdown id="qrcode_type" name="qrcode_type" value={aeQRCodeType} 
			                	onChange={(e) => updateQrCodeTypeValue(e)} options={aeQRCode.qrcode_type.options} optionLabel="name" 
    							placeholder="Select QR Code Type" className="" />
    						<ShowError err={formErrors.qrcode_type} />
			            </div>
				    </div>
				    <div className="col-6">
				        <div className="flex flex-column gap-2">
			                <label htmlFor="qrcode_name">* QR Code Name</label>
			                <InputText id="qrcode_name" name="qrcode_name" value={aeQRCode.qrcode_name.value} 
			                	onChange={(e) => updateQrCodeNameValue(e)}
			                	tooltip={aeQRCode.qrcode_name.tooltip} />
			                <ShowError err={formErrors.qrcode_name} />
			            </div>
				    </div>
				    <div className={classNames('col-6 ', isQRCodeActiveStatusHiddenClass)}>
				        <div className="flex flex-column gap-2">
			                <label htmlFor="qrcode_name">* QR Code Status</label>
			                <ToggleButton id="active" name="active" 
			                	onLabel="Active" onIcon="pi pi-check" 
			                	offLabel="Inactive" offIcon="pi pi-times"
			                	checked={isQRCodeActiveStatusFlag} 
			                	onChange={(e) => updateQrCodeStatusValue(e)} />
			                <ShowError err={formErrors.active} />
			            </div>
				    </div>
				    <div className="col-12 py-2 px-3 hidden">
				    	<div className="text-xl font-bold text-800 mt-1 mb-2">User Form View</div>
				    	<ShowError err={formErrors.formview} />
				    	<div className="card">
				            <DataView value={aeQRCode.form_view} listTemplate={aeQRCode.id, formViewListTemplate} />
				        </div>
				    </div>
				    <div className="col-12 py-2 px-3">
				    	<div className="text-xl font-bold text-800 mt-1 mb-2">User Form Questionaire</div>
				    	<ShowError err={formErrors.questionaire} />
				    	<div className="card">
				            <DataView value={aeQRCode.questionare} listTemplate={questionaireListTemplate} />
				        </div>
				    </div>
			    </div>
			    <Dialog header="Error" visible={dialogQuestionaireErrFlag} style={{ width: '50vw' }} 
			    	onHide={() => { setDialogQuestionaireErrFlag(false); }}>
		            <div className="m-0 text-lg font-medium mb-2 text-gray-800">
		            	<h4>{dialogQuestionaireErrHeader}</h4>
		                <div className="font-light">
		                	{dialogQuestionaireErrMsgs.map(er => (
		                		<p>{er}</p>
		                	))}
		                </div>
		            </div>
		        </Dialog>
			</Dialog>
			);
	}

	const onChangeSelectQRCodeButtonValue = (btnVal) => {
		setSelectQrCodesButtonValue(btnVal);
		setCurrentSelectedQrCode(btnVal);
		if(null === btnVal || "All" === btnVal) {
			setQrCodesData(qrCodesOnLoadData);
		} else if(btnVal.includes("Active")) {
			const newState = qrCodesOnLoadData.filter((s) => (s.active === true));
			setQrCodesData(newState);
		} else if(btnVal.includes("Inactive")) {
			const newState = qrCodesOnLoadData.filter((s) => (s.active === false));
			setQrCodesData(newState);
		}	
	}

	const qrcodesListTemplate = (items) => {
		if (!items || items.length === 0) return null;

	    let list = items.map((qrcode, index) => {
	        return qrcodeItemTemplate(qrcode, index);
	    });

	    return <div className="grid grid-nogutter">{list}</div>;
	}

	const classRowColumn = "text-left p-2";
    const classRowColumnLbl = "font-light text-gray-600 mb-1";
    const classRowColumnVal = "";

	const qrcodeItemTemplate = (qrcode, index) => {

		const imgData = 'data:image/jpeg;base64,' + qrcode.qrCode;
		const inactiveQRCode = qrcode.active ? '' : 'hidden';

		return (
	    		<div className="col-12" key="qrcode_id">
	                <div className="col-12 p-2 my-2 border-round-md border-1 border-yellow-300"  >
	                	<div className="grid mb-2 border-bottom-1 border-300">
						    <div className="col-9">
						        <div className="p-0 m-0 text-xl mb-2 font-medium text-900 capitalize"></div>
						    </div>
						    <div className="col-3">
						        <div className="flex justify-content-end">
						        	<Button icon={FaEye} rounded text raised severity="secondary" aria-label="View QRCode"
		                            	 className="bg-white cursor-pointer mx-1" 
		                            	 onClick={(e) => ViewQRCodeForm(qrcode)} />
		                            <Button icon={FaPencil} rounded text raised severity="secondary" aria-label="Edit QRCode"
		                            	 className={classNames('bg-white cursor-pointer mx-1 ', inactiveQRCode)} 
		                            	 onClick={(e) => FetchQuestionListNEditQRCode(qrcode)} />
			                    	<Button icon={FaTrashCan} rounded text raised severity="secondary" aria-label="Delete QRCode"
			                    		 className={classNames('bg-white cursor-pointer mx-1 ', inactiveQRCode)} 
			                    		 onClick={(e) => deleteQRCodePopup(e, qrcode)} />
						        </div>
						    </div>
						</div>
						<div className="grid">
							<div className="col-3 p-1">
						        <Image src={imgData} alt="Image" width="250" preview />
						    </div>
						    <div className="col-6">
						        <div className={classRowColumn}>
						        	<div className={classRowColumnLbl}>Id</div>
						        	<div className={classRowColumnVal}>{qrcode.qrCodeUuid}</div>
						        </div>
						        <div className={classRowColumn}>
						        	<div className={classRowColumnLbl}>Name</div>
						        	<div className={classRowColumnVal}>{qrcode.name}</div>
						        </div>
						        <div className={classRowColumn}>
						        	<div className={classRowColumnLbl}>Status</div>
						        	<div className={classRowColumnVal}>{qrcode.active ? 'Active' : 'Inactive'}</div>
						        </div>
						    </div>
						    <div className="col-2">
						    </div>
						</div>
	                </div>

	                <ConfirmPopup />
	            </div>
	        );
	}

	const deleteQRCodePopup = (event, qrcode) => {

		const delQRCode = {'qrcodeUuid': qrcode.qrCodeUuid};

		const accept = () => {
			AddUpdateDeleteOps('DELETE',delQRCode);
		}

		const reject = () => {
		}

        confirmPopup({
            target: event.currentTarget,
            message: 'Do you want to deactive this QR Code?',
            icon: 'pi pi-info-circle',
            defaultFocus: 'reject',
            acceptClassName: 'p-button-danger',
            accept,
            reject
        });
    }

  	const QRCodes = () => {


  		return (<div className="grid px-3">
				<AddEditQRCode />
			    <div className="col-12">
			    	<div className="flex">
					    <div className="flex-1 flex justify-content-start align-items-center flex-wrap">
				    		<div className="text-2xl font-medium">QR Codes</div>
					    	<Avatar icon="pi pi-refresh" className="cursor-pointer"
					    		style={{ backgroundColor: '#fff', color: '#000'}} shape="circle"
					    		onClick ={(e) => getQRCodes()} />
				    	</div>
				    	<div className="flex-1 flex justify-content-end flex-wrap">
				    		<Button icon={FaPlus} rounded text raised severity="secondary" aria-label="Add QR Code"
				    			onClick={(e) => {
				    								if(checkBrandsAvailable()) {
				    									FetchNewQuestionaireFormDetails();
				    								}
				    							}
				    					}>&nbsp;Add New</Button>
				    	</div>
					</div>
					<div className="flex">
					    <div className="flex-1 flex justify-content-start align-items-center flex-wrap">
				    		<SelectButton value={selectQrCodesButtonValue} onChange={(e) => onChangeSelectQRCodeButtonValue(e.value)} options={selectQrCodesButtonOptions} />
				    	</div>
					</div>
			        <div className="flex">
						<div className="flex flex-column overflow-auto" style={{ width: ConstantValues.contentViewWidth, height: ConstantValues.contentViewHeight }}>
							<DataView value={qrCodesData} listTemplate={qrcodesListTemplate} />
					   </div>
					</div>
			    </div>
			</div>);
  	};

	return (<>
			<div className="relative p-0 m-0">
				<div className="relative text-gray-800 w-screen h-screen">
					<NavigationBarVertical />
					<div className="absolute top-0 left-0 h-4rem px-2 border-200 border-bottom-1" style={{ width: ConstantValues.hNavBarWidth, height: '95%', marginLeft: ConstantValues.vNavBarWidth }}>
						<NavigationBarHorizontal hideLinkOf='' />
						<div id="show-saltwin-content" className="absolute top-4rem left-0 px-2 py-4" style={{ width: '100%', height: '95%' }}>
							<div id="cqcodes" className="py-2 relative">
								<div className="grid overflow-hidden" style={{height: '86vh' }}>
								    <div className="col-12">
								        <QRCodes />
								    </div>
								</div>
							</div>
						</div>
					</div>
					<Footer />
				</div>
			</div>
			<Dialog header="Error" headerClassName="text-red-500" visible={dialogAPIErrorFlag} style={{ width: '50vw' }} onHide={() => setDialogAPIErrorFlag(false)}>
		        <div className="m-0 text-lg font-medium mb-2 text-gray-800">
		            <div className="font-light">{dialogAPIError}</div>
		        </div>
		    </Dialog>
		    <Dialog header="Info" visible={dialogAPIInfoFlag} style={{ width: '50vw' }} onHide={() => setDialogAPIInfoFlag(false)}>
	            <div className="m-0 text-lg font-medium mb-2 text-gray-800">
	                <div className="font-light">{dialogAPIInfoMessage}</div>
	            </div>
	        </Dialog>
	        <ProgressLoader loaderVisible={loaderVisible} />
		</>)
}

const newQRCode = {

	"id" : null,
	"qrcode_type": {
		"options" : [
			{code: "user_onboard", "name" : "User Onboard"}
		],
		"tooltip" : "Choose QR code type",
		"txt_length" : "50",
		"mandatory": true,
		"value" : "user_onboard"
	},
	"qrcode_name": {
		"tooltip" : "Provide QR code name",
		"txt_length" : "50",
		"mandatory": true,
		"value" : ""
	},
	"form_view": [
		{
			"name" : "Title",
			"tooltip" : "User web form title",
			"txt_length" : "100",
			"mandatory": true,
			"value" : ""
		},
		{
			"name" : "Sub Title",
			"tooltip" : "User web form sub-title",
			"txt_length" : "100",
			"mandatory": false,
			"value" : ""
		},
		{
			"name" : "Brand Logo",
			"tooltip" : "Provide logo for QR code",
			"img_path" : "",
			"mandatory": false,
			"value" : ""
		},
		{
			"name" : "Background Image",
			"tooltip" : "Provide backgroup image of user web form",
			"img_path" : "",
			"mandatory": false,
			"value" : ""
		},
		{
			"name" : "Footer",
			"tooltip" : "User web form footer text",
			"txt_length" : "100",
			"mandatory": false,
			"value" : ""
		},
		{
			"name" : "Sticker 1",
			"tooltip" : "Provide sticker image 1 of user web form",
			"img_path" : "",
			"mandatory": false,
			"value" : ""
		},
		{
			"name" : "Sticker 2",
			"tooltip" : "Provide sticker image 2 of user web form",
			"img_path" : "",
			"mandatory": false,
			"value" : ""
		}
	],
	"questionare": [

		{
			"id": 1,
			"type": "name",
			"label": "Name",
			"description": "This field is required to save customer name.",
			"addToForm": false,
			"isMandatory": false,
			"loyalityPoint": "",
			"sequence": 1
		},
		{
			"id": 2,
			"type": "email",
			"label": "Email",
			"description": "This field is required to save customer email.",
			"addToForm": false,
			"isMandatory": false,
			"loyalityPoint": "",
			"sequence": 2
		},
		{
			"id": 3,
			"type": "phone",
			"label": "Phone Number",
			"description": "This field is required to save customer phone number.",
			"addToForm": false,
			"isMandatory": false,
			"loyalityPoint": "",
			"sequence": 3
		},
		{
			"id": 4,
			"type": "dob",
			"label": "Date of Birth",
			"description": "This field is required to save customer date of birth.",
			"addToForm": false,
			"isMandatory": false,
			"loyalityPoint": "",
			"sequence": 4
		},
		{
			"id": 5,
			"type": "marriage_anniversary",
			"label": "Marriage Anniversary",
			"description": "This field is required to save customer marriage anniversary.",
			"addToForm": false,
			"isMandatory": false,
			"loyalityPoint": "",
			"sequence": 5
		}
	]
}

export default QRCode;