import React, { useState }  from 'react';
import {NavigationBarVertical, NavigationBarHorizontal, Footer} from './NavigationBar';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Dialog } from 'primereact/dialog';
import {Link} from 'react-router-dom';
import { Avatar } from 'primereact/avatar';
import { Button } from 'primereact/button';

import {ServerErrorModal, ConstantValues} from '../Utility';

import { FaPencil, FaTrashCan, FaPlus, FaUsers, FaCheck, FaXmark } from 'react-icons/fa6';


const Configurations = () => {

	const [loaderVisible, setLoaderVisible] = useState(false);

	const [dialogAPIErrorFlag, setDialogAPIErrorFlag] = useState(false);
	const [dialogAPIError, setDialogAPIError] = useState(null);

	const [dialogAPIInfoFlag, setDialogAPIInfoFlag] = useState(false);
	const [dialogAPIInfoMessage, setDialogAPIInfoMessage] = useState(null);

	return (
		<>
		<div className="relative p-0 m-0">
		    <div className="relative text-gray-800 w-screen h-screen">
        		<NavigationBarVertical />
		        <div className="absolute top-0 left-0 h-4rem px-2 border-200 border-bottom-1" style={{ width: ConstantValues.hNavBarWidth, height: '95%', marginLeft: ConstantValues.vNavBarWidth }}>
		        	<NavigationBarHorizontal hideLinkOf='ManageCustomerSegments' />
					<div id="show-saltwin-content" className="absolute top-4rem left-0 px-2 py-4" style={{ width: '100%', height: '95%' }}>
						<div id="cust-segments" className="py-2 relative">
							<div className="grid overflow-hidden" style={{height: '86vh' }}>
							    <div className="col-12 p-3">
							       	<div class="grid">
									    <div class="col-5">
									        <div class="shadow-1">
									        	<div className="flex h-3rem p-3">
												    <div className="flex-1 flex justify-content-start align-items-center flex-wrap">
											    		<div className="text-2xl font-medium">Brands</div>
												    	<Avatar icon="pi pi-refresh" className="cursor-pointer"
												    		style={{ backgroundColor: '#fff', color: '#000'}} shape="circle" />
											    	</div>
											    	<div className="flex-1 flex justify-content-end flex-wrap">
											    		<Button icon={FaPlus} rounded text raised severity="secondary" aria-label="Add Brand" >&nbsp;Add New</Button>
											    	</div>
												</div>
												<div className="border-bottom-1 border-gray-300 mt-4 mb-2"></div>
												<div className="p-3">a</div>
									        </div>
									    </div>
									    <div class="col-7">
									        <div class="shadow-1">
									        	<div className="flex h-3rem p-3">
												    <div className="flex flex-column justify-content-start align-items-start">
											    		<div className="text-2xl font-medium">Outlets</div>
											    		<div className="text-xl font-light">(Brand Name)</div>
											    	</div>
											    	<div className="flex-1 flex justify-content-end flex-wrap">
											    		<Button icon={FaPlus} rounded text raised severity="secondary" aria-label="Add Outlet" >&nbsp;Add New</Button>
											    	</div>
												</div>
												<div className="border-bottom-1 border-gray-300 mt-4 mb-2"></div>
												<div className="p-3">a</div>
									        </div>
									    </div>
									</div>
							    </div>
							</div>
						</div>
						<Dialog visible={loaderVisible} 
				       		style={{ width: '100vw', height: '100vh', maxHeight: '100%' }} 
				       		onHide={() => setLoaderVisible(false)}
				       		className="relative" headerClassName="hidden" contentClassName="bg-white-alpha-20">
							<ProgressSpinner className="absolute left-50 top-50" />
						</Dialog>
						<Dialog header="Error" headerClassName="text-red-500" visible={dialogAPIErrorFlag} style={{ width: '50vw' }} onHide={() => setDialogAPIErrorFlag(false)}>
					        <p className="m-0 text-lg font-medium mb-2 text-gray-800">
					            <p className="font-light">{dialogAPIError}</p>
					        </p>
					    </Dialog>
					    <Dialog header="Info" visible={dialogAPIInfoFlag} style={{ width: '50vw' }} onHide={() => setDialogAPIInfoFlag(false)}>
					        <p className="m-0 text-lg font-medium mb-2 text-gray-800">
					            <p className="font-light">{dialogAPIInfoMessage}</p>
					        </p>
					    </Dialog>
					</div>
        		</div>
        		<Footer />
		    </div>
		</div>
		</>
    )
}

export default Configurations;