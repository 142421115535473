import React, { useState, useEffect }  from 'react';
import {NavigationBarVertical, NavigationBarHorizontal, Footer} from './NavigationBar';
import { InputSwitch } from 'primereact/inputswitch';
import { Divider } from 'primereact/divider';
import { InputText } from 'primereact/inputtext';
import { SelectButton } from 'primereact/selectbutton';
import { RadioButton } from 'primereact/radiobutton';
import { Checkbox } from "primereact/checkbox";
import { useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button';
import {Link} from 'react-router-dom';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dialog } from 'primereact/dialog';
import dateFormat from 'dateformat';
import { classNames } from 'primereact/utils';
import { DataView, DataViewLayoutOptions } from 'primereact/dataview';
import { Avatar } from 'primereact/avatar';
import { Badge } from 'primereact/badge';


import { ServerErrorModal, ConstantValues} from '../Utility';


const ManageCustomerSegment = () => {
	return (
		<div className="relative p-0 m-0">
		    <div className="relative text-gray-800 w-screen h-screen">
        		<NavigationBarVertical />
		        <div className="absolute top-0 left-0 h-4rem px-2 border-200 border-bottom-1" style={{ width: ConstantValues.hNavBarWidth, height: '95%', marginLeft: ConstantValues.vNavBarWidth }}>
		        	<NavigationBarHorizontal hideLinkOf='ManageCustomerSegments' />
					<div id="show-saltwin-content" className="absolute top-4rem left-0 px-2 py-4" style={{ width: '100%', height: '95%' }}>
						<div id="cust-segments" className="py-2 relative">
							<div className="grid overflow-hidden" style={{height: '86vh' }}>
							    <div className="col-7">
							        <BrandNOutletsForm />
							    </div>
							    <div className="col-5">
							        <LoyalityConfigsForm segmentsNValidity={SegmentsNValidity} />
							    </div>
							</div>
						</div>
					</div>
        		</div>
        		<Footer />
		    </div>
		</div>
        
    )

}

const BrandNOutletsForm = ({brandNOutlets}) => {
	const outlets = [
        { 
        	name: 'Janak Puri - Delhi', 
        	code: 'JK-Del',
        	address: {

        	},
        	contactNumber: '',
        	contactPerson: ''
        },
        { 
        	name: 'South Extension - Delhi', 
        	code: 'S-Del',
        	address: {

        	},
        	contactNumber: '',
        	contactPerson: ''
         },
    ];
	const [selectedOutlet, setSelectedOutlet] = useState(outlets[0]);
	return (<>
		<div className="col-12 p-1">
			<div className="col-12 border-round-md border-1 border-yellow-300">
				<span className="text-2xl font-semibold text-900 vertical-align-middle">Ramesh Brand Name</span>
				<div className="grid pt-2">
				    <div className="col-6">
				        <span className="flex flex-column align-items-start gap-1">
                            <span className="text-sm font-medium text-500">Outlets</span>
                            <span className="text-sm font-light text-800 ml-2">2</span>
                        </span>
				    </div>
				</div>
			</div>
		</div>
		<div className="col-12 p-1">
			<div className="col-12 border-round-md border-1 border-yellow-300">
				<span className="text-2xl font-semibold text-900 vertical-align-middle">Outlets</span>
				<div className="grid pt-2">
				    <div className="col-6">
				        <span className="flex flex-column align-items-start gap-1">
                            <Dropdown value={selectedOutlet} onChange={(e) => setSelectedOutlet(e.value)} options={outlets} optionLabel="name" 
								placeholder="Select a Outlet" className="w-full" checkmark={true} highlightOnSelect={true} />
                        </span>
				    </div>
				</div>
				<div className="grid pt-2">
				    <div className="col-6">
				        <span className="flex flex-column align-items-start gap-1">
                            <span className="text-sm font-medium text-500">Name</span>
                            <span className="text-sm font-light text-800 ml-2">Ramesh Outlet - JNKP</span>
                        </span>
				    </div>
				    <div className="col-6">
				        <span className="flex flex-column align-items-start gap-1">
                            <span className="text-sm font-medium text-500">Contact Number</span>
                            <span className="text-sm font-light text-800 ml-2">(+91) 123 456 7890</span>
                        </span>
				    </div>
				</div>
				<div className="grid pt-2">
					<div className="col-6">
				        <span className="flex flex-column align-items-start gap-1">
                            <span className="text-sm font-medium text-500">Contact Person</span>
                            <span className="text-sm font-light text-800 ml-2">Ramesh</span>
                        </span>
				    </div>
				    <div className="col-6">
				        <span className="flex flex-column align-items-start gap-1">
                            <span className="text-sm font-medium text-500">Address</span>
                            <span className="text-sm font-light text-800 ml-2">Address Line 1</span>
                            <span className="text-sm font-light text-800 ml-2">Address Line 2</span>
                            <span className="text-sm font-light text-800 ml-2">City, Distict</span>
                            <span className="text-sm font-light text-800 ml-2">State, Country</span>
                            <span className="text-sm font-light text-800 ml-2">Zipcode</span>
                        </span>
				    </div>
				</div>
			</div>
		</div>
		<div className="col-12 p-1">
			<div className="col-12 border-round-md border-1 border-yellow-300">
				<span className="text-2xl font-semibold text-900 vertical-align-middle">Programs</span>
				<div className="grid pt-2">
				    <div className="col">
				        <div className="p-2 border-round-md border-1 border-yellow-200">
				        	<span className="flex justify-content-between">
				        		<span class="flex justify-content-start flex-wrap">
								    <span className="text-lg font-semibold text-900 vertical-align-middle">Cashback</span>
								</span>
	                            <span class="flex align-content-center justify-content-end flex-wrap">
								    <span className="text-xs font-normal text-blue-500 vertical-align-middle cursor-pointer">Show Details</span>
								</span>
	                        </span>
				        	<span className="flex flex-column align-items-start gap-1 mt-2">
	                            <Dropdown value={selectedOutlet} onChange={(e) => setSelectedOutlet(e.value)} options={outlets} optionLabel="name" 
								placeholder="Select a Outlet" className="" checkmark={true} highlightOnSelect={true} />
								<span className="text-xs text-500 p-2">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
				        		</span>
	                        </span>
				        </div>
				    </div>
				    <div className="col">
				        <div className="p-2 border-round-md border-1 border-yellow-200">
				        	<span className="flex justify-content-between">
				        		<span class="flex justify-content-start flex-wrap">
								    <span className="text-lg font-semibold text-900 vertical-align-middle">Promotion</span>
								</span>
	                            <span class="flex align-content-center justify-content-end flex-wrap">
								    <span className="text-xs font-normal text-blue-500 vertical-align-middle cursor-pointer">Show Details</span>
								</span>
	                        </span>
				        	<span className="flex flex-column align-items-start gap-1 mt-2">
	                            <Dropdown value={selectedOutlet} onChange={(e) => setSelectedOutlet(e.value)} options={outlets} optionLabel="name" 
								placeholder="Select a Outlet" className="" checkmark={true} highlightOnSelect={true} />
								<span className="text-xs text-500 p-2">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
				        		</span>
	                        </span>
				        </div>
				    </div>
				    <div className="col">
				       	<div className="p-2 border-round-md border-1 border-yellow-200">
				        	<span className="flex justify-content-between">
				        		<span class="flex justify-content-start flex-wrap">
								    <span className="text-lg font-semibold text-900 vertical-align-middle">Points</span>
								</span>
	                            <span class="flex align-content-center justify-content-end flex-wrap">
								    <span className="text-xs font-normal text-blue-500 vertical-align-middle cursor-pointer">Show Details</span>
								</span>
	                        </span>
				        	<span className="flex flex-column align-items-start gap-1 mt-2">
	                            <Dropdown value={selectedOutlet} onChange={(e) => setSelectedOutlet(e.value)} options={outlets} optionLabel="name" 
								placeholder="Select a Outlet" className="" checkmark={true} highlightOnSelect={true} />
								<span className="text-xs text-500 p-2">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
				        		</span>
	                        </span>
				        </div>
				    </div>
				</div>
			</div>
		</div>
	</>);
}

const LoyalityConfigsForm = ({segmentsNValidity}) => {
	const [segmentsData, setSegmentsData] = useState([]);
	const [validityData, setValidityData] = useState([]);
	const [publishedFlag, setPublishedFlag] = useState(false);
	const [dialogVisible, setDialogVisible] = useState(false);
	const [newCashbackProgram, setNewCashbackProgram] = useState(newCashbackProgramObj);

	useEffect(() => {
		getJson();
	}, []);

	const getJson = async () => {
	    try {
	      // send a request to the server to validate the credentials
	      const response = await fetch('http://localhost:9090/customer-segments?outlet=12', {
	        method: 'GET',
	        headers: {
	          'Content-Type': 'application/json',
	          'Authorization': 'Bearer ' + localStorage.getItem('token')
	        }
	      });
	      const resJson = await response.json();
	      SegmentsList(resJson);
	    } catch (err) {
	    	<ServerErrorModal />
	    }
	}

	const SegmentsList = (resJson) => {
		var segList = [];
		resJson.brands.map((b) => {
			b.cashbackPrograms.map((s) => {
				segList.push(s);
			});
		});

		const dstart = new Date(segList[0].startDate);
		const dend = null === segList[0].endDate ? '' : new Date(segList[0].endDate);

		var valdty =  {
			startDate: dstart, 
			endDate: dend, 
			newMemberBonusPointFlag: false, 
			newMemberBonusPoint: '', 
			nonApplicableItems: '', 
			termsNConditions: ''
		};

		setSegmentsData(segList);
	    setValidityData(valdty);
	    setPublishedFlag(segList[0].active);
	}

	const updateSegmentsData = (id, e) => {
		const t = e.target;
		const n = t.name;
		const v = t.value;
		
		const newState = segmentsData.map((seg) => {
			if(seg.id === id) {
				if(t.type === 'checkbox') {
					return {...seg, [n]: t.checked};
				}
				return {...seg, [n]: v};
			}
			return seg;
		});
		setSegmentsData(newState);
	}

	const updateSegmentsData2 = (bId, oId, sId, e) => {
		alert(bId + '  --  ' + oId + '  --  ' + sId);
	}

	const updateValidityData = (id, e)  => {
		const t = e.target;
		const n = t.name;
		const v = t.value;

		if(t.type === 'date') {
			const newState = {...validityData, [n]: v};
			setValidityData(newState);
		} else if(t.type === 'checkbox') {
			const newState = {...validityData, [n]: t.checked};
			setValidityData(newState);
			if(n === 'newMemberBonusPointFlag') {

			}
		} else {
			const newState = {...validityData, [n]: v};
			setValidityData(newState);
		}
	}

	const handleSubmit = (e) => {
		e.preventDefault();
		const configs = {
				'loyalityConfigs': {
				'segmentConfig': segmentsData,
				'validity': validityData
			}
		};

		fetch('http://localhost:9090/save-customer-segments?outlet=10009', {
    		method: 'POST',
    		body: JSON.stringify(configs),
    		headers: {
    			'Content-type': 'application/json; charset=UTF-8',
          		'token': localStorage.getItem('token')
    		},
    	})
    	.then((response) => response.text())
    	.then((data) => {
    		const msg = data;
    		setDialogVisible(true);
    		<Dialog header="Header" visible={dialogVisible} style={{ width: '50vw' }} onHide={() => setDialogVisible(false)}>
                <p className="m-0">{msg.message}</p>
            </Dialog>
    	})
    	.catch((err) => {
    		alert('Error Occured: ' + err.message);
    	});
	}

	return(<div className="flex-1 flex flex-column m-1 px-4 py-1 border-1 border-round-sm border-white-alpha-70 shadow-1">
			<div className="flex my-1" style={{ width: '100%' }}>
	    		<div className="flex-1 py-2">
			    	<div className="text-2xl font-medium">Cashback Programs</div>
			    </div>
			    <div className="flex-1">
				    <div className="flex flex-row-reverse flex-wrap">
					    <Button icon="pi pi-plus" className="p-button-rounded"
					    	onClick={(e) => addNewCashbackProgram(e)} />
					</div>
				</div>
	    	</div>
	    	<ConfigSegments segments={segmentsData} updateSegmentsData={updateSegmentsData} updateSegmentsData2={updateSegmentsData2} validityData={validityData} updateValidityData={updateValidityData} handleSubmit={handleSubmit} />
	    	<div className="flex p-1" key="config-submit">
		    	<div className="flex flex-column px-3 py-1" style={{ width: '100%' }}>
		    		<Button label="Save"
		    			className="w-full bg-yellow-500 text-sm border-round-sm mt-1"
		    			 onClick={(e) => handleSubmit(e)} />
		    	</div>
		    </div>
	    </div>
		)
}

const OnlyNumberKey = (e) => {
	const ASCIICode = e.which ? e.which : e.keyCode;
	if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57)){
		e.preventDefault();
        return false;
	}
    return true;
}

const ConfigSegments = ({segments, updateSegmentsData, updateSegmentsData2, validityData, updateValidityData, handleSubmit}) => {
	return <div className="flex">
				<div className="flex flex-column overflow-auto" style={{ width: '100%', height: '69vh' }}>
					<DataView value={segments} listTemplate={listTemplate} />
			   </div>
			</div>;
}

const IsProgramPublished = ({isActive}) => {
		if(isActive === true)
			return <Avatar icon="pi pi-check" style={{ backgroundColor: '#fff', color: '#22c55e', border: '1px solid' }} shape="circle" />;
		else
			return <Avatar icon="pi pi-times" style={{ backgroundColor: '#fff', color: '#ef4444', border: '1px solid' }} shape="circle" />;
	}

const itemTemplate = (segment, index) => {
	const bId = segment.brandId;
	const oId = segment.outletId;
	const sId = segment.id;
	const compId = bId + '-' + oId + '-' + sId;
	const cashbackTypeId = 'cashbackType-' + compId;
	const publishedId = 'published-' + compId;
	const minPurReqId = 'minimumPurchaseRequired-' + compId;
	const minimumPurchaseRequiredFlag = ( segment.minBillAmountForReward > 0 ? true : false );
	const minBillAmountForReward = ( segment.minBillAmountForReward > 0 ? segment.minBillAmountForReward : '-' );

	const GetValidity = ({segment}) => {
		const fdStart = dateFormat(segment.startDate, "d mmm, yyyy");

		var fdEnd = '-';
		if(null !== segment.endDate) {
			fdEnd = dateFormat(segment.endDate, "d mmm, yyyy");
		} 
		return ( <>
					{fdStart} <span className="mx-3">To</span> {fdEnd}
				</>
			);
	}

	const MinPurRequired = ({flag}) => {
		if(flag === true)
			return <Badge value="Yes" severity="success"></Badge>;
		else
			return <Badge value="No" severity="danger"></Badge>;
	}

	const CashbackType = ({discountType}) => {
		if(discountType === 'FIXED')
			return <>Fixed ($)</>;
		else if(discountType === 'PERCENTAGE')
			return <>Percentage (%)</>;
	}

    return (
            <div className="col-12" key={compId}>
            	<div className="col-12 p-2">
            		<div className="col-12 border-round-md border-1 border-yellow-300">
            			<div className="grid border-bottom-1 border-yellow-200">
						    <div className="col-8 flex flex-wrap align-items-center justify-content-start">
						        <span className="text-lg font-semibold text-900 vertical-align-middle">{segment.name}</span>
						    </div>
						    <div className="col-4">
						        <div className="flex sm:flex justify-content-end align-items-center sm:align-items-end gap-3 sm:gap-2">
		                        	<IsProgramPublished isActive={segment.active} />
		                            <Button icon="pi pi-pencil" className="p-button-rounded"
		                            	onClick={(e) => editCashbackProgram(e, bId, oId, sId)} ></Button>
		                        </div>
						    </div>
						</div>
						<div className="grid pt-2">
						    <div className="col-6">
						        <span className="flex flex-column align-items-start gap-1">
		                            <span className="text-sm font-medium text-500">Cashback</span>
		                            <span className="text-sm font-light text-800 ml-2">{segment.discountValue}</span>
		                        </span>
						    </div>
						    <div className="col-6">
						        <span className="flex flex-column align-items-start gap-1">
		                            <span className="text-sm font-medium text-500">Maximum Cashback</span>
		                            <span className="text-sm font-light text-800 ml-2">{segment.maxDiscount}</span>
		                        </span>
						    </div>
						</div>
						<div className="grid pt-2">
						    <div className="col-6">
						        <span className="flex flex-column align-items-start gap-1">
		                            <span className="text-sm font-medium text-500">Cashback Type</span>
		                            <span className="text-sm font-light text-800 ml-2"><CashbackType discountType={segment.discountType} /></span>
		                        </span>
						    </div>
						    <div className="col-4">
						        
						    </div>
						</div>
						<div className="grid pt-2">
						    <div className="col-6">
						        <span className="flex flex-column align-items-start gap-1">
		                            <span className="text-sm font-medium text-500">Minimum purchase required</span>
		                            <MinPurRequired flag={minimumPurchaseRequiredFlag} />
		                        </span>
						    </div>
						    <div className="col-6">
						        <span className="flex flex-column align-items-start gap-1">
		                            <span className="text-sm font-medium text-500">Minimum Purchase Value</span>
		                            <span className="text-sm font-light text-800 ml-2">{minBillAmountForReward}</span>
		                        </span>
						    </div>
						</div>
						<div className="grid pt-2">
						    <div className="col-8">
						        <span className="flex flex-column align-items-start gap-1">
		                            <span className="text-sm font-medium text-500">Validity</span>
		                            <span className="text-sm font-light text-800 ml-2"><GetValidity segment={segment} /> </span>
		                        </span>
						    </div>
						    <div className="col-4">
						        
						    </div>
						</div>
            		</div>
            	</div>
            </div>
        );
    };

const listTemplate = (items) => {
    if (!items || items.length === 0) return null;

    let list = items.map((segment, index) => {
        return itemTemplate(segment, index);
    });

    return <div className="grid grid-nogutter">{list}</div>;
};

const addNewCashbackProgram = ({e, newCashbackProgram, setNewCashbackProgram}) => {
	alert('add new cashback');
};

const editCashbackProgram = (e, bId, oId, sId) => {
	alert('edit cashback - ' + bId + '_' + oId + '_' + sId);
};

const newCashbackProgramObj = {
	id: null,
    outletId: null,
    brandId: null,
    name: null,
    rewardCode: null,
    rewardType: 'CASHBACK',
    discountOn: 'BILL',
    discountType: null,
    discountValue: 0,
    pointMoneyRatio: 0.75,
    maxDiscount: 0,
    minBillAmountForReward: 0,
    products: null,
    numOfProductDiscoutAppliedOn: null,
    tier: 'NA',
    startDate: null,
    endDate: null,
    active: true,
    promotion: false
};

const SegmentsNValidity = {};

export default ManageCustomerSegment;