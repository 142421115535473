import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { Divider } from 'primereact/divider';
import { Password } from 'primereact/password';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import {Link} from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Footer from './Footer';
import { Dialog } from 'primereact/dialog';

import {CheckEmail, ConstantValues, ServerErrorModal} from '../Utility';

function ResetPassword() {

    const search = useLocation().search;
    const authToken=new URLSearchParams(search).get("token");

    const [resetPasswordForm, setResetPasswordForm] = useState('block');
    const [resetPasswordSuccessfully, setResetPasswordSuccessfully] = useState('none');

    const [fieldValues, setFieldValues] = useState({
        'newPassword': '',
        'confirmPassword': '',
        'errors': {}
    });

    const updateFieldValue = (e) => {
        const t = e.target;
        const n = t.name;
        const v = t.value;

        const newState = {...fieldValues, [n]: v};
        setFieldValues(newState);
    }

    const header = <div className="font-bold mb-3">Pick a password</div>;
    const footer = (
        <>
            <Divider />
            <p className="mt-2">Suggestions</p>
            <ul className="pl-2 ml-2 mt-0 line-height-3">
                <li>At least one lowercase</li>
                <li>At least one uppercase</li>
                <li>At least one numeric</li>
                <li>At least one special character ($@!_-)()</li>
                <li>Minimum {ConstantValues.passwordLength} characters</li>
            </ul>
        </>
    );

    const navigate = useNavigate();

    const resetPassword = async(e) => {
        try {
            const password = fieldValues.newPassword;
            const response = await fetch('https://api.saltwin.com/loyalty-web-service/rest/v1/user/reset-password', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({ password, authToken })
              });
            const resJson = await response.json();
            return resJson;
        } catch (err) {
            const errorMessage = "Error:" + err.message;
            console.error(errorMessage);
        }
      }

    const handleSubmit = (e) => {
        e.preventDefault();
        let errors = {};
        if(fieldValues.newPassword.length === 0 ) {
            errors.newPassword = 'New Password is required field';
        } else if(fieldValues.newPassword.length < ConstantValues.passwordLength) {
            errors.newPassword = 'New Password must be of ' + ConstantValues.passwordLength + ' characters length';
        }  

        if(fieldValues.confirmPassword.length === 0) {
            errors.confirmPassword = 'Confirm Password is required field';
        } else if(fieldValues.confirmPassword.length < ConstantValues.passwordLength) {
            errors.confirmPassword = 'Confirm Password must be of ' + ConstantValues.passwordLength + ' characters length';
        } 

        if(fieldValues.newPassword !== fieldValues.confirmPassword) {
            errors.confirmPassword = 'New & Confirm Passwords should be same.';
        }

        const newState = {...fieldValues, errors: errors};
        setFieldValues(newState);

       if(Object.keys(errors).length > 0) {
            return;
       }
        // send a request to the server to validate the credentials
        resetPassword().then(res => {
            if(res != null) {
                if(res.success === true) {
                    setResetPasswordForm('none');
                    setResetPasswordSuccessfully('block');
                } else {
                    let errors = {confirmPassword: res.error};
                    const newState = {...fieldValues, errors: errors};
                    setFieldValues(newState);
                }
            } else {
                <ServerErrorModal />
            }
        });
    }

    const isFormFieldInvalid = (name) => { return fieldValues.errors[name]; }

    const getFormErrorMessage = (name) => {
        return isFormFieldInvalid(name) ? <small className="p-error">{fieldValues.errors[name]}</small> : <small className="p-error">&nbsp;</small>;
    };

    return (<div className="flex flex-column md:flex-row h-screen">
                <div className="w-full flex flex-column align-items-center justify-content-center py-1">
                    <img src={process.env.PUBLIC_URL + '/SaltwinLogo.jpeg'} alt="logo" />
                </div>
                <div className="w-full md:w-1">
                    <Divider layout="vertical" className="hidden md:flex">
                        
                    </Divider>
                    <Divider layout="horizontal" className="flex md:hidden" align="center">
                        
                    </Divider>
                </div>
                <div className="w-full flex align-items-center justify-content-center py-1 relative">
                    <div className="surface-card p-4 w-full lg:w-8" style={{ display: resetPasswordForm}}>
                        <div className="text-center">
                            <div className="text-900 text-2xl font-semibold capitalize">Reset Password</div>
                        </div>

                        <div>
                            {getFormErrorMessage('loginError')}
                            <label htmlFor="newPassword" className="block font-medium mb-2 text-gray-800">New Password</label>
                            <Password placeholder="New Password" panelClassName="w-3" inputClassName="w-12" style={{ width: '100%'}}
                                header={header} footer={footer}
                                name="newPassword"
                                value={fieldValues.newPassword}
                                onChange={(e) => updateFieldValue(e)} />
                            {getFormErrorMessage('newPassword')}
                            <label htmlFor="confirmPassword" className="block font-medium mb-2 text-gray-800">Confirm Password</label>
                            <Password placeholder="Confirm Password" panelClassName="w-3" inputClassName="w-12" style={{ width: '100%'}}
                                header={header} footer={footer}
                                name="confirmPassword"
                                value={fieldValues.confirmPassword}
                                onChange={(e) => updateFieldValue(e)} />
                            {getFormErrorMessage('confirmPassword')}
                            <Button label="Submit" icon="" className="w-full bg-yellow-500 text-yellow-900 border-round-sm mt-2 pi pi-user"
                                onClick={(e) => handleSubmit(e)} />

                            <div className="text-center mt-1 py-2">
                                
                            </div>
                        </div>
                        
                    </div>
                    <div  style={{ display: resetPasswordSuccessfully}}>
                        <small className="text-yellow-900 text-lg mr-2">Password reset sucessfully.</small>
                        <Link to="/" className="no-underline text-yellow-800 text-right cursor-pointer">Sign In</Link>
                    </div>
                    <Footer />
                </div>
            </div>
    );
}


export default ResetPassword;

        