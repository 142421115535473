import React from "react";

const BASE_URL = 'https://api.saltwin.com/loyalty-web-service/';

const RequestTypes = {
	GET: 'get',
	POST: 'post',
	PUT: 'put',
	DELETE: 'delete'
};

const RelativePaths = {
    LOGIN: 'rest/v1/user/signin',
    SIGNUP: 'rest/v1/user/register'
};

const SendHttpRequest = async(requestType, relativePath, payload) => {
	try {
        const response = await fetch(BASE_URL + relativePath, {
            method: requestType,
            headers: {
              'Content-Type': 'application/json'
            },
            body: ((null === payload || undefined === payload) ? '' : JSON.stringify(payload))
          });
        const resJson = await response.json();
        return resJson;
    } catch (err) {
        const errorMessage = "Error:" + err.message;
        return errorMessage;
    }
}

export { RequestTypes, SendHttpRequest };