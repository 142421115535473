import React, { useState, useEffect , useRef}  from 'react';
import { useNavigate } from 'react-router-dom';
import {NavigationBarVertical, NavigationBarHorizontal, Footer} from './NavigationBar';
import { DataView, DataViewLayoutOptions } from 'primereact/dataview';
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';
import { Badge } from 'primereact/badge';
import { Avatar } from 'primereact/avatar';
import { Chip } from 'primereact/chip';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from "primereact/checkbox";
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import { AutoComplete } from "primereact/autocomplete";
import { ProgressSpinner } from 'primereact/progressspinner';
import { OverlayPanel } from 'primereact/overlaypanel';
import { RadioButton } from "primereact/radiobutton";
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useTimout } from 'primereact/hooks';
import { Dropdown } from 'primereact/dropdown';

import { ServerErrorModal, APIErrorModal, ConstantValues} from '../Utility';

import { FaPencil, FaTrashCan, FaPlus, FaUsers, FaCheck, FaXmark } from 'react-icons/fa6';

const ManageOutlets = () => {

	const navigate = useNavigate();

	useEffect(() => { 
		if(undefined === localStorage.getItem('token') === null ||
				localStorage.getItem('token') === null) navigate('/'); 
		localStorage.setItem('currentPage', 'manageoutlets');
	}, []);

	const ConfigOutlets = () => {
		return (
			<div className="grid px-3">
			    <div className="col-12">
			    	<div className="flex">
					    <div className="flex-1 flex justify-content-start align-items-center flex-wrap">
				    		<div className="text-2xl font-medium">Outlets</div>
					    	<Avatar icon="pi pi-refresh" className="cursor-pointer"
					    		style={{ backgroundColor: '#fff', color: '#000'}} shape="circle" />
				    	</div>
				    	<div className="flex-1 flex justify-content-end flex-wrap">
				    		<Button icon={FaPlus} rounded text raised severity="secondary" aria-label="Add Segment">&nbsp;Add New</Button>
				    	</div>
					</div>
			        <div className="flex">
						<div className="flex flex-column overflow-auto" style={{ width: '100%', height: '70vh' }}>
							
					   </div>
					</div>
			    </div>
			</div>
		);
	}

	return (
		<>
		<div className="relative p-0 m-0">
		    <div className="relative text-gray-800 w-screen h-screen">
        		<NavigationBarVertical />
		        <div className="absolute top-0 left-0 h-4rem px-2 border-200 border-bottom-1" style={{ width: ConstantValues.hNavBarWidth, height: '95%', marginLeft: ConstantValues.vNavBarWidth }}>
		        	<NavigationBarHorizontal hideLinkOf='ManageCustomerSegments' />
					<div id="show-saltwin-content" className="absolute top-4rem left-0 px-2 py-4" style={{ width: '100%', height: '95%' }}>
						<div id="cust-segments" className="py-2 relative">
							<div className="grid overflow-hidden" style={{height: '86vh' }}>
							    <div className="col-12">
							        <ConfigOutlets />
							    </div>
							</div>
						</div>
					</div>
        		</div>
        		<Footer />
		    </div>
		</div>
		</>
	);

}

export default ManageOutlets;