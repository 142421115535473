import { Dialog } from 'primereact/dialog';
import React, { useState } from "react";

const regEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;

function CheckEmail(email) {
  	if (regEmail.test(email) === false) {
  		return false;
  	}
  	return true;
}

function OnlyNumberKey(e) {
	const ASCIICode = e.which ? e.which : e.keyCode;
	if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57)){
		e.preventDefault();
        return false;
	}
    return true;
}

function setAuthUser(response) {
	const un = response.username;
	const e = response.email;
	const t = response.accessToken;
	localStorage.setItem('authUserName', un);
	localStorage.setItem('authUserEmail', e);
	localStorage.setItem('authUserAccessToken', t);
}

function isUserAuthenticated() {
	return (getAccessToken() === null) ? false : true;
}

function getAccessToken() {
	return localStorage.getItem('authUserAccessToken');
}

function setLastActivityTimestamp() {
	
}

const ServerErrorModal = ({flg}) => {
		const [dialogServerError, setDialogServerError] = useState(false);
		setDialogServerError(flg);
		return <Dialog header="Server Error" visible={dialogServerError} style={{ width: '50vw' }} onHide={() => setDialogServerError(false)}>
	        <p className="m-0 text-lg font-medium mb-2 text-gray-800">
	            <p>Internal Server Error</p>
	            <p>Please try after sometime or contact <a href="mailto:support@saltwin.com">system administrator</a>.</p>
	        </p>
	    </Dialog> ;
}

const APIErrorModal = ({error}) => {
		const [dialogAPIError, setDialogAPIError] = useState(false);
		setDialogAPIError(true);
		return dialogAPIError ? <Dialog header="Server Error" visible={dialogAPIError} style={{ width: '50vw' }} onHide={() => setDialogAPIError(false)}>
	        <p className="m-0 text-lg font-medium mb-2 text-gray-800">
	            <p>{error.message}</p>
	            <p>Please try after sometime or contact <a href="mailto:support@saltwin.com">system administrator</a>.</p>
	        </p>
	    </Dialog> : <></>;
}

function APIEndpoints() {

	const BaseURL = 'https://api.saltwin.com/loyalty-web-service/rest';

	const _Login = BaseURL + '/user/signin';

}

const ConstantValues = {
	passwordLength: 8,
	hNavBarWidth: '83%',
	vNavBarWidth: '17%',
	contentViewHeight: '75vh',
	contentViewWidth: '100%'
};

const CountryPhoneCodes = [
		{value: '91', name: 'India +91'},
		{value: '965', name: 'Kuwait +965'},
		{value: '93', name: 'Afghanistan +93'},
		{value: '358', name: 'Aland Islands +358'},
		{value: '355', name: 'Albania +355'},
		{value: '213', name: 'Algeria +213'},
		{value: '1684', name: 'American Samoa +1684'},
		{value: '376', name: 'Andorra +376'},
		{value: '244', name: 'Angola +244'},
		{value: '1264', name: 'Anguilla +1264'},
		{value: '672', name: 'Antarctica +672'},
		{value: '1268', name: 'Antigua and Barbuda +1268'},
		{value: '54', name: 'Argentina +54'},
		{value: '374', name: 'Armenia +374'},
		{value: '297', name: 'Aruba +297'},
		{value: '61', name: 'Australia +61'},
		{value: '43', name: 'Austria +43'},
		{value: '994', name: 'Azerbaijan +994'},
		{value: '1242', name: 'Bahamas +1242'},
		{value: '973', name: 'Bahrain +973'},
		{value: '880', name: 'Bangladesh +880'},
		{value: '1246', name: 'Barbados +1246'},
		{value: '375', name: 'Belarus +375'},
		{value: '32', name: 'Belgium +32'},
		{value: '501', name: 'Belize +501'},
		{value: '229', name: 'Benin +229'},
		{value: '1441', name: 'Bermuda +1441'},
		{value: '975', name: 'Bhutan +975'},
		{value: '591', name: 'Bolivia +591'},
		{value: '599', name: 'Bonaire, Sint Eustatius and Saba +599'},
		{value: '387', name: 'Bosnia and Herzegovina +387'},
		{value: '267', name: 'Botswana +267'},
		{value: '55', name: 'Bouvet Island +55'},
		{value: '55', name: 'Brazil +55'},
		{value: '246', name: 'British Indian Ocean Territory +246'},
		{value: '673', name: 'Brunei Darussalam +673'},
		{value: '359', name: 'Bulgaria +359'},
		{value: '226', name: 'Burkina Faso +226'},
		{value: '257', name: 'Burundi +257'},
		{value: '855', name: 'Cambodia +855'},
		{value: '237', name: 'Cameroon +237'},
		{value: '1', name: 'Canada +1'},
		{value: '238', name: 'Cape Verde +238'},
		{value: '1345', name: 'Cayman Islands +1345'},
		{value: '236', name: 'Central African Republic +236'},
		{value: '235', name: 'Chad +235'},
		{value: '56', name: 'Chile +56'},
		{value: '86', name: 'China +86'},
		{value: '61', name: 'Christmas Island +61'},
		{value: '672', name: 'Cocos (Keeling) Islands +672'},
		{value: '57', name: 'Colombia +57'},
		{value: '269', name: 'Comoros +269'},
		{value: '242', name: 'Congo +242'},
		{value: '242', name: 'Congo, Democratic Republic of the Congo +242'},
		{value: '682', name: 'Cook Islands +682'},
		{value: '506', name: 'Costa Rica +506'},
		{value: '225', name: "Cote D'Ivoire +225"},
		{value: '385', name: 'Croatia +385'},
		{value: '53', name: 'Cuba +53'},
		{value: '599', name: 'Curacao +599'},
		{value: '357', name: 'Cyprus +357'},
		{value: '420', name: 'Czech Republic +420'},
		{value: '45', name: 'Denmark +45'},
		{value: '253', name: 'Djibouti +253'},
		{value: '1767', name: 'Dominica +1767'},
		{value: '1809', name: 'Dominican Republic +1809'},
		{value: '593', name: 'Ecuador +593'},
		{value: '20', name: 'Egypt +20'},
		{value: '503', name: 'El Salvador +503'},
		{value: '240', name: 'Equatorial Guinea +240'},
		{value: '291', name: 'Eritrea +291'},
		{value: '372', name: 'Estonia +372'},
		{value: '251', name: 'Ethiopia +251'},
		{value: '500', name: 'Falkland Islands (Malvinas) +500'},
		{value: '298', name: 'Faroe Islands +298'},
		{value: '679', name: 'Fiji +679'},
		{value: '358', name: 'Finland +358'},
		{value: '33', name: 'France +33'},
		{value: '594', name: 'French Guiana +594'},
		{value: '689', name: 'French Polynesia +689'},
		{value: '262', name: 'French Southern Territories +262'},
		{value: '241', name: 'Gabon +241'},
		{value: '220', name: 'Gambia +220'},
		{value: '995', name: 'Georgia +995'},
		{value: '49', name: 'Germany +49'},
		{value: '233', name: 'Ghana +233'},
		{value: '350', name: 'Gibraltar +350'},
		{value: '30', name: 'Greece +30'},
		{value: '299', name: 'Greenland +299'},
		{value: '1473', name: 'Grenada +1473'},
		{value: '590', name: 'Guadeloupe +590'},
		{value: '1671', name: 'Guam +1671'},
		{value: '502', name: 'Guatemala +502'},
		{value: '44', name: 'Guernsey +44'},
		{value: '224', name: 'Guinea +224'},
		{value: '245', name: 'Guinea-Bissau +245'},
		{value: '592', name: 'Guyana +592'},
		{value: '509', name: 'Haiti +509'},
		{value: '39', name: 'Holy See (Vatican City State) +39'},
		{value: '504', name: 'Honduras +504'},
		{value: '852', name: 'Hong Kong +852'},
		{value: '36', name: 'Hungary +36'},
		{value: '354', name: 'Iceland +354'},
		{value: '62', name: 'Indonesia +62'},
		{value: '98', name: 'Iran, Islamic Republic of +98'},
		{value: '964', name: 'Iraq +964'},
		{value: '353', name: 'Ireland +353'},
		{value: '44', name: 'Isle of Man +44'},
		{value: '972', name: 'Israel +972'},
		{value: '39', name: 'Italy +39'},
		{value: '1876', name: 'Jamaica +1876'},
		{value: '81', name: 'Japan +81'},
		{value: '44', name: 'Jersey +44'},
		{value: '962', name: 'Jordan +962'},
		{value: '7', name: 'Kazakhstan +7'},
		{value: '254', name: 'Kenya +254'},
		{value: '686', name: 'Kiribati +686'},
		{value: '850', name: "Korea, Democratic People's Republic of +850"},
		{value: '82', name: 'Korea, Republic of +82'},
		{value: '381', name: 'Kosovo +383'},
		{value: '996', name: 'Kyrgyzstan +996'},
		{value: '856', name: "Lao People's Democratic Republic +856"},
		{value: '371', name: 'Latvia +371'},
		{value: '961', name: 'Lebanon +961'},
		{value: '266', name: 'Lesotho +266'},
		{value: '231', name: 'Liberia +231'},
		{value: '218', name: 'Libyan Arab Jamahiriya +218'},
		{value: '423', name: 'Liechtenstein +423'},
		{value: '370', name: 'Lithuania +370'},
		{value: '352', name: 'Luxembourg +352'},
		{value: '853', name: 'Macao +853'},
		{value: '389', name: 'Macedonia, the Former Yugoslav Republic of +389'},
		{value: '261', name: 'Madagascar +261'},
		{value: '265', name: 'Malawi +265'},
		{value: '60', name: 'Malaysia +60'},
		{value: '960', name: 'Maldives +960'},
		{value: '223', name: 'Mali +223'},
		{value: '356', name: 'Malta +356'},
		{value: '692', name: 'Marshall Islands +692'},
		{value: '596', name: 'Martinique +596'},
		{value: '222', name: 'Mauritania +222'},
		{value: '230', name: 'Mauritius +230'},
		{value: '262', name: 'Mayotte +262'},
		{value: '52', name: 'Mexico +52'},
		{value: '691', name: 'Micronesia, Federated States of +691'},
		{value: '373', name: 'Moldova, Republic of +373'},
		{value: '377', name: 'Monaco +377'},
		{value: '976', name: 'Mongolia +976'},
		{value: '382', name: 'Montenegro +382'},
		{value: '1664', name: 'Montserrat +1664'},
		{value: '212', name: 'Morocco +212'},
		{value: '258', name: 'Mozambique +258'},
		{value: '95', name: 'Myanmar +95'},
		{value: '264', name: 'Namibia +264'},
		{value: '674', name: 'Nauru +674'},
		{value: '977', name: 'Nepal +977'},
		{value: '31', name: 'Netherlands +31'},
		{value: '599', name: 'Netherlands Antilles +599'},
		{value: '687', name: 'New Caledonia +687'},
		{value: '64', name: 'New Zealand +64'},
		{value: '505', name: 'Nicaragua +505'},
		{value: '227', name: 'Niger +227'},
		{value: '234', name: 'Nigeria +234'},
		{value: '683', name: 'Niue +683'},
		{value: '672', name: 'Norfolk Island +672'},
		{value: '1670', name: 'Northern Mariana Islands +1670'},
		{value: '47', name: 'Norway +47'},
		{value: '968', name: 'Oman +968'},
		{value: '92', name: 'Pakistan +92'},
		{value: '680', name: 'Palau +680'},
		{value: '970', name: 'Palestinian Territory, Occupied +970'},
		{value: '507', name: 'Panama +507'},
		{value: '675', name: 'Papua New Guinea +675'},
		{value: '595', name: 'Paraguay +595'},
		{value: '51', name: 'Peru +51'},
		{value: '63', name: 'Philippines +63'},
		{value: '64', name: 'Pitcairn +64'},
		{value: '48', name: 'Poland +48'},
		{value: '351', name: 'Portugal +351'},
		{value: '1787', name: 'Puerto Rico +1787'},
		{value: '974', name: 'Qatar +974'},
		{value: '262', name: 'Reunion +262'},
		{value: '40', name: 'Romania +40'},
		{value: '7', name: 'Russian Federation +7'},
		{value: '250', name: 'Rwanda +250'},
		{value: '590', name: 'Saint Barthelemy +590'},
		{value: '290', name: 'Saint Helena +290'},
		{value: '1869', name: 'Saint Kitts and Nevis +1869'},
		{value: '1758', name: 'Saint Lucia +1758'},
		{value: '590', name: 'Saint Martin +590'},
		{value: '508', name: 'Saint Pierre and Miquelon +508'},
		{value: '1784', name: 'Saint Vincent and the Grenadines +1784'},
		{value: '684', name: 'Samoa +684'},
		{value: '378', name: 'San Marino +378'},
		{value: '239', name: 'Sao Tome and Principe +239'},
		{value: '966', name: 'Saudi Arabia +966'},
		{value: '221', name: 'Senegal +221'},
		{value: '381', name: 'Serbia +381'},
		{value: '381', name: 'Serbia and Montenegro +381'},
		{value: '248', name: 'Seychelles +248'},
		{value: '232', name: 'Sierra Leone +232'},
		{value: '65', name: 'Singapore +65'},
		{value: '721', name: 'Sint Maarten +721'},
		{value: '421', name: 'Slovakia +421'},
		{value: '386', name: 'Slovenia +386'},
		{value: '677', name: 'Solomon Islands +677'},
		{value: '252', name: 'Somalia +252'},
		{value: '27', name: 'South Africa +27'},
		{value: '500', name: 'South Georgia and the South Sandwich Islands +500'},
		{value: '211', name: 'South Sudan +211'},
		{value: '34', name: 'Spain +34'},
		{value: '94', name: 'Sri Lanka +94'},
		{value: '249', name: 'Sudan +249'},
		{value: '597', name: 'Suriname +597'},
		{value: '47', name: 'Svalbard and Jan Mayen +47'},
		{value: '268', name: 'Swaziland +268'},
		{value: '46', name: 'Sweden +46'},
		{value: '41', name: 'Switzerland +41'},
		{value: '963', name: 'Syrian Arab Republic +963'},
		{value: '886', name: 'Taiwan, Province of China +886'},
		{value: '992', name: 'Tajikistan +992'},
		{value: '255', name: 'Tanzania, United Republic of +255'},
		{value: '66', name: 'Thailand +66'},
		{value: '670', name: 'Timor-Leste +670'},
		{value: '228', name: 'Togo +228'},
		{value: '690', name: 'Tokelau +690'},
		{value: '676', name: 'Tonga +676'},
		{value: '1868', name: 'Trinidad and Tobago +1868'},
		{value: '216', name: 'Tunisia +216'},
		{value: '90', name: 'Turkey +90'},
		{value: '7370', name: 'Turkmenistan +7370'},
		{value: '1649', name: 'Turks and Caicos Islands +1649'},
		{value: '688', name: 'Tuvalu +688'},
		{value: '256', name: 'Uganda +256'},
		{value: '380', name: 'Ukraine +380'},
		{value: '971', name: 'United Arab Emirates +971'},
		{value: '44', name: 'United Kingdom +44'},
		{value: '1', name: 'United States +1'},
		{value: '1', name: 'United States Minor Outlying Islands +1'},
		{value: '598', name: 'Uruguay +598'},
		{value: '998', name: 'Uzbekistan +998'},
		{value: '678', name: 'Vanuatu +678'},
		{value: '58', name: 'Venezuela +58'},
		{value: '84', name: 'Viet Nam +84'},
		{value: '1284', name: 'Virgin Islands, British +1284'},
		{value: '1340', name: 'Virgin Islands, U.s. +1340'},
		{value: '681', name: 'Wallis and Futuna +681'},
		{value: '212', name: 'Western Sahara +212'},
		{value: '967', name: 'Yemen +967'},
		{value: '260', name: 'Zambia +260'},
		{value: '263', name: 'Zimbabwe +263'}
	];

export { CheckEmail, APIEndpoints, setAuthUser, isUserAuthenticated, getAccessToken, ServerErrorModal, APIErrorModal, ConstantValues, CountryPhoneCodes };