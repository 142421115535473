import React, { useState } from "react";
import { Divider } from 'primereact/divider';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import {Link} from 'react-router-dom';
import Footer from './Footer';

import { Carousel } from 'primereact/carousel';

import {CheckEmail, ConstantValues, ServerErrorModal, CountryPhoneCodes} from '../Utility';


function SignUp() {
		const [dialogVisible, setDialogVisible] = useState(false);
		const [regUser, setRegUser] = useState('');
		const [regMessage, setRegMessage] = useState('');

        const [membershipPackages, setMembershipPackages] = useState(memberPackages);

        const codes = CountryPhoneCodes;

		const ShowRegisterSuccessModal = () => {
			return dialogVisible ? <Dialog header={regUser} visible={dialogVisible} style={{ width: '50vw' }} onHide={() => setDialogVisible(false)}>
	        <p className="m-0 text-lg font-medium mb-2 text-gray-800">
	            <p>{regMessage}</p>
	            <Link to="/" className="text-yellow-800">Sign In</Link>
	            <span> to complete your configurations.</span>
	        </p>
	    </Dialog> : <></>;
		}

    const [fieldValues, setFieldValues] = useState({
        'fullName': '',
        'companyName': '',
        'email': '',
        'password': '',
        'agreeOnTermNCondition': false,
        'countryCode': '',
        'phoneNumber': '',
        'errors': {}
    });

    const updateFieldValue = (e) => {
        const t = e.target;
        const n = t.name;
        var v = t.value;

        if(t.type === 'checkbox') {
        	v = t.checked;
        }

        const newState = {...fieldValues, [n]: v};
        setFieldValues(newState);
    }

    const validateUser = async(e) => {
        try {
            const fullName = fieldValues.fullName;
            const firstName = fullName;
            const companyName = fieldValues.companyName;
            const email = fieldValues.email;
            const username = email;
            const password = fieldValues.password;
            const agreeOnTermNCondition = fieldValues.agreeOnTermNCondition;
            const countryCode = fieldValues.countryCode;
            const phoneNumber = fieldValues.phoneNumber;
            const response = await fetch('https://api.saltwin.com/loyalty-web-service/rest/v1/user/register', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({ fullName, companyName, email, username, password, agreeOnTermNCondition,
                                        firstName, countryCode, phoneNumber })
              });
            const resJson = await response.json();
            return resJson;
        } catch (err) {
            const errorMessage = "Error:" + err.message;
            console.error(errorMessage);
        }
      }

    const handleSubmit = (e) => {
        e.preventDefault();
        let errors = {};
        if(fieldValues.fullName.length === 0 ) {
            errors.fullName = 'Full name is required fields';
        } 
        if(fieldValues.companyName.length === 0 ) {
            errors.companyName = 'Company name is required fields';
        } 
        if(fieldValues.email.length === 0 ) {
            errors.email = 'Work email is required fields';
        } else if(!CheckEmail(fieldValues.email)) {
        		errors.email = 'Please provide valid email';
        }

        if(fieldValues.countryCode.length === 0 && fieldValues.phoneNumber.length === 0) {
            errors.phoneNumber = 'Country code & Phone number are required fields';
        } else if(fieldValues.countryCode.length === 0 ) {
            errors.phoneNumber = 'Choose countryCode';
        } else if(fieldValues.phoneNumber.length === 0 ) {
            errors.phoneNumber = 'Phone number is required fields';
        } 

        if(fieldValues.password.length === 0) {
            errors.password = 'Password is required fields';
        } else if(fieldValues.password.length < 8) {
            errors.password = 'Password must be of 8 characters length';
        } 

        if(!fieldValues.agreeOnTermNCondition) {
            errors.agreeOnTermNCondition = 'Please accept the terms and condition';
        } 

        const newState = {...fieldValues, errors: errors};
        setFieldValues(newState);

       if(Object.keys(errors).length > 0) {
            return;
       }
        // send a request to the server to validate the credentials
        validateUser().then(res => {
            if(res != null) {
                if(true === res.isSuccess) {
                    setFieldValues({
                        'fullName': '',
                                        'companyName': '',
                                        'email': '',
                                        'password': '',
                                        'agreeOnTermNCondition': false,
                                        'errors': {}
                    });
                    const regUser = res.firstName;
                    const usrname = res.username;
                    const msg = 'Registration successful. Your username is - ' + usrname;
                    setRegUser('Welcome, ' + regUser);
                                setRegMessage(msg);
                                setDialogVisible(true);
                    <ShowRegisterSuccessModal username={regUser} message={msg} />;
                } else {
                    if('BadRequest' === res.error) {
                        const newState = {...fieldValues, errors: res.message};
                        setFieldValues(newState);
                    } else {
                        let errMsg = '';
                        if('Unauthorized' === res.error) {
                            errMsg = res.message;
                        } else if(undefined !== res.error.message) {
                            errMsg = res.error.message;
                        } else if(null !== res.error) {
                            errMsg = res.error;
                        }

                        let errors = {};
                        errors.loginError = errMsg;
                        const newState = {...fieldValues, errors: errors};
                        setFieldValues(newState);
                    }
                }
            } else {
                <ServerErrorModal />
            }
        });
    }

    const isFormFieldInvalid = (name) => { return fieldValues.errors[name]; }

    const getFormErrorMessage = (name) => {
        return isFormFieldInvalid(name) ? <small className="p-error">{fieldValues.errors[name]}</small> : <small className="p-error">&nbsp;</small>;
    };

    const membershipPackagesTemplate = (memPackage) => {
        return (
                <div className="p-4 shadow-1 bg-white-alpha-90 border-1 border-gray-200 border-round-sm">
                    <p className="text-yellow-600 font-semibold  text-lg my-2">{memPackage.title}</p>
                    <p className="text-bluegray-600 font-semibold text-xl mt-2 mb-3">{memPackage.name}</p>
                    <div className="border-bottom-1 border-gray-300"></div>
                    <div className="my-4">
                        <p className="text-gray-500 uppercase my-2">manage</p>
                        <p className="text-black-alpha-80 text-lg my-2">{memPackage.customerLimit} customers</p>
                    </div>
                    <div className="my-4">
                        <p className="text-gray-500 uppercase my-2">redemptions</p>
                        <p className="text-black-alpha-80 text-lg my-2">{memPackage.redemptionLimit}</p>
                    </div>
                    <div className="my-4">
                        <p className="text-gray-500 uppercase my-2">earning</p>
                        <p className="text-black-alpha-80 text-lg my-2">{memPackage.earningLimit}</p>
                    </div>
                    <div className="border-dashed border-bottom-1 border-gray-300"></div>
                    <div className="flex mt-4 mb-2">
                        <div className="flex-1 flex align-items-center justify-content-start text-bluegray-600 font-semibold uppercase">invoice</div>
                        <div className="flex-1 flex align-items-center justify-content-end text-yellow-600 font-semibold  text-lg">{memPackage.membershipPrice}</div>
                    </div>
                </div>
            );
    }

    const pwdPlaceholder = 'Atleast ' + ConstantValues.passwordLength + ' characters';
    const header = <div className="font-bold mb-3">Pick a password</div>;
    const footer = (
        <>
            <Divider />
            <p className="mt-2">Suggestions</p>
            <ul className="pl-2 ml-2 mt-0 line-height-3">
                <li>At least one lowercase</li>
                <li>At least one uppercase</li>
                <li>At least one numeric</li>
                <li>At least one special character ($@!_-)()</li>
                <li>Minimum {ConstantValues.passwordLength} characters</li>
            </ul>
        </>
    );

    return (
            <>
            <div className="">{ShowRegisterSuccessModal()}
                <div className="grid  grid-nogutter" style={{ height: '100vh' }}>
                    <div className="col-6 bg-yellow-50">
                        <div className="p-3 mt-3 align-items-center justify-content-center">
                            <div className="flex justify-content-center flex-wrap">
                                <img src={process.env.PUBLIC_URL + '/saltwin-text.png' } alt="home-logo" />
                            </div>
                            
                            <div id="membership-package" className="mt-6 p-3">
                                <div className="w-9 m-auto p-2">
                                    <Carousel value={membershipPackages} numVisible={1} numScroll={1} circular className="custom-carousel"
                                        autoplayInterval={5000} itemTemplate={membershipPackagesTemplate} showNavigators={false} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 border-left-1 border-gray-300 relative">
                        <div className="flex align-self-start align-items-center justify-content-center mt-3">
                            <div className="surface-card p-2 w-8">
                                <div className="text-center">
                                    <div className="text-900 text-2xl font-semibold capitalize mb-5">Start your free trial</div>
                                    <Button label="Sign Up with google" icon="pi pi-google" className="w-full bg-yellow-800 text-white text-lg" />
                                </div>
                                <Divider layout="horizontal" className="hidden md:flex mb-1" align="center">
                                    <b className="font-medium text-bluegray-500 text-sm">or using your email</b>
                                    </Divider>
                                <div>
                                    {getFormErrorMessage('loginError')}
                                    <label htmlFor="fullName" className="block font-medium mb-2 text-gray-800">Full Name</label>
                                    <InputText id="fullName" type="text" placeholder="First name and last name" className="w-full border-round-sm"
                                        name="fullName"
                                        value={fieldValues.fullName}
                                        onChange={(e) => updateFieldValue(e)} />
                                    {getFormErrorMessage('fullName')}
                                    <label htmlFor="companyName" className="block font-medium mb-2 text-gray-800">Company Name</label>
                                    <InputText id="companyName" type="text" placeholder="Your company name" className="w-full border-round-sm"
                                        name="companyName"
                                        value={fieldValues.companyName}
                                        onChange={(e) => updateFieldValue(e)} />
                                    {getFormErrorMessage('companyName')}
                                    <div className="flex flex-column gap-1">
                                        <label htmlFor="phoneNumber">Phone Number</label>
                                        <div className="flex-1 flex justify-content-start align-items-center">
                                            <Dropdown name="countryCode" value={fieldValues.countryCode} onChange={(e) => updateFieldValue(e)} options={codes} optionLabel="name"
                                                placeholder="Country Code" className="mr-2 w-8" />
                                            <InputText id="phoneNumber" name="phoneNumber" placeholder="Phone Number" value={fieldValues.phoneNumber} onChange={(e) => updateFieldValue(e)} 
                                            keyfilter="int" className="w-8" />
                                        </div>
                                    </div>
                                    {getFormErrorMessage('phoneNumber')}
                                    <label htmlFor="email" className="block font-medium mb-2 text-gray-800">Work Email</label>
                                    <InputText id="email" type="text" placeholder="youremail@company.com" className="w-full border-round-sm"
                                        name="email"
                                        keyfilter="email"
                                        value={fieldValues.email}
                                        onChange={(e) => updateFieldValue(e)} />
                                    {getFormErrorMessage('email')}
                                    <div className="bg-yellow-100 p-2 mb-2 border-round-sm flex">
                                            <span className="pi pi-info-circle text-cyan-400 font-light flex-1 mr-2"></span>
                                            <div className="text-yellow-800 text-sm font-light flex-2">Use your corporate email to integrate customer emails, existing applications, and invite your team members.</div>
                                        </div>
                                    <label htmlFor="password" className="block font-medium mb-2 text-gray-800">Password</label>
                                    <Password placeholder={pwdPlaceholder} panelClassName="w-3" inputClassName="w-12" style={{ width: '100%'}}
                                        header={header} footer={footer}
                                        name="password"
                                        value={fieldValues.password}
                                        onChange={(e) => updateFieldValue(e)}
                                        autoCorrect={false} />
                                    {getFormErrorMessage('password')}
                                    <div className="flex align-items-center">
                                        <Checkbox inputId="agreeOnTermNCondition" name="agreeOnTermNCondition" 
                                            onChange={(e) => updateFieldValue(e)} 
                                            checked={fieldValues.agreeOnTermNCondition} />
                                        <label htmlFor="agreeOnTermNCondition" className="ml-2 capitalize  font-normal">i agree to company's term & conditions</label>
                                            {getFormErrorMessage('agreeOnTermNCondition')}
                                    </div>
                                    <Button label="Sign Up" icon="" className="w-full bg-yellow-500 text-yellow-900 border-round-sm mt-2 pi pi-user-plus"
                                        onClick={(e) => handleSubmit(e)} />

                                    <div className="text-center mt-1 py-2">
                                        <span className="no-underline text-bluegray-600 font-light text-right mr-3">Already Using Saltwin?</span>
                                        <Link to="/" className="no-underline text-yellow-800 text-right cursor-pointer">Sign In</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>
            </div>
        </>
    );
}

const memberPackages = [];

const memberPackages2 = [
        {
            title: 'Membership package - Gold [Demo]',
            name: '5 month package',
            customerLimit: 25000,
            redemptionLimit: 'Unlimited',
            earningLimit: '$150 per customer',
            membershipPrice: '$XXX.XX'
        },
        {
            title: 'Membership package - Silver [Demo]',
            name: '1 month package',
            customerLimit: 5000,
            redemptionLimit: 'Unlimited',
            earningLimit: '$100 per customer',
            membershipPrice: '$XXX.XX'
        },
        {
            title: 'Welcome package',
            name: '1 month free trial',
            customerLimit: 500,
            redemptionLimit: 'Unlimited',
            earningLimit: '$1000 per customer',
            membershipPrice: '$0.00'
        }
    ];


export default SignUp;

        