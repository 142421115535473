import React, { useState, useEffect }  from 'react';
import { useNavigate } from 'react-router-dom';
import {NavigationBarVertical, NavigationBarHorizontal, Footer, ProgressLoader} from './NavigationBar';
import { DataView } from 'primereact/dataview';
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';
import { Avatar } from 'primereact/avatar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from "primereact/checkbox";
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import { RadioButton } from "primereact/radiobutton";
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { SelectButton } from 'primereact/selectbutton';
import dateFormat from 'dateformat';

import { ServerErrorModal, ConstantValues} from '../Utility';

import { FaPencil, FaTrashCan, FaPlus, FaCheck, FaXmark } from 'react-icons/fa6';


const ManageCashbackRules = () => {

	const [loaderVisible, setLoaderVisible] = useState(false);

	const [dialogAPIErrorFlag, setDialogAPIErrorFlag] = useState(false);
	const [dialogAPIError, setDialogAPIError] = useState(null);

	const [dialogAPIInfoFlag, setDialogAPIInfoFlag] = useState(false);
    const [dialogAPIInfoMessage, setDialogAPIInfoMessage] = useState(null);

    const [segmentsData, setSegmentsData] = useState([]);
    const [cashbackRulesData, setCashbackRulesData] = useState([]);
    const [cashbackRulesOnLoadData, setCashbackRulesOnLoadData] = useState([]);

    const [dialogVisible, setDialogVisible] = useState(false);
	const [dialogCashbackRule, setDialogCashbackRule] = useState(newCashbackRule);

	const [deleteCashbackRule, setDeleteCashbackRule] = useState(null);
	const [selectCBRuleButtonOptions, setSelectCBRuleButtonOptions] = useState(['All','Active','Inactive']);
	const [selectCBRuleButtonValue, setSelectCBRuleButtonValue] = useState('All');
	const [currentSelectedCBRule, setCurrentSelectedCBRule] = useState(null);
	
	const navigate = useNavigate();

	useEffect(() => {
		if('' === localStorage.getItem('token') ||
				localStorage.getItem('token').length === 0) navigate('/'); 
		localStorage.setItem('currentPage', 'managecashbackrules');
		if(checkBrandsAvailable()) {
            getSegmentsDetails();
            getCashbackRules();
        }
	}, []);

	const checkBrandsAvailable = () => {
		var currBrand = JSON.parse(localStorage.getItem("currentBrand"));
		var currBrandId = currBrand.brandId;
		if(currBrandId === -313131) {
            setDialogAPIInfoMessage('Please configure your brand(s) or contact system administrator');
            setDialogAPIInfoFlag(true);
            return false;
        }
        return true;
	}

	const checkSegmentsToAddNewRule = () => {
        if(0 === getSegmentOptionsToAddNewRule().length) {
            setDialogAPIInfoMessage('Either no segment left to add cashback rule, or some segment(s) in inactive status');
            setDialogAPIInfoFlag(true);
            return false;
        }
        return true;
    }

	const getSegmentsDetails = () => {
		const sd = async() => {
			setLoaderVisible(true);
			try {
				var currBrand = JSON.parse(localStorage.getItem("currentBrand"));
				var currBrandId = currBrand.brandId;
		      	// send a request to the server to get dashboard details
		      	const response = await fetch('https://api.saltwin.com/loyalty-web-service/rest/v1/segment/' + currBrandId,
		      	{
			        method: 'GET',
			        headers: {
			          'Content-Type': 'application/json',
			          'Authorization': 'Bearer ' + localStorage.getItem('token')
			        }
		      	});
		      	const resJson = await response.json();
		      	SegmentsList(resJson);
		    } catch (err) {
		    	<ServerErrorModal flg={true} />
		    }
		    setLoaderVisible(false);
		}
		sd(); 
  	}	

  	const SegmentsList = (resJson) => {
  		if(resJson.success === true) {
      		setSegmentsData(resJson.segments);
      	} else {
      		showPopupOnAPIError(resJson);
      	}
  	};

	const getCashbackRules = () => {
		const sd = async() => {
			setLoaderVisible(true);
			try {
				var currBrand = JSON.parse(localStorage.getItem("currentBrand"));
				var currBrandId = currBrand.brandId;
		      	// send a request to the server to get cashback details
		      	const response = await fetch('https://api.saltwin.com/loyalty-web-service/rest/v1/loyalty/program/' + currBrandId,
		      	{
			        method: 'GET',
			        headers: {
			          'Content-Type': 'application/json',
			          'Authorization': 'Bearer ' + localStorage.getItem('token')
			        }
		      	});
		      	const resJson = await response.json();
		      	CashbackRulesList(resJson);
		    } catch (err) {
		    	<ServerErrorModal flg={true} />
		    }
		    setLoaderVisible(false);
		}
		sd(); 
  	}	

  	const CashbackRulesList = (resJson) => {
  		if(resJson.success === true) {
      		var prgs = resJson.brands[0].cashbackPrograms;
  			var actPrgs = prgs.filter((p) => {return (p.active === true) });
  			var sortedPrgs = actPrgs.sort((s1, s2) => {return (s2.id - s1.id); });
  			var sortedActPrgs = sortedPrgs;

  			var inActPrgs = prgs.filter((p) => {return (p.active === false) });

  			inActPrgs.forEach((ip) => {
  				sortedPrgs = [...sortedPrgs, ip];
  			});

  			setCashbackRulesOnLoadData(sortedPrgs);
  			if(currentSelectedCBRule === null) {
      			setCashbackRulesData(sortedPrgs);
  			} else if(currentSelectedCBRule === 'Inactive') {
      			setCashbackRulesData(inActPrgs);
  			} else if(currentSelectedCBRule === 'Active') {
      			setCashbackRulesData(sortedActPrgs);
  			}

      	} else {
      		showPopupOnAPIError(resJson);
      	}
  	};

  	const showPopupOnAPIError = (resJson) => {
  		if('Unauthorized' === resJson.error) {
  			setDialogAPIErrorFlag(true);
  			setDialogAPIError(resJson.message);
  			setTimeout(() => {
			  navigate('/');
			}, 3000);
  		} else if(undefined !== resJson.error.message) {
  			setDialogAPIErrorFlag(true);
  			setDialogAPIError(resJson.error.message);
  		} else if(null !== resJson.error) {
  			setDialogAPIErrorFlag(true);
  			setDialogAPIError(resJson.error);
  		}
  	}

    const getSegmentOptionsToAddNewRule = () => {
        var opts = [];
        segmentsData.forEach((s) => {
            if(s.active && s.isCashbackRuleConfiguredForSegment === false) {
                opts.push(s);
            }
        });
        return opts;
    }

    const AddUpdateDeleteOps = async(method, cbRule) => {
		setLoaderVisible(true);
        try {
        	var currBrand = JSON.parse(localStorage.getItem("currentBrand"));
			var currBrandId = currBrand.brandId;

			const cbRuleReq = {
				brandId: currBrandId,
				programRequests: [cbRule]
			}

            const response = await fetch('https://api.saltwin.com/loyalty-web-service/rest/v1/loyalty/program/', {
                method: method,
                headers: {
                   'Content-Type': 'application/json',
			       'Authorization': 'Bearer ' + localStorage.getItem('token')
                },
                body: JSON.stringify(cbRuleReq)
              });
            const resJson = await response.json();
            CashbackRulesList(resJson);
            getSegmentsDetails();
        } catch (err) {
            <ServerErrorModal flg={true}  />
        }
        setLoaderVisible(false);
	}

	const AddEditCashbackRule = () => {
		const dialogCBRule = dialogCashbackRule;

		const [cbRule, setCbRule] = useState(dialogCBRule);
		const [cbRuleSegment, setCbRuleSegment] = useState(null);
		const [disableCbRuleSegmentDD, setDisableCbRuleSegmentDD] = useState(false);
		const [formErrors, setFormErrors] = useState({});
		const [segmentOptions, setSegmentOptions] = useState([]);


		if(null === cbRule.brandId || '' === cbRule.brandId) {
			var currBrand = JSON.parse(localStorage.getItem("currentBrand"));
			var currBrandId = currBrand.brandId;
			setCbRule({...cbRule, brandId: currBrandId });
		}

		const setSegmentOptionsAddNewRule = () => {
	  		const opts = getSegmentOptionsToAddNewRule();
	  		setSegmentOptions(opts);
	  	}

		const updatecashbackValue = (e) => {
			const t = e.target;
			const n = t.name;
			const v = t.value;
			const typ = t.type;

			if('checkbox' === typ) {
				setCbRule({...cbRule, [n]: !v });
			} else {
				setCbRule({...cbRule, [n]: v });
			}
		}

		const submitNewCashbackRule = () => {
			var isError = false;
			var errorsNewState = {};
			if('' === cbRule.name || null === cbRule.name) {
				errorsNewState = {...errorsNewState, errName: 'Cashback name should not be empty'}
				isError = true;
			}
			// Earning validations
			if('' === cbRule.earningType || null === cbRule.earningType) {
			    errorsNewState = {...errorsNewState, errEarningType: 'Choose cashback type'}
			    isError = true;
			} else if('' === cbRule.earningValue || null === cbRule.earningValue) {
			    var errMsg = 'Cashback amount should not be empty';
			    if('PERCENTAGE' === cbRule.earningType) {
			        errMsg = 'Cashback percent should not be empty';
			    }
			    errorsNewState = {...errorsNewState, errEarningValue: errMsg}
			    isError = true;
			} else {
			    if('' !== cbRule.earningValue && null !== cbRule.earningValue) {
			        if('PERCENTAGE' === cbRule.earningType 
			            && (cbRule.earningValue < 0 || cbRule.earningValue > 100)) {
			            errMsg = 'Cashback percent should be in between 1 - 100';
			        	errorsNewState = {...errorsNewState, errEarningValue: errMsg}
			            isError = true;
			        } else if('FIXED' === cbRule.earningValue 
			            && (cbRule.earningValue < 0)) {
			            errMsg = 'Cashback amount should not be less than 1';
			            errorsNewState = {...errorsNewState, errEarningValue: errMsg}
			            isError = true;
			        }
			    }
			        
			}
			if('' === cbRule.maxEarning || null === cbRule.maxEarning) {
			    errorsNewState = {...errorsNewState, errMaxEarning: 'Maximum cashback amount should not be empty'}
			    isError = true;
			} else if(cbRule.maxEarning < 0) {
			    errorsNewState = {...errorsNewState, errMaxEarning: 'Maximum cashback amount should not be less than 1'}
			    isError = true;
			}
			if(cbRule.minimumPurchaseRequiredEarningFlag) {
			    if('' === cbRule.minBillAmountForEarning || null === cbRule.minBillAmountForEarning) {
			        errorsNewState = {...errorsNewState, errMinBillAmountForEarning: 'Minimum bill amount should not be empty'}
			        isError = true;
			    } else if(cbRule.minBillAmountForEarning < 0) {
			        errorsNewState = {...errorsNewState, errMinBillAmountForEarning: 'Minimum bill amount should not be less than 1'}
			        isError = true;
			    }
			}

			// Redeem validations
			if('' === cbRule.discountType || null === cbRule.discountType) {
				errorsNewState = {...errorsNewState, errDiscountType: 'Choose redeem type'}
				isError = true;
			} else if('' === cbRule.discountValue || null === cbRule.discountValue) {
				var errMsg = 'Redeem amount should not be empty';
				if('PERCENTAGE' === cbRule.discountType) {
					errMsg = 'Redeem percent should not be empty';
				}
				errorsNewState = {...errorsNewState, errDiscountValue: errMsg}
				isError = true;
			} else {
				if('' !== cbRule.discountValue && null !== cbRule.discountValue) {
                    if('PERCENTAGE' === cbRule.discountType 
                        && (cbRule.discountValue < 0 || cbRule.discountValue > 100)) {
                        errMsg = 'Redeem percent should be in between 1 - 100';
                    	errorsNewState = {...errorsNewState, errDiscountValue: errMsg}
                        isError = true;
                    } else if('FIXED' === cbRule.discountType 
                        && (cbRule.discountValue < 0)) {
                        errMsg = 'Redeem amount should not be less than 1';
                        errorsNewState = {...errorsNewState, errDiscountValue: errMsg}
                        isError = true;
                    }
                }
                    
			}
			if('' === cbRule.maxDiscount || null === cbRule.maxDiscount) {
				errorsNewState = {...errorsNewState, errMaxDiscount: 'Maximum redeem amount should not be empty'}
				isError = true;
			} else if(cbRule.maxDiscount < 0) {
				errorsNewState = {...errorsNewState, errMaxDiscount: 'Maximum redeem amount should not be less than 1'}
				isError = true;
			}
			if(cbRule.minimumPurchaseRequiredFlag) {
				if('' === cbRule.minBillAmountForReward || null === cbRule.minBillAmountForReward) {
					errorsNewState = {...errorsNewState, errMinBillAmountForReward: 'Minimum bill amount should not be empty'}
					isError = true;
				} else if(cbRule.minBillAmountForReward < 0) {
					errorsNewState = {...errorsNewState, errMinBillAmountForReward: 'Minimum bill amount should not be less than 1'}
					isError = true;
				}
			}
			if('' === cbRule.startDate || null === cbRule.startDate) {
				errorsNewState = {...errorsNewState, errStartDate: 'Choose start date'}
				isError = true;
			}
			if('' === cbRule.endDate || null === cbRule.endDate) {
				errorsNewState = {...errorsNewState, errEndDate: 'Choose end date'}
				isError = true;
			}

			if(('' !== cbRule.startDate && null !== cbRule.startDate)
				&& ('' !== cbRule.endDate && null !== cbRule.endDate)
				&& (cbRule.startDate > cbRule.endDate)) {
				errorsNewState = {...errorsNewState, errStartDate: 'Start date should not be greater than End date'}
				isError = true;
			}
			
			if('' === cbRule.segment || null === cbRule.segment) {
				errorsNewState = {...errorsNewState, errSegment: 'Choose segment'}
				isError = true;
			}

			setFormErrors(errorsNewState);

			if(!isError) {
				var httpMethod = 'POST';
				if('' === cbRule.id || null === cbRule.id) {
					httpMethod = 'POST';
				}
				AddUpdateDeleteOps(httpMethod, cbRule);
				setDialogVisible(false);
			}
		}

		const ShowError = ({err}) => {
			if(null === err) {
				return <></>;
			} else {
				return <span className="text-xs text-red-500">{err}</span>;
			}
		}

		var cashbackAmtLbl = 'Redeem Amount';
		if(cbRule.discountType === 'PERCENTAGE') {
			cashbackAmtLbl = 'Redeem in Percentage';
		}

		var earningAmtLbl = 'Cashback Amount';
		if(cbRule.earningType === 'PERCENTAGE') {
			earningAmtLbl = 'Cashback in Percentage';
		}

		return(	
			<Dialog header={(cbRule.id === null || cbRule.id === '') ? 'Add New Cashback Rule' : 'Edit  Cashback Rule'} className="text-lg" 
				visible={dialogVisible} style={{ width: '50vw' }} 
				onHide={() => setDialogVisible(false)}
				onShow={() => {
					setDisableCbRuleSegmentDD(false);
					if(null !== cbRule.segment) {
						var _cbSeg;
						segmentsData.forEach((s) => {
							if(s.id === cbRule.segment.id) {
								_cbSeg = s;
							}
						});
						setCbRuleSegment(_cbSeg);
						setDisableCbRuleSegmentDD(true);
					}
				}} >

				<div className="grid">
				    <div className="col-6">
				        <div className="flex flex-column gap-2">
			                <label htmlFor="segment_name">Segment</label>
			                <Dropdown id="segment" name="segment" value={(cbRule.id === null || cbRule.id === '') ? cbRule.segment : cbRuleSegment } 
			                	onChange={(e) => updatecashbackValue(e)} options={segmentsData} optionLabel="name" 
    							disabled={disableCbRuleSegmentDD} 
    							placeholder="Select Segment" className="" />
    						<ShowError err={formErrors.errSegment} />
			            </div>
				    </div>
				    <div className="col-6">
				        <div className="flex flex-column gap-2">
			                <label htmlFor="cashback_name">Name</label>
			                <InputText flag="cashback_name" id="name" name="name" value={cbRule.name} onChange={(e) => updatecashbackValue(e)} />
			                <ShowError err={formErrors.errName} />
			            </div>
				    </div>
				    <div className="col-12 border-top-1 border-gray-300"></div>
				    <div className="col-12">
				    	<div className="grid border-1 border-gray-300 border-round-sm">
				    		<div className="col-12 py-2 text-lg font-semibold border-bottom-1 border-gray-300">Earning Rule</div>
				    		<div className="col-6">
						    	<div className="flex flex-column gap-2">
					                <label htmlFor="earn_type">Cashback Type</label>
					                <div className="flex flex-wrap gap-3">
									    <div className="flex align-items-center">
									        <RadioButton inputId="earnTypFixed" name="earningType" value="FIXED" onChange={(e) => updatecashbackValue(e)} checked={cbRule.earningType === 'FIXED'} />
									        <label htmlFor="earnTypFixed" className="ml-2">FIXED ($)</label>
									    </div>
									    <div className="flex align-items-center">
									        <RadioButton inputId="earnTypPercent" name="earningType" value="PERCENTAGE" onChange={(e) => updatecashbackValue(e)} checked={cbRule.earningType === 'PERCENTAGE'} />
									        <label htmlFor="earnTypPercent" className="ml-2">Percentage (%)</label>
									    </div>
									</div>
									<ShowError err={formErrors.errEarningType} />
					            </div>
						    </div>
						    <div className="col-6">
						        
						    </div>
						    <div className="col-6">
						        <div className="flex flex-column gap-2">
					                <label htmlFor="earning_value">{earningAmtLbl}</label>
					                <InputText flag="earning_value" id="earningValue" name="earningValue" keyfilter="int" value={cbRule.earningValue} onChange={(e) => updatecashbackValue(e)} />
					                <ShowError err={formErrors.errEarningValue} />
					            </div>
						    </div>
						    <div className="col-6">
						        <div className="flex flex-column gap-2">
					                <label htmlFor="maximum_earning_value">Maximum Cashback Amount</label>
					                <InputText flag="maximum_earning_value" id="maxEarning" name="maxEarning" keyfilter="int" value={cbRule.maxEarning} onChange={(e) => updatecashbackValue(e)} />
					                <ShowError err={formErrors.errMaxEarning} />
					            </div>
						    </div>
						    <div className="col-6">
						        <div className="flex flex-column gap-2">
					                <label htmlFor="minimum_purchase_required">Minimum Purchase Required</label>
					                <div className="flex align-items-center">
								        <Checkbox inputId="minimumPurchaseRequiredEarningFlag" name="minimumPurchaseRequiredEarningFlag" value={cbRule.minimumPurchaseRequiredEarningFlag} onChange={(e) => updatecashbackValue(e)} checked={cbRule.minimumPurchaseRequiredEarningFlag} />
								        <label htmlFor="minimumPurchaseRequiredEarningFlag" className="ml-2"></label>
								    </div>
					            </div>
						    </div>
						    <div className="col-6">
						        <div className="flex flex-column gap-2">
					                <label htmlFor="minimum_purchase_value_earning">Minimum Purchase Amount</label>
					                <InputText flag="minimum_purchase_value_earning" id="minBillAmountForEarning" name="minBillAmountForEarning" keyfilter="int" value={cbRule.minBillAmountForEarning} onChange={(e) => updatecashbackValue(e)} />
					                <ShowError err={formErrors.errMinBillAmountForEarning} />
					            </div>
						    </div>
				    	</div>
				    </div>
				    <div className="col-12">
				    	<div className="grid border-1 border-gray-300 border-round-sm">
				    		<div className="col-12 py-2 text-lg font-semibold border-bottom-1 border-gray-300">Redeem Rule</div>
				    		<div className="col-6">
						    	<div className="flex flex-column gap-2">
					                <label htmlFor="cashback_type">Redeem Type</label>
					                <div className="flex flex-wrap gap-3">
									    <div className="flex align-items-center">
									        <RadioButton inputId="chsbkTypFixed" name="discountType" value="FIXED" onChange={(e) => updatecashbackValue(e)} checked={cbRule.discountType === 'FIXED'} />
									        <label htmlFor="chsbkTypFixed" className="ml-2">FIXED ($)</label>
									    </div>
									    <div className="flex align-items-center">
									        <RadioButton inputId="chsbkTypPercent" name="discountType" value="PERCENTAGE" onChange={(e) => updatecashbackValue(e)} checked={cbRule.discountType === 'PERCENTAGE'} />
									        <label htmlFor="chsbkTypPercent" className="ml-2">Percentage (%)</label>
									    </div>
									</div>
									<ShowError err={formErrors.errDiscountType} />
					            </div>
						    </div>
						    <div className="col-6">
						        
						    </div>
						    <div className="col-6">
						        <div className="flex flex-column gap-2">
					                <label htmlFor="cashback_value">{cashbackAmtLbl}</label>
					                <InputText flag="cashback_value" id="discountValue" name="discountValue" keyfilter="int" value={cbRule.discountValue} onChange={(e) => updatecashbackValue(e)} />
					                <ShowError err={formErrors.errDiscountValue} />
					            </div>
						    </div>
						    <div className="col-6">
						        <div className="flex flex-column gap-2">
					                <label htmlFor="maximum_cashback_value">Maximum Redeemtion Allowed</label>
					                <InputText flag="maximum_cashback_value" id="maxDiscount" name="maxDiscount" keyfilter="int" value={cbRule.maxDiscount} onChange={(e) => updatecashbackValue(e)} />
					                <ShowError err={formErrors.errMaxDiscount} />
					            </div>
						    </div>
						    <div className="col-6">
						        <div className="flex flex-column gap-2">
					                <label htmlFor="minimum_purchase_required">Minimum Purchase Required</label>
					                <div className="flex align-items-center">
								        <Checkbox inputId="minimumPurchaseRequiredFlag" name="minimumPurchaseRequiredFlag" value={cbRule.minimumPurchaseRequiredFlag} onChange={(e) => updatecashbackValue(e)} checked={cbRule.minimumPurchaseRequiredFlag} />
								        <label htmlFor="minimumPurchaseRequiredFlag" className="ml-2"></label>
								    </div>
					            </div>
						    </div>
						    <div className="col-6">
						        <div className="flex flex-column gap-2">
					                <label htmlFor="minimum_purchase_value">Minimum Purchase Amount</label>
					                <InputText flag="minimum_purchase_value" id="minBillAmountForReward" name="minBillAmountForReward" keyfilter="int" value={cbRule.minBillAmountForReward} onChange={(e) => updatecashbackValue(e)} />
					                <ShowError err={formErrors.errMinBillAmountForReward} />
					            </div>
						    </div>
				    	</div>
				    </div>
				    <div className="col-12 mt-2 border-top-1 border-gray-300"></div>
				    <div className="col-6">
				        <div className="flex flex-column gap-2">
			                <label htmlFor="cbrule_start_date">Start Date</label>
			                <Calendar value={cbRule.startDate === null ? '' : new Date(cbRule.startDate)} onChange={(e) => updatecashbackValue(e)} showIcon
					        	minDate={new Date()}
					        	className="mr-3 border-yellow-500 text-lg border-round-sm"
					        	name="startDate"
					        	dateFormat="M d, yy" />
					        <ShowError err={formErrors.errStartDate} />
			            </div>
				    </div>
				    <div className="col-6">
				        <div className="flex flex-column gap-2">
			                <label htmlFor="cbrule_end_date">End Date</label>
			                <Calendar value={cbRule.endDate === null ? '' : new Date(cbRule.endDate)}  onChange={(e) => updatecashbackValue(e)} showIcon
					        	minDate={new Date()}
					        	className="mr-2 border-yellow-500 text-lg border-round-sm" 
					        	name="endDate"
					        	dateFormat="M d, yy" />
					        <ShowError err={formErrors.errEndDate} />
			            </div>
				    </div>
				    <div className="col-6">
				        <div className="flex flex-column gap-2">
			                <label htmlFor="cbrule_active_flg">Active</label>
			                <div className="flex align-items-center">
						        <Checkbox inputId="cbrule_active_flg" name="active" value={cbRule.active} onChange={(e) => updatecashbackValue(e)} checked={cbRule.active} />
						    </div>
			            </div>
				    </div>
				    <div className="col-6">
				    	<div className="flex flex-wrap justify-content-end gap-2">
					        <Button icon={FaXmark} rounded severity="danger" aria-label="Cancel"
					        	onClick={() => setDialogVisible(false)} >&nbsp;Cancel</Button>
					        <Button icon={FaCheck} rounded severity="success" aria-label="Submit"
					        	onClick={() => submitNewCashbackRule()} >&nbsp;Submit</Button>
				        </div>
				    </div>
				</div>
		    </Dialog>
		);
	}

	const cashbackRulesListTemplate = (items) => {
		if (!items || items.length === 0) return null;

	    let list = items.map((cashbackRule, index) => {
	        return cashbackRuleItemTemplate(cashbackRule, index);
	    });

	    return <div className="grid grid-nogutter">{list}</div>;
	}

	const onChangeSelectCBRuleButtonValue = (btnVal) => {
		setSelectCBRuleButtonValue(btnVal);
		setCurrentSelectedCBRule(btnVal);
		if(null === btnVal || "All" === btnVal) {
			setCashbackRulesData(cashbackRulesOnLoadData);
		} else if(btnVal.includes("Active")) {
			const newState = cashbackRulesOnLoadData.filter((s) => (s.active === true));
			setCashbackRulesData(newState);
		} else if(btnVal.includes("Inactive")) {
			const newState = cashbackRulesOnLoadData.filter((s) => (s.active === false));
			setCashbackRulesData(newState);
		}	
		
	}

	const cashbackRuleItemTemplate = (cashbackRule, index) => {
		const cshBkId = cashbackRule.id;

		var cashbackAmtLbl = '';
		var earningType = cashbackRule.earningType;
		if('PERCENTAGE' === earningType) {
			earningType = earningType + ' (%)';
			cashbackAmtLbl = 'Cashback Percent';
		} else if('FIXED' === earningType) {
			earningType = earningType + ' ($)';
			cashbackAmtLbl = 'Cashback Amount';
		}

		var redeemAmtLbl = '';
		var discountType = cashbackRule.discountType;
		if('PERCENTAGE' === discountType) {
			discountType = discountType + ' (%)';
			redeemAmtLbl = 'Redeem Percent';
		} else if('FIXED' === discountType) {
			discountType = discountType + ' ($)';
			redeemAmtLbl = 'Redeem Amount';
		}



		var d = new Date(cashbackRule.startDate);
		const validFrom =  dateFormat(d, 'mmm dd, yyyy');

		d = new Date(cashbackRule.endDate);
		const validTo =  dateFormat(d, 'mmm dd, yyyy');


		const accept = () => {
			AddUpdateDeleteOps('DELETE',deleteCashbackRule);
		}

		const reject = () => {
			setDeleteCashbackRule(null);
		}

		const deleteCashbackRulePopup = (event, cashbackRule) => {
	        confirmPopup({
	            target: event.currentTarget,
	            message: 'Do you want to delete this cashback rule?',
	            icon: 'pi pi-info-circle',
	            defaultFocus: 'reject',
	            acceptClassName: 'p-button-danger',
	            accept,
	            reject
	        });
	        setDeleteCashbackRule(cashbackRule);
	    };

	    const classRowColumn = "text-left px-2 py-1";
	    const classRowColumnLbl = "font-light text-gray-600 mb-1";
	    const classRowColumnVal = "capitalize";
	    const classSecRuleLbl = "col-12 text-center font-medium text-900 text-lg border-bottom-1 border-300";

		return (
	    		<div className="col-12" key={cshBkId}>
	                <div className="col-12 p-2 my-2 border-round-md border-1 border-yellow-300"  >
		                <div className="flex align-items-center gap-2 pb-2 mb-2 border-bottom-1 border-300">
		                	<div className="flex-1">
		                		<p className="p-0 m-0 text-xl mb-2 font-medium text-900 capitalize">{cashbackRule.name}</p>
		                		<p className="p-0 m-0 text mb-2 font-medium text-700">(Segment - {cashbackRule.segment.name})</p>
		                	</div>
	                    	<div className="flex justify-content-end">
							    <Button icon={FaPencil} rounded text raised severity="secondary" aria-label="Edit Segment"
	                            	 className="bg-white cursor-pointer mx-1" onClick={(e) => {setDialogCashbackRule(cashbackRule); setDialogVisible(true)}} />
		                    	<Button icon={FaTrashCan} rounded text raised severity="secondary" aria-label="Delete Segment"
		                    		 className="bg-white cursor-pointer mx-1 hidden" onClick={(e) => deleteCashbackRulePopup(e, cashbackRule)} />
							</div>
	                    </div>
	                    <div className="grid p-2 pb-0">
	                    	
	                    	<div className="col-12 py-0">
						        <div className="grid">	
						        	<div className="col-6 p-0 border-right-1 border-300">
						        		<div className={classSecRuleLbl}>Earn Rule</div>
								        <div className="grid">	
								        	<div className="col-4">
										        <div className={classRowColumn}>
										        	<div className={classRowColumnLbl}>Cashback Type</div>
										        	<div className={classRowColumnVal}>{earningType}</div>
										        </div>
										    </div>
										    <div className="col-4">
										        <div className={classRowColumn}>
										        	<div className={classRowColumnLbl}>{cashbackAmtLbl}</div>
										        	<div className={classRowColumnVal}>{cashbackRule.earningValue}</div>
										        </div>
										    </div>
										    <div className="col-4">
										        <div className={classRowColumn}>
										        	<div className={classRowColumnLbl}>Maximum Cashback Amount</div>
										        	<div className={classRowColumnVal}>{cashbackRule.maxEarning}</div>
										        </div>
										    </div>
										    <div className="col-4">
										        <div className={classRowColumn}>
										        	<div className={classRowColumnLbl}>Minimum Purchase Required</div>
										        	<div className={classRowColumnVal}>{cashbackRule.minimumPurchaseRequiredEarningFlag === true ? 'Yes' : 'No'}</div>
										        </div>
										    </div>
										    <div className="col-4">
										        <div className={classRowColumn}>
										        	<div className={classRowColumnLbl}>Minimum Purchase Amount</div>
										        	<div className={classRowColumnVal}>{cashbackRule.minBillAmountForEarning}</div>
										        </div>
										    </div>
								        </div>
								    </div>
								    <div className="col-6 p-0">
								    	<div className={classSecRuleLbl}>Redeem Rule</div>
								        <div className="grid">	
								        	<div className="col-4">
										        <div className={classRowColumn}>
										        	<div className={classRowColumnLbl}>Redeem Type</div>
										        	<div className={classRowColumnVal}>{discountType}</div>
										        </div>
										    </div>
										    <div className="col-4">
										        <div className={classRowColumn}>
										        	<div className={classRowColumnLbl}>{redeemAmtLbl}</div>
										        	<div className={classRowColumnVal}>{cashbackRule.discountValue}</div>
										        </div>
										    </div>
										    <div className="col-4">
										        <div className={classRowColumn}>
										        	<div className={classRowColumnLbl}>Maximum Redeemtion Allowed</div>
										        	<div className={classRowColumnVal}>{cashbackRule.maxDiscount}</div>
										        </div>
										    </div>
										    <div className="col-4">
										        <div className={classRowColumn}>
										        	<div className={classRowColumnLbl}>Minimum Purchase Required</div>
										        	<div className={classRowColumnVal}>{cashbackRule.minimumPurchaseRequiredFlag === true ? 'Yes' : 'No'}</div>
										        </div>
										    </div>
										    <div className="col-4">
										        <div className={classRowColumn}>
										        	<div className={classRowColumnLbl}>Minimum Purchase Amount</div>
										        	<div className={classRowColumnVal}>{cashbackRule.minBillAmountForReward}</div>
										        </div>
										    </div>
								        </div>
								    </div>
						        </div>
						    </div>
						
						    <div className="col-12 pb-0 border-top-1 border-300">
						        <div className="grid pb-0">	
						        	<div className="col-3">
								        <div className={classRowColumn}>
								        	<div className={classRowColumnLbl}>Status</div>
								        	<div className={classRowColumnVal}>{cashbackRule.active === true ? 'Active' : 'Inactive'}</div>
								        </div>
								    </div>
								    <div className="col-3">
								        <div className={classRowColumn}>
								        	<div className={classRowColumnLbl}>Valid From</div>
								        	<div className={classRowColumnVal}>{validFrom}</div>
								        </div>
								    </div>
								    <div className="col-3">
								        <div className={classRowColumn}>
								        	<div className={classRowColumnLbl}>Valid To</div>
								        	<div className={classRowColumnVal}>{validTo}</div>
								        </div>
								    </div>
						        </div>
						    </div>
						</div>
	                </div>
	                <ConfirmPopup />
	            </div>
	        );
	}

	const ConfigCashbackRules = () => {
		return (<div className="grid px-3">
				<AddEditCashbackRule />
			    <div className="col-12">
			    	<div className="flex">
					    <div className="flex-1 flex justify-content-start align-items-center flex-wrap">
				    		<div className="text-2xl font-medium">Cashback Rules</div>
					    	<Avatar icon="pi pi-refresh" className="cursor-pointer"
					    		style={{ backgroundColor: '#fff', color: '#000'}} shape="circle"
					    		onClick ={(e) => getCashbackRules()} />
				    	</div>
				    	<div className="flex-1 flex justify-content-end flex-wrap">
				    		<Button icon={FaPlus} rounded text raised severity="secondary" aria-label="Add Segment"
				    			onClick={(e) => {
				    								if(checkBrandsAvailable() && checkSegmentsToAddNewRule()) {
				    									setDialogCashbackRule(newCashbackRule); 
				    									setDialogVisible(true);
				    								}
				    							}
				    					}>&nbsp;Add New</Button>
				    	</div>
					</div>
					<div className="flex">
					    <div className="flex-1 flex justify-content-start align-items-center flex-wrap">
				    		<SelectButton value={selectCBRuleButtonValue} onChange={(e) => onChangeSelectCBRuleButtonValue(e.value)} options={selectCBRuleButtonOptions} />
				    	</div>
					</div>
			        <div className="flex">
						<div className="flex flex-column overflow-auto" style={{ width: ConstantValues.contentViewWidth, height: ConstantValues.contentViewHeight }}>
							<DataView value={cashbackRulesData} listTemplate={cashbackRulesListTemplate} />
					   </div>
					</div>
			    </div>
			</div>);
	}

	return (<>
		<div className="relative p-0 m-0">
		    <div className="relative text-gray-800 w-screen h-screen">
        		<NavigationBarVertical />
		        <div className="absolute top-0 left-0 h-4rem px-2 border-200 border-bottom-1" style={{ width: ConstantValues.hNavBarWidth, height: '95%', marginLeft: ConstantValues.vNavBarWidth }}>
		        	<NavigationBarHorizontal hideLinkOf='ManageCustomerSegments' />
					<div id="show-saltwin-content" className="absolute top-4rem left-0 px-2 py-4" style={{ width: '100%', height: '95%' }}>
						<div id="cust-segments" className="py-2 relative">
							<div className="grid overflow-hidden" style={{height: '86vh' }}>
							    <div className="col-12">
							        <ConfigCashbackRules />
							    </div>
							</div>
						</div>
					</div>
        		</div>
        		<Footer />
		    </div>
		</div>
		<ProgressLoader loaderVisible={loaderVisible} />
        <Dialog header="Error" headerClassName="text-red-500" visible={dialogAPIErrorFlag} style={{ width: '50vw' }} onHide={() => setDialogAPIErrorFlag(false)}>
	        <div className="m-0 text-lg font-medium mb-2 text-gray-800">
	            <div className="font-light">{dialogAPIError}</div>
	        </div>
	    </Dialog>
	    <Dialog header="Info" visible={dialogAPIInfoFlag} style={{ width: '50vw' }} onHide={() => setDialogAPIInfoFlag(false)}>
            <div className="m-0 text-lg font-medium mb-2 text-gray-800">
                <div className="font-light">{dialogAPIInfoMessage}</div>
            </div>
        </Dialog>
		</>);

}

const newCashbackRule = {
	"id": null,
    "minimumPurchaseRequiredFlag": false,
    "outletId": null,
    "brandId": null,
    "name": null,
    "rewardCode": null,
    "rewardType": 'CASHBACK',
    "discountOn": 'BILL',
    "discountType": null, //'PERCENTAGE',
    "discountValue": null,
    "pointMoneyRatio": 1,
    "maxDiscount": null,
    "minBillAmountForReward": null,
    "minimumPurchaseRequiredEarningFlag": false,
    "earningType": null, //'PERCENTAGE',
    "earningOn": 'BILL',
    "earningValue": null,
    "maxEarning": null,
    "minBillAmountForEarning": null,
    "products": null,
    "numOfProductDiscoutAppliedOn": null,
    "segment": null,
    "startDate": null,
    "endDate": null,
    "active": true,
    "promotion": false
}

export default ManageCashbackRules;