import React, { useState, useEffect }  from 'react';
import { useTimeout } from 'primereact/hooks';
import {NavigationBarVertical, NavigationBarHorizontal, Footer, ProgressLoader} from './NavigationBar';
import { Calendar } from 'primereact/calendar';
import { Chart } from 'primereact/chart';
import { Paginator } from 'primereact/paginator';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import {Link} from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import dateFormat from 'dateformat';
import { Avatar } from 'primereact/avatar';
import { ProgressSpinner } from 'primereact/progressspinner';
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { InputText } from "primereact/inputtext";

import { ServerErrorModal, ConstantValues} from '../Utility';


const Dashboard = () => {
	const [topCustomersNStatistics, setTopCustomersNStatistics] = useState(loyalCustomersNStats);
	const [topCustomersNStatisticsOri, setTopCustomersNStatisticsOri] = useState(loyalCustomersNStats);
	const [segmentInfo, setSegmentInfo] = useState({});
	const [dialogVisible, setDialogVisible] = useState(false);
	const [transactions, setTransactions] = useState({});
	const [first, setFirst] = useState(0);
	const [loaderVisible, setLoaderVisible] = useState(false);
	const [showChooseBrand, setShowChooseBrand] = useState(false);

	var filterEndDate = new Date();
	var d = new Date();
	var filterStartDate = new Date(d.setDate(d.getDate() - 91));

	const [filterDates, setFilterDates] = useState([filterStartDate, filterEndDate]);
	const [prevFilterDates, setPrevFilterDates] = useState(filterDates);

	const [brands, setBrands] = useState(null);
	const [brandChosen, setBrandChosen] = useState(null);

    const [dialogAPIInfoFlag, setDialogAPIInfoFlag] = useState(false);
    const [dialogAPIInfoMessage, setDialogAPIInfoMessage] = useState(null);

    const [dialogAPIErrorFlag, setDialogAPIErrorFlag] = useState(false);
	const [dialogAPIError, setDialogAPIError] = useState(null);

	const navigate = useNavigate();

	useEffect(() => {
		if('' === localStorage.getItem('token') ||
				localStorage.getItem('token').length === 0) navigate('/'); 
		var brands = JSON.parse(localStorage.getItem("brands"));
		setBrands(brands);
		localStorage.setItem('currentPage', 'dashboard');
		if(brands.length === 0) {
            setDialogAPIInfoMessage('Please configure your brand(s) or contact system administrator');
            setDialogAPIInfoFlag(true);
        } else {
        	if(brands.length === 1) {
			//setBrandChosenAndGetDetails(brands[0]);
			} else {
				//showChooseBrandDialog();
			}
			setBrandChosenAndGetDetails(brands[0]);
        }
	}, []);

	const showChooseBrandDialog = () => {
		setShowChooseBrand(true);
	}

	const setBrandChosenAndGetDetails = (brand) => {
		setShowChooseBrand(false);
		setBrandChosen(brand);
		localStorage.setItem('currentBrand', JSON.stringify(brand));
		getStatsDetails();
	}

	const getStatsDetails = () => {
		if(null === filterDates[0]
			|| null === filterDates[1]) {
			setFilterDates(prevFilterDates);
		} else {
			const sd = async() => {
				setLoaderVisible(true);
				try {
					var currBrand = JSON.parse(localStorage.getItem("currentBrand"));
					var currBrandId = currBrand.id;
			      	// send a request to the server to get dashboard details
			      	const response = await fetch('https://api.saltwin.com/loyalty-web-service/rest/v1/loyalty/dashboard/?' + new URLSearchParams({
					    startDate: dateFormat(filterDates[0], 'mm/dd/yyyy'),
					    endDate: dateFormat(filterDates[1], 'mm/dd/yyyy')
					}),
			      	{
				        method: 'GET',
				        headers: {
				          'Content-Type': 'application/json',
				          'Authorization': 'Bearer ' + localStorage.getItem('token')
				        }
			      	});
			      	const resJson = await response.json();
			      	CustomersList(resJson);
			      	setPrevFilterDates(filterDates);
			    } catch (err) {
			    	<ServerErrorModal />
			    }
			    setLoaderVisible(false);
			}
			sd();
		}   
  }

  const CustomersList = (resJson) => {
  	if(resJson.success === true) {
  		var custList = [];
	  	resJson.brands.map((b) => {
	  		b.customers.map((c) => {
	  			custList.push(c);
	  		});
	  	});

	  	const custTblHeaders = [
								//{field: 'uuid', header: ''},
								//{field: 'email', header: ''},
						        {field: 'phoneNumber', header: 'Phone', align: 'left', isSortable: 'true'},
						        {field: 'name', header: 'Name', align: 'left', isSortable: 'true'},
						        {field: 'dateOfJoining', header: 'Member Since', align: 'left', isSortable: 'true'},
						        {field: 'segment.name', header: 'Segment', align: 'left', isSortable: 'true'},
						        {field: 'pointBalance', header: 'Balance', align: 'right', isSortable: 'true'},
						        {field: 'totalPointsEarn', header: 'Earnings', align: 'right', isSortable: 'true'},
						        {field: 'totalRedeemption', header: 'Redemptions', align: 'right', isSortable: 'true'},
						        {field: 'dob', header: '', align: 'left', isSortable: 'true'}
			];


	  	const segmentDetails = [
	  			{name: 'BASIC', color: 'orange'},
	  			{name: 'SILVER', color: 'gray'},
	  			{name: 'GOLD', color: 'yellow'},
	  			{name: 'PLATINUM', color: 'purple'}
	  		];

	  	setSegmentInfo(segmentDetails);

	  	const loyalityStats = [
						{id: 'new-customers', name: 'new customers', count: 164, currencyType: '', percentage: '+15%', progressType: 'up', bgColor: 'FloralWhite', graphStats: [25, 45, 40, 34, 41] },
						{id: 'repeat-customers', name: 'repeat customers', count: 159, currencyType: '', percentage: '+32%', progressType: 'up', bgColor: 'AliceBlue', graphStats: [1, 4, 6, 0,12] },
						{id: 'redemptions-processed', name: 'redemptions processed', count: 559, currencyType: '$', percentage: '+54%', progressType: 'down', bgColor: 'LavenderBlush', graphStats: [25, 45, 40, 34, 41] },
						{id: 'customer-earnings', name: 'customer earnings', count: 589, currencyType: '$', percentage: '+24%', progressType: 'down', bgColor: 'OldLace', graphStats: [1, 4, 6, 0,12] }
					];

	  	const details = {
			loyalityStats: loyalityStats,
			topEarningCustomers: custList,
			topEarningCustomersHeaders: custTblHeaders
		};
		setTopCustomersNStatistics(details);
		setTopCustomersNStatisticsOri(details);
  	} else {
      		if('Unauthorized' === resJson.error) {
      			setDialogAPIErrorFlag(true);
      			setDialogAPIError(resJson.message);
      			setTimeout(() => {
				  navigate('/');
				}, 3000);
      		}  else if(undefined !== resJson.error.message) {
	  			setDialogAPIErrorFlag(true);
	  			setDialogAPIError(resJson.error.message);
	  		} else if(null !== resJson.error) {
	  			setDialogAPIErrorFlag(true);
	  			setDialogAPIError(resJson.error);
	  		}
      	}
  }

  const getTransactionsJson = async (uuid) => {
  		setLoaderVisible(true);
	    try {
	      // send a request to the server to get dashboard details
	      const response = await fetch('https://api.saltwin.com//loyalty-web-service/rest/v1/loyalty/dashboard/' + uuid + '/transactions', {
	        method: 'GET',
	        headers: {
	          'Content-Type': 'application/json',
	          'Authorization': 'Bearer ' + localStorage.getItem('token')
	        }
	      });
	      const resJson = await response.json();
	      setTransactions(resJson);
	    } catch (err) {
	    	<ServerErrorModal />
	    }
	    setLoaderVisible(false);
  }

    const onPageChange = (event) => {
        setFirst(event.first);
    };

    const [sortBy, setSortBy] = useState(null);
    const sortOpt = [
        { name: 'New York', code: 'NY' },
        { name: 'Rome', code: 'RM' },
        { name: 'London', code: 'LDN' },
        { name: 'Istanbul', code: 'IST' },
        { name: 'Paris', code: 'PRS' }
    ];

	const loyalityStats = topCustomersNStatistics.loyalityStats;
	const topEarningCustomers = topCustomersNStatistics.topEarningCustomers;
	const topEarningCustomersHeaders = topCustomersNStatistics.topEarningCustomersHeaders;

	const NewCustomerCount = ({customers}) => {
		var c = 0;
		customers.map((cust) => {
			if(cust.isNew) { 
				c += 1;
			}
		});
		return <>{c}</>;
	}

	const formatCurrency = (value) => {
    return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
};

const balanceBodyTemplate = (topEarningCustomer) => {
    return formatCurrency(topEarningCustomer.pointBalance);
};

const newCustomerBodyTemplate = (topEarningCustomer) => {
	if(null === topEarningCustomer.isNew || false === topEarningCustomer.isNew) {
		return <></>;
	} else if(topEarningCustomer.isNew) {
		return <span className="text-lg text-white bg-blue-400 px-2 py-1 border-round-3xl">New</span>;
	}
};

const segmentBodyTemplate = (topEarningCustomer) => {
	var segInfo = null;
	segmentInfo.map((seg) => {
		if(topEarningCustomer.tier === seg.name) {
			segInfo = seg;
		}
	});

	if(null === segInfo) {
		return <span className="text-lg text-white-800 bg-gray-100 px-2 py-1 border-round-3xl">N/A</span>;
	} else {
		const txtColor = 'text-' + segInfo.color + '-800';
		const bgColor = 'bg-' + segInfo.color + '-100';
		const segName = segInfo.name.toLowerCase();
		const clsName = txtColor + ' ' + bgColor + ' text-lg capitalize px-2 py-1 border-round-3xl';
		return <span className={clsName}>{segName}</span>;
	}
};

const phoneBodyTemplate = (topEarningCustomer) => { 
	return <>{topEarningCustomer.countryCode} {topEarningCustomer.phoneNumber}</>;
};

const memberSinceBodyTemplate = (topEarningCustomer) => { 
	const d = new Date(topEarningCustomer.dateOfJoining);
	return dateFormat(d, 'mmm dd, yyyy');
};

const detailsBodyTemplate = (topEarningCustomer) => {
	
    return <span className="text-lg text-yellow-600 px-2 py-1 border-1 border-round-sm border-yellow-600 cursor-pointer"
    		onClick={() => setFlagDetailsDialog(topEarningCustomer.uuid)}>Details</span>;
};

const setFlagDetailsDialog = (uuid) => {
	getTransactionsJson(uuid);
	setDialogVisible(true);
}

const transactionDateTimeBodyTemplate = (transaction) => { 
	const transDate = transaction.transactionTime;
	const splitedDate = transDate.split(".");
	const d = Date.parse(splitedDate[0]);
	return dateFormat(d, 'mmm dd, yyyy hh:MM TT');
};

const StatsFilterInfo = ({topCustNStats}) => {

	const content = topCustNStats.map((stat) => {
		const bgColor = stat.bgColor;
		var percentColor = null;
		var gbColor = null;
      	var gbgColor = null;
		if(stat.progressType === 'up') {
			percentColor = 'LimeGreen';
			gbColor = '#2BA615';
      		gbgColor = '#D3FCD8';
		} else {
			percentColor = 'MediumVioletRed';
			gbColor = '#FF6384';
      		gbgColor = '#FFDFE6';
		}
		const currType = stat.currencyType === null ? '' : stat.currencyType + ' ';
		const graphStats = stat.graphStats;
		return <div key={stat.id} className="col p-3 mx-2 mb-2 border-round" style={{backgroundColor: bgColor}}>
					<div key={stat.id} className="grid">
					    <div className="col">
					        <p className="p-0 m-0 text-lg font-normal capitalize h-2rem w-8">{stat.name}</p>
			                <p className="count">{currType}{stat.count}</p>
			                <p className="font-light mb-0 pb-0" style={{fontSize: '.8rem'}}>
			                    <span style={{color: percentColor}}>{stat.percentage}</span><span> from last week</span>
			                </p>
					    </div>
					    <div className="col align-items-center justify-content-end align-content-end">
					        <StatGraph graphStats={graphStats} bColor={gbColor} bgColor={gbgColor} />
					    </div>
					</div>
				</div>
	});

	return <div className="grid my-2">
				{content}
			</div>
}

const StatGraph = ({graphStats, bColor, bgColor}) => {
	const data = {
            labels: ['d1', 'd2', 'd3', 'd4', 'd5'],
            datasets: [
                {
                    label: 'First Dataset',
                    data: graphStats,
                    fill: true,
                    borderColor: bColor,
      				backgroundColor: bgColor,
                    tension: 0.5,
                    borderWidth: 1,
                    pointRadius: 0.5
                }
            ]
        };

    return (
        <Chart type="line" data={data} options={options} style={{ width: '5rem', height: '5rem', float: 'right' }} />
    )
}

const hideDetailsDialog = () => {
	setDialogVisible(false); 
	setTimeout(function() {
   		setTransactions({});
	}, 1000);

}

const searchInGridCustomer = (e) => {
	var q = e.target.value;
	if('' !== q) {
		q = q.toUpperCase();
		const topEarningCustomers = topCustomersNStatisticsOri.topEarningCustomers;
		const newState = topEarningCustomers.filter((c) => {
												var name = c.name === null ? '' : c.name.toUpperCase();
												var pointBalance = c.pointBalance + '';
												var totalPointsEarn = c.totalPointsEarn + '';
												var totalRedeemption = c.totalRedeemption + '';
												var phoneNumber = c.phoneNumber + '';
												var countryCode = c.countryCode + '';
												return (c.segment.name.toUpperCase().includes(q)
													|| name.includes(q)
													|| pointBalance.includes(q)
													|| totalPointsEarn.includes(q)
													|| totalRedeemption.includes(q)
													|| phoneNumber.includes(q)
													|| countryCode.includes(q)
												);
											})
											.map((c) => c);
		const newStateStats = {...topCustomersNStatistics, topEarningCustomers: newState};
		setTopCustomersNStatistics(newStateStats);
	} else {
		const topEarningCustomers = topCustomersNStatisticsOri.topEarningCustomers;
		const newStateStats = {...topCustomersNStatistics, topEarningCustomers: topEarningCustomers};
		setTopCustomersNStatistics(newStateStats);
	}
}

	return (<>
		<div className="relative p-0 m-0">
		    <div className="relative text-gray-800 w-screen h-screen">
        		<NavigationBarVertical />
		        <div className="absolute top-0 left-0 h-4rem px-2 border-200 border-bottom-1" style={{ width: ConstantValues.hNavBarWidth, height: '95%', marginLeft: ConstantValues.vNavBarWidth }}>
		        	<NavigationBarHorizontal hideLinkOf='ManageCustomerSegments' />
					<div id="show-saltwin-content" className="absolute top-4rem left-0 px-2 py-4" style={{ width: '100%', height: '95%' }}>
						<div id="cust-segments" className="py-2 relative">
							<div className="grid overflow-hidden" style={{height: '86vh' }}>
							    <div className="col-12 px-3">
							        <div className="flex">
                                        <div className="flex-1 align-items-center justify-content-center">
                                            <div className="text-2xl font-medium">Statistics</div>
                                        </div>
                                        <div className="flex-1">
                                            <div className="flex justify-content-end flex-wrap">
                                                <div className="flex">
                                                    <Calendar value={filterDates} 
                                                        className="h-2rem w-18rem"
                                                        placeholder="All Customers"
                                                        onChange={(e) => setFilterDates(e.value)} 
                                                        onHide ={(e) => getStatsDetails()}
                                                        dateFormat="M d, yy"
                                                        selectionMode="range" readOnlyInput hideOnRangeSelection showIcon />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <StatsFilterInfo topCustNStats={loyalityStats}  />
                                    <div className="flex">
                                        <div className="flex-1 align-items-center justify-content-center pt-1">
                                            <b className="text-2xl font-medium">Top Earnings</b>
                                            <Avatar icon="pi pi-refresh" className="cursor-pointer"
                                                style={{ backgroundColor: '#fff', color: '#000'}} shape="circle" 
                                                onClick ={(e) => getStatsDetails()} />
                                        </div>
                                        <div className="flex-1">
                                            <div className="flex justify-content-end flex-wrap">
                                                <div className="flex">
                                                    <IconField iconPosition="right">
                                                        <InputIcon className="pi pi-search"> </InputIcon>
                                                        <InputText v-model="value1" placeholder="Search" className="w-full md:w-14rem"
                                                            onChange={(e) => searchInGridCustomer(e)} />
                                                    </IconField>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex">
                                    	<div className="flex-1 align-items-center justify-content-center">
                                            <div className="flex">
                                                <div className="flex-0 flex align-items-center justify-content-start">
                                                    <b className="text-lg font-light hidden">New Customers (<NewCustomerCount customers={topEarningCustomers} />)</b>
                                                    <b className="text-lg font-light hidden text-gray-400 mx-3">|</b>
                                                </div>
                                                <div className="flex-1 flex align-items-center justify-content-start">
                                                    <Button icon="pi pi-user-plus" className="text-lg font-light text-gray-400 hidden" rounded text  />
                                                    <Button icon="pi pi-info-circle" className="text-lg font-light text-gray-400 hidden" rounded text />
                                                    <Button icon="pi pi-ellipsis-h" className="text-lg font-light text-gray-400 hidden" rounded text />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex-1">
                                            <div className="flex justify-content-end flex-wrap">
                                                <div className="flex">
                                                    <Button icon="pi pi-sliders-h" className="text-lg font-light text-gray-400 hidden" rounded text />
                                                    <Dropdown value={sortBy} onChange={(e) => setSortBy(e.value)} 
                                                        options={sortOpt} optionLabel="name" 
                                                        placeholder="Select a City" className="w-full md:w-14rem text-lg h-2rem w-15rem hidden" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <DataTable value={topEarningCustomers} tableStyle={{ minWidth: '50rem' }} 
                                                className="font-light mt-2"
                                                scrollable scrollHeight="48vh"
                                                sortMode="multiple"
                                                paginator rows={200} rowsPerPageOptions={[10, 25, 50, 100, 200]} >
                                        {topEarningCustomersHeaders.map((col, i) => {
                                            if(col.field === 'uuid'){
                                                return <Column key={col.field} field={col.field} header={col.header} align={col.align} sortable={col.isSortable} hidden="true" />
                                            } else if(col.field === 'phoneNumber') {
                                                return <Column key={col.field} field={col.field} header={col.header} align={col.align} sortable={col.isSortable} body={phoneBodyTemplate} />
                                            } else if(col.field === 'email') {
                                                return <Column key={col.field} field={col.field} header={col.header} align={col.align} sortable={col.isSortable} body={newCustomerBodyTemplate} />
                                            } else if(col.field === 'tier') {
                                                return <Column key={col.field} field={col.field} header={col.header} align={col.align} sortable={col.isSortable} body={segmentBodyTemplate} />
                                            } else if(col.field === 'dob') {
                                                return <Column key={col.field} field={col.field} header={col.header} align={col.align} body={detailsBodyTemplate} />
                                            } else if(col.field === 'dateOfJoining') {
                                                return <Column key={col.field} field={col.field} header={col.header} align={col.align} sortable={col.isSortable} body={memberSinceBodyTemplate} />
                                            } else {
                                                return <Column key={col.field} field={col.field} header={col.header} align={col.align} sortable={col.isSortable} />
                                            }
                                        })}
                                    </DataTable>
							    </div>
							</div>
						</div>
					</div>
        		</div>
        		<Footer />
		    </div>
		</div>
       	<ShowDetailsDialog transactions={transactions} dialogVisible={dialogVisible} 
       		hideDetailsDialog={hideDetailsDialog}
       		transactionDateTimeBodyTemplate={transactionDateTimeBodyTemplate} />
       	<ProgressLoader loaderVisible={loaderVisible} />
		<Dialog header="Your Brands" visible={showChooseBrand} 
       		onHide={() => setShowChooseBrand(false)}  style={{ width: 'auto' }}>
			<p className="m-0">
		        <Dropdown value={brandChosen} onChange={(e) => setBrandChosenAndGetDetails(e.value)} options={brands} optionLabel="name" 
    				placeholder="Choose Brand" className="w-full md:w-14rem" />
		    </p>
		</Dialog>
		<Dialog header="Info" visible={dialogAPIInfoFlag} style={{ width: '50vw' }} onHide={() => setDialogAPIInfoFlag(false)}>
            <p className="m-0 text-lg font-medium mb-2 text-gray-800">
                <p className="font-light">{dialogAPIInfoMessage}</p>
            </p>
        </Dialog>
        <Dialog header="Error" headerClassName="text-red-500" visible={dialogAPIErrorFlag} style={{ width: '50vw' }} onHide={() => setDialogAPIErrorFlag(false)}>
	        <div className="m-0 text-lg font-medium mb-2 text-gray-800">
	            <div className="font-light">{dialogAPIError}</div>
	        </div>
	    </Dialog>
    </>
    )
}

const ShowDetailsDialog = ({transactions, dialogVisible, hideDetailsDialog, transactionDateTimeBodyTemplate}) => {
	return(
		<Dialog header="Transactions" className="text-lg" 
			visible={dialogVisible} style={{ width: '50vw', height: '100vh', maxHeight: '100%' }} 
			onHide={() => hideDetailsDialog()}
			position="right" >
	        <DataTable value={transactions.transactions} className="font-light">
			    <Column field="billId" header="" hidden></Column>
			    <Column field="rewardCode" header="Code" hidden></Column>
			    <Column field="transactionType" header="Type" hidden></Column>
			    <Column field="transactionStatus" header="Status" hidden></Column>
			    <Column field="transactionTime" header="Date" body={transactionDateTimeBodyTemplate} />
			    <Column field="pointsEarn" header="Earnings"></Column>
			    <Column field="pointsBurn" header="Redeemptions"></Column>
			    <Column field="redeemAmount" header="Redeem" hidden></Column>
			</DataTable>
	    </Dialog>
	);
}

const loyalCustomersNStats = {
	loyalityStats: [],
	topEarningCustomers: [],
	topEarningCustomersHeaders: []
};

const options = {
    maintainAspectRatio: false,
    aspectRatio: 0.7,
    plugins: {
        legend: {
            labels: {

            },
            display: false
        }
    },
    scales: {
        x: {
            ticks: {
                display: false
            },
            grid: {
                display: false,
                drawTicks: false,
                drawOnChartArea: 0
            },
            display: false
        },
        y: {
            ticks: {
                display: false
            },
            grid: {
                display: false,
                drawTicks: false,
                drawOnChartArea: 0
            },
            display: false
        }
    }
};

export default Dashboard;